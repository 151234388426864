import { createAction, createActions } from 'redux-actions';
import { hideJoinLoading } from '../../../global/service';

const TOGGLE_CONF_LOCK_REMEMBER_CHECKED = 'TOGGLE_CONF_LOCK_REMEMBER_CHECKED';
const TOGGLE_MUTE_ALL_UNMUTE_THEMSELVES_CHECKED =
  'TOGGLE_MUTE_ALL_UNMUTE_THEMSELVES_CHECKED';
const SET_RENAME_DIALOG_VISIBLE = 'SET_RENAME_DIALOG_VISIBLE';
const SET_RENAME_ALERT_DIALOG_VISIBLE = 'SET_RENAME_ALERT_DIALOG_VISIBLE';
const SET_RECONNECT_DIALOG_VISIBLE = 'SET_RECONNECT_DIALOG_VISIBLE';
const SET_CONNECT_ERROR_DIALOG_VISIBLE = 'SET_CONNECT_ERROR_DIALOG_VISIBLE';
const SET_EXPEL_INFO_DIALOG_VISIBLE = 'SET_EXPEL_INFO_DIALOG_VISIBLE';
const SET_CONF_ENDED_DIALOG_VISIBLE = 'SET_CONF_ENDED_DIALOG_VISIBLE';
const SET_CONF_FULL_DIALOG_VISIBLE = 'SET_CONF_FULL_DIALOG_VISIBLE';
const SET_CONF_LOCKED_DIALOG_VISIBLE = 'SET_CONF_LOCKED_DIALOG_VISIBLE';
const SET_FAR_END_CAMERA_CONTROL_DIALOG_VISIBLE =
  'SET_FAR_END_CAMERA_CONTROL_DIALOG_VISIBLE';
const SET_CAMERA_CONTROL_GROUP_DIALOG_VISIBLE =
  'SET_CAMERA_CONTROL_GROUP_DIALOG_VISIBLE';
const SET_JOIN_VOIP_TIMEOUT_DIALOG_VISIBLE =
  'SET_JOIN_VOIP_TIMEOUT_DIALOG_VISIBLE';
const SET_REQUEST_CONTROL_DIALOG_VISIBLE = 'SET_REQUEST_CONTROL_DIALOG_VISIBLE';
const SET_REMOTE_CONTROL_APPROVE_DIALOG_VISIBLE =
  'SET_REMOTE_CONTROL_APPROVE_DIALOG_VISIBLE';
const SET_REMOTE_CONTROL_LAUNCH_DIALOG_VISIBLE =
  'SET_REMOTE_CONTROL_LAUNCH_DIALOG_VISIBLE';
const SET_REMOTE_CONTROL_NEW_SCREEN_DIALOG_VISIBLE =
  'SET_REMOTE_CONTROL_NEW_SCREEN_DIALOG_VISIBLE';
const SET_UNMUTE_BY_HOST_DIALOG_VISIBLE = 'SET_UNMUTE_BY_HOST_DIALOG_VISIBLE';
const TOGGLE_INSTANCE_OPEN = 'TOGGLE_INSTANCE_OPEN';
const DIALOG_RESET = 'DIALOG_RESET';
const SET_SHARE_MAXIMUM_DIALOG_VISIBLE = 'SET_SHARE_MAXIMUM_DIALOG_VISIBLE';
const SET_SHARE_SETTING_DIALOG_VISIBLE = 'SET_SHARE_SETTING_DIALOG_VISIBLE';
const SET_CANNOT_JOIN_MEETING_DIALOG_VISIBLE =
  'SET_CANNOT_JOIN_MEETING_DIALOG_VISIBLE';
const SET_CANNOT_SHARE_DIALOG_VISIBLE = 'SET_CANNOT_SHARE_DIALOG_VISIBLE';
const SET_DISALLOW_TIP_VISIBLE = 'SET_DISALLOW_TIP_VISIBLE';
const SET_CLAIM_HOST_DIALOG_INFO = 'SET_CLAIM_HOST_DIALOG_INFO';
const SET_FOR_YOUR_GIFT_DIALOG_VISIBLE = 'SET_FOR_YOUR_GIFT_DIALOG_VISIBLE';
const SET_SHARE_TO_BO_CONFIRM_DIALOG_VISIBLE =
  'SET_SHARE_TO_BO_CONFIRM_DIALOG_VISIBLE';
const SET_LIMIT_SHARING_VISIBLE = 'SET_LIMIT_SHARING_VISIBLE';
const SET_POLL_SIZE_HIT_LIMITS_VISIBLE = 'SET_POLL_SIZE_HIT_LIMITS_VISIBLE';
const SET_PRACTICE_SESSION_WEBINAR_START_DIALOG_VISIBLE =
  'SET_PRACTICE_SESSION_WEBINAR_START_DIALOG_VISIBLE';
const SET_CHAT_DELETED_DLP_DIALOG_VISIBLE =
  'SET_CHAT_DELETED_DLP_DIALOG_VISIBLE';
const SET_REPORT_PROBLEM_DIALOG_VISIBLE = 'SET_REPORT_PROBLEM_DIALOG_VISIBLE';

const SET_MEETING_FORCE_BREAK_DIALOG_VISIBLE =
  'SET_MEETING_FORCE_BREAK_DIALOG_VISIBLE';
const SET_LLS_PERMISSION_DIALOG_VISIBLE = 'SET_LLS_PERMISSION_DIALOG_VISIBLE';

const SET_REMOTE_CONTROL_BLOCK_DIALOG_VISIBLE =
  'SET_REMOTE_CONTROL_BLOCK_DIALOG_VISIBLE';

const SET_UNMUTE_MIC_ALERT_VISIBLE = 'SET_UNMUTE_MIC_ALERT_VISIBLE';
const SET_SYSTEM_DISABLE_MIC_ALERT_VISIBLE =
  'SET_SYSTEM_DISABLE_MIC_ALERT_VISIBLE';
export const {
  toggleConfLockRememberChecked,
  toggleMuteAllUnmuteThemselvesChecked,
  setRenameDialogVisible,
  setRenameAlertDialogVisible,
  setReconnectDialogVisible,
  setConnectErrorDialogVisible,
  setExpelInfoDialogVisible,
  setConfEndedDialogVisible,
  setConfFullDialogVisible,
  setConfLockedDialogVisible,
  setFarEndCameraControlDialogVisible,
  setCameraControlGroupDialogVisible,
  setJoinVoipTimeoutDialogVisible,
  setRequestControlDialogVisible,
  setRemoteControlApproveDialogVisible,
  setRemoteControlBlockDialogVisible,
  setRemoteControlLaunchDialogVisible,
  setRemoteControlNewScreenDialogVisible,
  setUnmuteByHostDialogVisible,
  setShareMaximumDialogVisible,
  setShareSettingDialogVisible,
  toggleInstanceOpen,
  dialogReset,
  setCannotJoinMeetingDialogVisible,
  setCannotShareDialogVisible,
  setDisallowTipVisible,
  setClaimHostDialogInfo,
  setForYourGiftDialogVisible,
  setShareToBoConfirmDialogVisible,
  setLimitSharingVisible,
  setPollSizeHitLimitsVisible,
  setPracticeSessionWebinarStartDialogVisible,
  setChatDeletedDlpDialogVisible,
  setReportProblemDialogVisible,
  setMeetingForceBreakDialogVisible,
  setLlsPermissionDialogVisible,
} = createActions({
  [SET_REMOTE_CONTROL_BLOCK_DIALOG_VISIBLE]: (payload) => payload,
  [TOGGLE_CONF_LOCK_REMEMBER_CHECKED]: (payload) => payload,
  [TOGGLE_MUTE_ALL_UNMUTE_THEMSELVES_CHECKED]: (payload) => payload,
  [SET_RENAME_DIALOG_VISIBLE]: (payload) => payload,
  [SET_RENAME_ALERT_DIALOG_VISIBLE]: (payload) => payload,
  [SET_RECONNECT_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    if (typeof payload === 'boolean') {
      return {
        visible: payload,
        errorCode: null,
      };
    }
    return payload;
  },
  [SET_CONNECT_ERROR_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    return payload;
  },
  [SET_EXPEL_INFO_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    return payload;
  },
  [SET_CONF_ENDED_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    return payload;
  },
  [SET_CONF_FULL_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    if (typeof payload === 'boolean') {
      return {
        visible: payload,
        lmURL: null,
      };
    }
    return payload;
  },
  [SET_CONF_LOCKED_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    return payload;
  },
  [SET_FAR_END_CAMERA_CONTROL_DIALOG_VISIBLE]: (payload) => payload,
  [SET_CAMERA_CONTROL_GROUP_DIALOG_VISIBLE]: (payload) => payload,
  [SET_JOIN_VOIP_TIMEOUT_DIALOG_VISIBLE]: (payload) => payload,
  [SET_REQUEST_CONTROL_DIALOG_VISIBLE]: (payload) => payload,
  [SET_REMOTE_CONTROL_APPROVE_DIALOG_VISIBLE]: (payload) => payload,
  [SET_REMOTE_CONTROL_LAUNCH_DIALOG_VISIBLE]: (payload) => payload,
  [SET_REMOTE_CONTROL_NEW_SCREEN_DIALOG_VISIBLE]: (payload) => payload,
  [SET_UNMUTE_BY_HOST_DIALOG_VISIBLE]: (payload) => {
    if (typeof payload === 'boolean' && payload === false) {
      return {
        visible: payload,
        type: undefined,
      };
    }
    return payload;
  },
  [TOGGLE_INSTANCE_OPEN]: (payload) => payload,
  [DIALOG_RESET]: (payload) => {
    return payload;
  },
  [SET_SHARE_MAXIMUM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_SHARE_SETTING_DIALOG_VISIBLE]: (payload) => payload,
  [SET_CANNOT_JOIN_MEETING_DIALOG_VISIBLE]: (payload) => {
    hideJoinLoading();
    if (typeof payload === 'boolean') {
      return {
        visible: payload,
        errorCode: null,
      };
    }
    return payload;
  },
  [SET_CANNOT_SHARE_DIALOG_VISIBLE]: (payload) => payload,
  [SET_DISALLOW_TIP_VISIBLE]: (payload) => payload,
  [SET_CLAIM_HOST_DIALOG_INFO]: (payload) => payload,
  [SET_FOR_YOUR_GIFT_DIALOG_VISIBLE]: (payload) => payload,
  [SET_SHARE_TO_BO_CONFIRM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_LIMIT_SHARING_VISIBLE]: (payload) => payload,
  [SET_POLL_SIZE_HIT_LIMITS_VISIBLE]: (payload) => {
    if (typeof payload === 'boolean') {
      return {
        visible: payload,
      };
    }
    return payload;
  },
  [SET_PRACTICE_SESSION_WEBINAR_START_DIALOG_VISIBLE]: (payload) => payload,
  [SET_CHAT_DELETED_DLP_DIALOG_VISIBLE]: (payload) => ({ visible: payload }),
  [SET_REPORT_PROBLEM_DIALOG_VISIBLE]: (payload) => payload,
  [SET_MEETING_FORCE_BREAK_DIALOG_VISIBLE]: (payload) => payload,
  [SET_LLS_PERMISSION_DIALOG_VISIBLE]: (payload) => payload,
});
export const setUnmuteMicAlertVisible = createAction(
  SET_UNMUTE_MIC_ALERT_VISIBLE,
);

export const setSystemDisableMicAlertVisible = createAction(
  SET_SYSTEM_DISABLE_MIC_ALERT_VISIBLE,
);
