import { createSelector } from 'reselect';
import { isInBOSelector } from '../../features/breakout-room/redux/bo-room-selector';

export const bCanUnmuteSelector = (state) => state.meeting.bCanUnmute;

export const currentUserRoleSelector = (state) =>
  state.meeting.currentUser.userRole;

export const gettyConfigSelector = (state) => state.meeting.gettyConfig;

export const confReadySelector = (state) => state.meeting.confReady;

export const sessionReadyInMeetingSelector = createSelector(
  [
    confReadySelector,
    (state) => state.meetingUI.isOnHold,
    isInBOSelector,
    (v) => v.breakoutRoom.isSecondWSChannelReady,
  ],
  (isConfReady, isOnHold, isInBO, isSecondWSChannelReady) => {
    return (!isInBO || isSecondWSChannelReady) && isConfReady && !isOnHold;
  },
);

export const audioSessionReadySelector = (state) =>
  state.meeting.audioSessionReady;

export const videoSessionReadySelector = (state) =>
  state.meeting.videoSessionReady;

export const shareSessionReadySelector = (state) =>
  state.meeting.shareSessionReady;

export const bMutedUponEntryOnSelector = (state) =>
  state.meeting.bMutedUponEntry;

export const videoSsrcSelector = (state) => state.meeting.currentUser.videoSsrc;

export const bCanUnmuteVideoSelector = (state) => state.meeting.bCanUnmuteVideo;

export const inPracticeSessionSelector = (state) => !state.meeting.bBroadcast;
