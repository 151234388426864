import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import {
  SHARER_PERMISSION_CODE,
  SHARER_PERMISSION_ENUM,
  SHARER_CONTROLBAR_HEIGHT,
} from '../enum';
import {
  SHARER_SETTING_MENU_CATEGORY1_TITLE,
  SHARER_SETTING_MENU_CATEGORY2_TITLE,
  SHARER_SETTING_MENU_CATEGORY_OPTION1,
  SHARER_SETTING_MENU_CATEGORY_OPTION2,
} from '../resource';
import { getScalableRectDimensionByRatio } from '../util';
import {
  isExternalControlledMode,
  isSupportVideoShareSend,
} from '../../../global/util';
import { getFooterHeight } from '../../../global/service';
import {
  coOrHostSelector,
  shareUserCountSelector,
} from '../../../global/redux/selector';
import {
  attendeeBoStatusSelector,
  roomControlStatusSelector,
} from '../../breakout-room/redux/bo-room-selector';
import { isRoomInProgress, isNotInRoom } from '../../breakout-room/utils';
import {
  getPanelViewState,
  PanelName,
} from '../../../global/redux/set-panel-action';
import { isWebinar } from '../../../global/service/meeting-types';
import { isUseUnifiedRender } from '../../../global/op-feature-option';
import { checkRole } from '../../../global/service/user-types';
import {
  isEnableAnnotateCap,
  isOnlyEnableViewerAnnotateCap,
} from '../../annotation/util';

const shareeCurrentActiveNodeIdSelector = ({
  sharing: { shareeCurrentActiveNodeId },
}) => shareeCurrentActiveNodeId;
const localShareeAudioMutedStatusSelector = ({
  sharing: { localShareeAudioMutedStatus },
}) => localShareeAudioMutedStatus;

const showRightContainerSelector = (state) =>
  getPanelViewState(state, PanelName.rightPanelContainer);
const rightContainerWidthSelector = ({ meetingUI: { rightContainerWidth } }) =>
  rightContainerWidth;
const windowWidthSelector = ({
  meetingUI: {
    windowSize: { width },
  },
}) => width;
const windowHeightSelector = ({
  meetingUI: {
    windowSize: { height },
  },
}) => height;
const sharerAppWidth = ({
  sharing: {
    UI: {
      sharerClientReceivedRectDimension: { width },
    },
  },
}) => width;
const sharerAppHeight = ({
  sharing: {
    UI: {
      sharerClientReceivedRectDimension: { height },
    },
  },
}) => height;
export const sharerSelfPreviewVisibleSelector = ({
  sharing: {
    UI: { shareSelfClientPreviewVisible },
  },
}) => shareSelfClientPreviewVisible;

const getVisibleCanvasStyle = createSelector(
  [
    showRightContainerSelector,
    rightContainerWidthSelector,
    windowWidthSelector,
    windowHeightSelector,
    sharerAppWidth,
    sharerAppHeight,
  ],
  (
    showRightContainer,
    rightContainerWidth,
    windowWidth,
    windowHeight,
    sharerAppWidth,
    sharerAppHeight,
  ) => {
    const croppedWindowWidth = showRightContainer
      ? windowWidth - rightContainerWidth
      : windowWidth;
    const croppedWindowHeight =
      windowHeight - SHARER_CONTROLBAR_HEIGHT - getFooterHeight();

    const { width, height } = getScalableRectDimensionByRatio(
      croppedWindowWidth,
      croppedWindowHeight,
      sharerAppWidth,
      sharerAppHeight,
    );

    const left =
      croppedWindowWidth === width ? 0 : (croppedWindowWidth - width) / 2;

    return {
      width,
      height,
      left,
      top: SHARER_CONTROLBAR_HEIGHT,
      opacity: 1,
    };
  },
);

const sharerPermissionCodeSelector = ({ sharing: { sharerPermissionCode } }) =>
  sharerPermissionCode;

const getPermissionArgsFromRawArgs = createSelector(
  [sharerPermissionCodeSelector],
  (sharerPermissionCode) => {
    let code = {};
    switch (sharerPermissionCode) {
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_HOST_GRAB:
        code = [
          {
            title: SHARER_SETTING_MENU_CATEGORY1_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: false,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: true,
              },
            ],
          },
          {
            title: SHARER_SETTING_MENU_CATEGORY2_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: true,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: false,
              },
            ],
          },
        ];

        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_ANYONE_GRAB:
        code = [
          {
            title: SHARER_SETTING_MENU_CATEGORY1_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: false,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: true,
              },
            ],
          },
          {
            title: SHARER_SETTING_MENU_CATEGORY2_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: false,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: true,
              },
            ],
          },
        ];

        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE:
        code = [
          {
            title: SHARER_SETTING_MENU_CATEGORY1_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: true,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: false,
              },
            ],
          },
          {
            title: SHARER_SETTING_MENU_CATEGORY2_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: true,
                checked: true,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: true,
                checked: false,
              },
            ],
          },
        ];

        break;
      case SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_MULTI_SHARE:
        code = [
          {
            title: SHARER_SETTING_MENU_CATEGORY1_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: false,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_SHARE,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: true,
              },
            ],
          },
          {
            title: SHARER_SETTING_MENU_CATEGORY2_TITLE,
            cb: [
              {
                id: SHARER_PERMISSION_ENUM.HOST_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION1,
                disabled: false,
                checked: false,
              },
              {
                id: SHARER_PERMISSION_ENUM.ALL_GRAB,
                label: SHARER_SETTING_MENU_CATEGORY_OPTION2,
                disabled: false,
                checked: true,
              },
            ],
          },
        ];

        break;
      default:
        break;
    }

    return code;
  },
);

const attendeesListSelector = ({ attendeesList: { attendeesList } }) =>
  attendeesList;

const hasExistingSharers = createSelector(
  [attendeesListSelector],
  (attendeesList) => !!_.find(attendeesList, { sharerOn: true }),
);

const currentUserIdSelector = ({
  meeting: {
    currentUser: { userId },
  },
}) => userId;

const isSelfSharer = createSelector(
  [attendeesListSelector, currentUserIdSelector],
  (attendeesList, userId) =>
    !!_.find(attendeesList, { sharerOn: true, userId }),
);

const isShowShareMaximumDialogSelector = createSelector(
  [isSelfSharer, shareUserCountSelector],
  (isSelfSharer, sharerCount) => {
    const maxSize = meetingConfig.meetingOptions?.maxMultipleScreenShareSize;
    return (
      isWebinar() &&
      !isSelfSharer &&
      (maxSize <= sharerCount || (maxSize === undefined && !!sharerCount))
    );
  },
);

const isMultiShareSelector = ({ sharing: { sharerPermissionCode } }) =>
  sharerPermissionCode === SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_MULTI_SHARE;

const isShowOptimizeVideoClipSeletor = createSelector(
  [isMultiShareSelector],
  (isMultiShare) => isSupportVideoShareSend() && !isMultiShare,
);

const isCanShareToBoSelector = createSelector(
  [coOrHostSelector, attendeeBoStatusSelector, roomControlStatusSelector],
  (coOrHost, attendeeBoStatus, controlStatus) =>
    coOrHost &&
    isRoomInProgress(controlStatus) &&
    isNotInRoom(attendeeBoStatus),
);
const sameAccountSelector = ({ sharing: { sameAccount } }) => sameAccount;

const isLimitSharingSelector = ({ sharing: { isLimitSharing } }) =>
  isLimitSharing;
// not in waiting room
const hasOtherSameAccountUserSelector = ({
  sharing: { hasNotSameAccountUser },
  meeting: {
    currentUser: { bHold },
  },
}) => hasNotSameAccountUser && !bHold;

const isShowLimitSharingSeletor = createSelector(
  [
    sameAccountSelector,
    isLimitSharingSelector,
    hasOtherSameAccountUserSelector,
  ],
  (sameAccount, isLimitSharing, hasNotSameAccountUser) => {
    if (!isLimitSharing) {
      return false;
    }
    if (sameAccount && !hasNotSameAccountUser) {
      return false;
    }
    return true;
  },
);

const isHideSharingButtonSelector = createSelector(
  [sameAccountSelector, isLimitSharingSelector],
  (sameAccount, isLimitSharing) => {
    return isLimitSharing && !sameAccount;
  },
);
const isCurrentActiveShareeSharingAudioSelector = createSelector(
  [attendeesListSelector, shareeCurrentActiveNodeIdSelector],
  (attendeesList, activeNodeId) => {
    return (
      attendeesList?.find((user) => user?.userId === activeNodeId)
        ?.bShareAudioOn ?? false
    );
  },
);

const isLocalMutedCurrentActiveShareeSharedAudioSelector = createSelector(
  [localShareeAudioMutedStatusSelector, shareeCurrentActiveNodeIdSelector],
  (localShareeAudioMutedStatus, activeNodeId) => {
    return !!localShareeAudioMutedStatus[activeNodeId];
  },
);

const sharerSsrcSelector = (state) => state.sharing.sharerSsrc;

const isEnableAnnotateSelector = ({
  sharing: { shareeDecodeSdkInitialized },
  meeting: { currentUser },
}) => {
  const { isWebinarAttendee } = checkRole(currentUser);
  if (isWebinarAttendee) {
    return false;
  }

  const enableAnnotation = meetingConfig?.meetingOptions?.enableAnnotation;

  return (
    isUseUnifiedRender() &&
    (isOnlyEnableViewerAnnotateCap() || isEnableAnnotateCap()) &&
    !isExternalControlledMode() &&
    shareeDecodeSdkInitialized &&
    enableAnnotation
  );
};

export {
  getPermissionArgsFromRawArgs,
  hasExistingSharers,
  isSelfSharer,
  isShowShareMaximumDialogSelector,
  isMultiShareSelector,
  isShowOptimizeVideoClipSeletor,
  getVisibleCanvasStyle,
  isCanShareToBoSelector,
  isShowLimitSharingSeletor,
  isHideSharingButtonSelector,
  isCurrentActiveShareeSharingAudioSelector,
  isLocalMutedCurrentActiveShareeSharedAudioSelector,
  sharerSsrcSelector,
  isEnableAnnotateSelector,
};
