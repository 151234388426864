import _ from 'lodash';
import { isAutoTurnOnVideo } from '../../../../controller';
import { isViewOnly } from '../../../service/user-types';
import { coOrHostSelector } from '../../../redux/selector';
import { easyStore } from '../../../easy-store';
import { SESSIONSTORAGE_KEYS } from '../../../constant';
import { getRedux } from '../../../telemetry/telemetry-utils';

export const autoVideo = () =>
  getRedux(({ getState }) => {
    const state = getState();
    const viewOnly = isViewOnly(state.meeting.currentUser.userRole);
    const coOrHost = coOrHostSelector(state);
    const bCanUnmuteVideo = state.meeting.bCanUnmuteVideo;
    const avStatus = easyStore.easyGet(SESSIONSTORAGE_KEYS.avStatus);
    if (!viewOnly && (coOrHost || bCanUnmuteVideo)) {
      if (_.has(avStatus, 'video')) {
        return avStatus.video === 'unmute';
      } else {
        return isAutoTurnOnVideo();
      }
    }
    return false;
  });
