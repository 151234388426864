import { useEffect, useState, useRef, useCallback } from 'react';
import {
  PREVIEW_AUDIO_STATUS,
  PREVIEW_VIDEO_STATUS,
  PREVIEW_ERROR,
  PREVIEW_EVENT,
  CACHE_KEY,
  REQUEST_PERMISSION_STATUS,
  PEPC_PERMISSION_STATUS,
  PEPC_PERMISSION_TYPE,
} from '../consts';
import eb from '../../../global/event-bus';
import {
  cacheAudioStatus,
  cacheVideoStatus,
  clearPreviewAVSocket,
  getAudioStatusFromCache,
  getCachedAudioOn,
  getPreviewAVSocket,
  getVideoStatusFromCache,
} from '../service';
import {
  AVS_TAGS,
  avsLogReport,
} from '../../../global/logger/log-service/avs-laplace-telemetry';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../../job_enum';
import { initVideoPlayer } from '@zoom/web-media-video-player';
import { globalVariable } from '../../../global/global-variable';
import { has } from 'lodash';
import deviceManager from '../../../device-manager';

import {
  getPreviewPermissionDismissStatus,
  isSupportPEPCFlow,
  setPreviewPermissionDismissStatus,
} from '../../../global';

const usePreviewVideo = ({
  shouldRequestPermission,
  isAudioInitSuccess = false,
  isVideoInitSuccess = false,
  cache = CACHE_KEY.PREVIEW_OPTIONS,
  canAudioUnmute = true,
  canVideoUnmute = true,
  toggleShowAllowPermissionPrompt,
  toggleShowNoPermissionConfirm,
  toggleShowAskPermissionConfirm,
  askMicrophoneOnly,
  grantPermissionType,
  setGrantPermissionType,
}) => {
  const [audioStatus, _setAudioStatus] = useState(PREVIEW_AUDIO_STATUS.LOADING);
  const [videoStatus, setVideoStatus] = useState(PREVIEW_VIDEO_STATUS.LOADING);
  const [nextAudioStatus, setNextAudioStatus] = useState(
    getAudioStatusFromCache(cache),
  );
  const [nextVideoStatus, setNextVideoStatus] = useState(
    getVideoStatusFromCache(cache),
  );
  const [willRenderVideo, setWillRenderVideo] = useState(false);
  const [error, setError] = useState(PREVIEW_ERROR.NONE);
  const [microphones, setMicrophones] = useState([]);
  const [speakers, setSpeakers] = useState([]);
  const [activeMicrophone, setActiveMicrophone] = useState(null);
  const [activeSpeaker, setActiveSpeaker] = useState(null);
  const [cameras, setCameras] = useState([]);
  const [activeCamera, setActiveCamera] = useState(null);
  const avSocket = getPreviewAVSocket();
  const isVideoOpen = videoStatus === PREVIEW_VIDEO_STATUS.OPEN;
  const permissionRef = useRef({});

  const dynamicPropRef = useRef(null);
  dynamicPropRef.current = {
    audioStatus: dynamicPropRef.current
      ? dynamicPropRef.current.audioStatus
      : audioStatus,
    videoStatus,
    canAudioUnmute,
    canVideoUnmute,
  };

  const setAudioStatus = useCallback((status) => {
    dynamicPropRef.current.audioStatus = status;
    _setAudioStatus(status);
  }, []);

  const handleCamerasUpdate = useCallback((cameras) => {
    setCameras(cameras);
    if (cameras.length <= 1) {
      setError(PREVIEW_ERROR.CAN_NOT_DETECT_CAMERA);
      setVideoStatus(PREVIEW_VIDEO_STATUS.CAN_NOT_DETECT_CAMERA);
    } else {
      setError(PREVIEW_ERROR.NONE);
    }
  }, []);

  const handleStartVideo = useCallback(() => {
    avsLogReport('preview user start capture video', [
      AVS_TAGS.user_action,
      AVS_TAGS.video_telemetry,
    ]);
    startVideo();
    setVideoStatus(PREVIEW_VIDEO_STATUS.CAPTURING);
    setNextVideoStatus(PREVIEW_VIDEO_STATUS.OPEN);
    cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.OPEN);
  }, [cache]);

  const handleAskPermissionResolve = useCallback(
    (e) => {
      const permissionStatus = e.target.permissionStatus;
      const { micPermissionStatus, cameraPermissonStatus } =
        permissionRef.current || {};

      const isAVGranted =
        (permissionStatus === PEPC_PERMISSION_STATUS.GRANTED &&
          !askMicrophoneOnly) ||
        (micPermissionStatus.state === 'granted' &&
          cameraPermissonStatus.state === 'granted');

      const onlyAudioGranted =
        (permissionStatus === PEPC_PERMISSION_STATUS.GRANTED &&
          askMicrophoneOnly) ||
        (micPermissionStatus.state === 'granted' &&
          cameraPermissonStatus.state !== 'granted');
      if (isAVGranted) {
        Job.complete(
          JOB_ENUM.PEPC_PERMISSION_REQUEST,
          REQUEST_PERMISSION_STATUS.GRANTED_AUDIO_VIDEO,
        );
      } else if (onlyAudioGranted) {
        Job.complete(
          JOB_ENUM.PEPC_PERMISSION_REQUEST,
          REQUEST_PERMISSION_STATUS.GRANTED_AUDIO,
        );
        setPreviewPermissionDismissStatus('video', true);
      }
      toggleShowAskPermissionConfirm(false);
    },
    [askMicrophoneOnly, toggleShowAskPermissionConfirm],
  );

  const handleGrantPermissionResolve = useCallback(
    (e) => {
      const permissionStatus = e.target.permissionStatus;
      const { micPermissionStatus, cameraPermissonStatus } =
        permissionRef.current || {};

      const isAudioGranted =
        (permissionStatus === PEPC_PERMISSION_STATUS.GRANTED ||
          micPermissionStatus.state === 'granted') &&
        grantPermissionType === PEPC_PERMISSION_TYPE.MICROPHONE;

      const isVideoGranted =
        (permissionStatus === PEPC_PERMISSION_STATUS.GRANTED ||
          cameraPermissonStatus.state === 'granted') &&
        grantPermissionType === PEPC_PERMISSION_TYPE.CAMERA;
      if (isAudioGranted) {
        setPreviewPermissionDismissStatus('audio', false);
        Job.start(JOB_ENUM.PREVIEW_CONNECT_AUDIO, () => {
          avSocket.leaveAudio();
          avSocket.connectAudio({ captureAudio: true });
          setAudioStatus(PREVIEW_AUDIO_STATUS.CAPTURING);
        }).then(() => {
          const cachedAudioStatus = getAudioStatusFromCache(cache);
          const { canAudioUnmute } = dynamicPropRef.current;
          const isAudioOn =
            cachedAudioStatus === PREVIEW_AUDIO_STATUS.UNMUTED &&
            canAudioUnmute;
          if (isAudioOn) {
            avSocket.unmuteAudio();
            setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
          } else if (canAudioUnmute) {
            avSocket.muteAudio();
            setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
          } else {
            avSocket.muteAudio();
            setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
          }
        });
      } else if (isVideoGranted) {
        setPreviewPermissionDismissStatus('video', false);
        handleStartVideo();
      }

      setGrantPermissionType(null);
    },
    [
      avSocket,
      cache,
      grantPermissionType,
      handleStartVideo,
      setAudioStatus,
      setGrantPermissionType,
    ],
  );

  useEffect(() => {
    const deviceChangeHandler = (deviceState) => {
      if (has(deviceState, 'microphones')) {
        setMicrophones(deviceState.microphones);
      }
      if (has(deviceState, 'speakers')) {
        setSpeakers(deviceState.speakers);
      }
      if (has(deviceState, 'cameras')) {
        handleCamerasUpdate(deviceState.cameras);
      }
      if (has(deviceState, 'activeMicrophone')) {
        setActiveMicrophone(deviceState.activeMicrophone);
        if (deviceState.activeMicrophone !== activeMicrophone) {
          avSocket.changeMicrophone(deviceState.activeMicrophone);
        }
      }
      if (has(deviceState, 'activeSpeaker')) {
        setActiveSpeaker(deviceState.activeSpeaker);
      }
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceState.activeCamera);
        if (deviceState.activeCamera !== activeCamera && isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };
    const deviceSelectedHandler = (deviceState) => {
      if (has(deviceState, 'activeMicrophone')) {
        setActiveMicrophone(deviceState.activeMicrophone);
        avSocket.changeMicrophone(deviceState.activeMicrophone);
      }
      if (has(deviceState, 'activeSpeaker')) {
        setActiveSpeaker(deviceState.activeSpeaker);
      }
      if (has(deviceState, 'activeCamera')) {
        setActiveCamera(deviceState.activeCamera);
        if (isVideoOpen) {
          avSocket.changeCamera(deviceState.activeCamera);
        }
      }
    };

    deviceManager.on('DEVICE_CHANGED', deviceChangeHandler);
    deviceManager.on('DEVICE_SELECTED', deviceSelectedHandler);

    return () => {
      deviceManager.off('DEVICE_CHANGED', deviceChangeHandler);
      deviceManager.off('DEVICE_SELECTED', deviceSelectedHandler);
    };
  }, [
    avSocket,
    activeSpeaker,
    activeCamera,
    activeMicrophone,
    handleCamerasUpdate,
    isVideoOpen,
  ]);

  useEffect(() => {
    deviceManager.watchInitComplete().then((deviceState) => {
      setMicrophones(deviceState.microphones);
      setSpeakers(deviceState.speakers);
      setActiveMicrophone(deviceState.activeMicrophone);
      setActiveSpeaker(deviceState.activeSpeaker);
      handleCamerasUpdate(deviceState.cameras);
      setActiveCamera(deviceState.activeCamera);
    });
  }, [handleCamerasUpdate]);

  useEffect(() => {
    if (willRenderVideo) {
      avSocket.startVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [willRenderVideo]);
  const startVideo = () => setWillRenderVideo(true);
  const stopVideo = useCallback(() => {
    avSocket.stopVideo();
    setWillRenderVideo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const { audioStatus } = dynamicPropRef.current;
    if (!canAudioUnmute) {
      if (audioStatus === PREVIEW_AUDIO_STATUS.MUTED) {
        setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
      }
      if (audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED) {
        avSocket.muteAudio();
        setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
      }
    } else if (canAudioUnmute) {
      if (audioStatus === PREVIEW_AUDIO_STATUS.DISABLED) {
        setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAudioUnmute]);

  useEffect(() => {
    const { videoStatus } = dynamicPropRef.current;
    if (!canVideoUnmute) {
      if (videoStatus === PREVIEW_VIDEO_STATUS.OPEN) {
        stopVideo();
      }
      setVideoStatus(PREVIEW_VIDEO_STATUS.DISABLED);
      cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.CLOSED);
    } else if (canVideoUnmute) {
      if (videoStatus === PREVIEW_VIDEO_STATUS.DISABLED) {
        setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
        cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.CLOSED);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canVideoUnmute]);

  useEffect(() => {
    return () => {
      const { audioStatus, videoStatus } = dynamicPropRef.current;
      if (
        videoStatus === PREVIEW_VIDEO_STATUS.OPEN ||
        videoStatus === PREVIEW_VIDEO_STATUS.CAPTURING
      ) {
        // avSocket.enableReuseStream(true);
        stopVideo();
      }
      if (
        audioStatus === PREVIEW_AUDIO_STATUS.MUTED ||
        audioStatus === PREVIEW_AUDIO_STATUS.UNMUTED ||
        audioStatus === PREVIEW_AUDIO_STATUS.CAPTURING
      ) {
        // avSocket.enableReuseStream(true);
        avSocket.leaveAudio();
      }
      clearPreviewAVSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSupportPEPCFlow()) {
      const permissionNames = ['microphone', 'camera'];
      const queryPermissons = permissionNames.map((name) =>
        navigator.permissions.query({ name }),
      );
      Job.start(JOB_ENUM.PEPC_PERMISSION_REQUEST);
      Promise.all(queryPermissons).then(
        ([micPermissionStatus, cameraPermissonStatus]) => {
          permissionRef.current = {
            micPermissionStatus,
            cameraPermissonStatus,
          };
          const isAudioDismissed = getPreviewPermissionDismissStatus('audio');
          if (
            !isAudioDismissed &&
            micPermissionStatus.state === PEPC_PERMISSION_STATUS.PROMPT
          ) {
            toggleShowAskPermissionConfirm(true);
          } else if (
            micPermissionStatus.state === PEPC_PERMISSION_STATUS.GRANTED &&
            cameraPermissonStatus.state !== PEPC_PERMISSION_STATUS.GRANTED
          ) {
            Job.complete(
              JOB_ENUM.PEPC_PERMISSION_REQUEST,
              REQUEST_PERMISSION_STATUS.GRANTED_AUDIO,
            );
          } else if (
            micPermissionStatus.state !== PEPC_PERMISSION_STATUS.GRANTED &&
            cameraPermissonStatus.state === PEPC_PERMISSION_STATUS.GRANTED
          ) {
            Job.complete(
              JOB_ENUM.PEPC_PERMISSION_REQUEST,
              REQUEST_PERMISSION_STATUS.GRANTED_VIDEO,
            );
          } else if (
            micPermissionStatus.state === PEPC_PERMISSION_STATUS.GRANTED &&
            cameraPermissonStatus.state == PEPC_PERMISSION_STATUS.GRANTED
          ) {
            Job.complete(
              JOB_ENUM.PEPC_PERMISSION_REQUEST,
              REQUEST_PERMISSION_STATUS.GRANTED_AUDIO_VIDEO,
            );
          } else {
            Job.complete(
              JOB_ENUM.PEPC_PERMISSION_REQUEST,
              REQUEST_PERMISSION_STATUS.DENIED,
            );
          }
        },
      );
    } else if (shouldRequestPermission) {
      Job.start(JOB_ENUM.REQUEST_PERMISSION, () => {
        avSocket.requestPermission();
      });
    }

    const onAudioInitSuccess = (permissionStatus) => {
      const cachedAudioStatus = getAudioStatusFromCache(cache);
      const isAutoJoin =
        cachedAudioStatus !== PREVIEW_AUDIO_STATUS.NOT_CONNECTED;
      const captureAudio =
        (!shouldRequestPermission && !isSupportPEPCFlow()) ||
        [
          REQUEST_PERMISSION_STATUS.GRANTED_AUDIO_VIDEO,
          REQUEST_PERMISSION_STATUS.GRANTED_AUDIO,
        ].indexOf(permissionStatus) > -1;
      if (!captureAudio) {
        avSocket.connectAudio({ captureAudio });
        setAudioStatus(PREVIEW_AUDIO_STATUS.FORBIDDEN);
      } else if (isAutoJoin) {
        Job.start(JOB_ENUM.PREVIEW_CONNECT_AUDIO, () => {
          avSocket.connectAudio({ captureAudio });
          setAudioStatus(PREVIEW_AUDIO_STATUS.CAPTURING);
        })
          .then(() => {
            const { canAudioUnmute } = dynamicPropRef.current;
            const isAudioOn =
              cachedAudioStatus === PREVIEW_AUDIO_STATUS.UNMUTED &&
              canAudioUnmute;
            if (isAudioOn) {
              avSocket.unmuteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            } else if (canAudioUnmute) {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
            } else {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
          })
          .catch();
      } else {
        setAudioStatus(PREVIEW_AUDIO_STATUS.NOT_CONNECTED);
      }
    };
    const onVideoInitSuccess = (permissionStatus) => {
      initVideoPlayer({
        mediaSDK: () => globalVariable.avSocket?.socketInstance,
        renderMode: globalVariable.avSocket?.useWBVideo ? 'video' : 'canvas',
      });
      const hasCameraPermission =
        (!shouldRequestPermission && !isSupportPEPCFlow()) ||
        [
          REQUEST_PERMISSION_STATUS.GRANTED_AUDIO_VIDEO,
          REQUEST_PERMISSION_STATUS.GRANTED_VIDEO,
        ].indexOf(permissionStatus) > -1;
      if (!hasCameraPermission) {
        setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
        return;
      }
      const cachedVideoStatus = getVideoStatusFromCache(cache);
      const { videoStatus, canVideoUnmute } = dynamicPropRef.current;
      const isVideoOn =
        cachedVideoStatus === PREVIEW_VIDEO_STATUS.OPEN && canVideoUnmute;
      if (videoStatus === PREVIEW_VIDEO_STATUS.DISABLED) {
        return;
      }
      if (isVideoOn) {
        startVideo();
        if (videoStatus === PREVIEW_VIDEO_STATUS.LOADING) {
          setVideoStatus(PREVIEW_VIDEO_STATUS.CAPTURING);
        }
      } else {
        if (canVideoUnmute) {
          setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
        } else {
          setVideoStatus(PREVIEW_VIDEO_STATUS.DISABLED);
        }
      }
    };
    eb.on(PREVIEW_EVENT.AUDIO_INIT_SUCCESS, () => {
      if (shouldRequestPermission && !isSupportPEPCFlow()) {
        Job.watch(JOB_ENUM.REQUEST_PERMISSION).then((permissionStatus) => {
          onAudioInitSuccess(permissionStatus);
        });
      } else if (isSupportPEPCFlow()) {
        Job.watch(JOB_ENUM.PEPC_PERMISSION_REQUEST).then((permissionStatus) => {
          onAudioInitSuccess(permissionStatus);
        });
      } else {
        onAudioInitSuccess();
      }
    });
    eb.on(PREVIEW_EVENT.AUDIO_CONNECT_SUCCESS, () => {
      Job.complete(JOB_ENUM.PREVIEW_CONNECT_AUDIO);
    });

    eb.on(PREVIEW_EVENT.AUDIO_LEAVE_SUCCESS, () => {
      setAudioStatus(PREVIEW_AUDIO_STATUS.NOT_CONNECTED);
    });

    eb.on(PREVIEW_EVENT.AUDIO_FORBIDDEN, () => {
      setAudioStatus(PREVIEW_AUDIO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.AUDIO_FORBIDDEN);
    });

    eb.on(PREVIEW_EVENT.VIDEO_INIT_SUCCESS, () => {
      if (shouldRequestPermission && !isSupportPEPCFlow()) {
        Job.watch(JOB_ENUM.REQUEST_PERMISSION).then((permissionStatus) => {
          onVideoInitSuccess(permissionStatus);
        });
      } else if (isSupportPEPCFlow()) {
        Job.watch(JOB_ENUM.PEPC_PERMISSION_REQUEST).then((permissionStatus) => {
          onVideoInitSuccess(permissionStatus);
        });
      } else {
        onVideoInitSuccess();
      }
    });

    eb.on(PREVIEW_EVENT.CAMERA_IS_TAKEN, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.CAMERA_TAKEN);
      setWillRenderVideo(false);
    });

    eb.on(PREVIEW_EVENT.VIDEO_START_SUCCESS, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.OPEN);
      setError(PREVIEW_ERROR.NONE);
    });

    eb.on(PREVIEW_EVENT.VIDEO_FORBIDDEN, () => {
      setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
      setError(PREVIEW_ERROR.VIDEO_FORBIDDEN);
      setWillRenderVideo(false);
    });
    eb.on(PREVIEW_EVENT.REQUEST_PERMISSION_RESULT, (result) => {
      if (
        [
          REQUEST_PERMISSION_STATUS.DENIED,
          REQUEST_PERMISSION_STATUS.DISMISS,
        ].indexOf(result) > -1
      ) {
        toggleShowNoPermissionConfirm(true);
      } else {
        Job.complete(JOB_ENUM.REQUEST_PERMISSION, result);
      }
      toggleShowAllowPermissionPrompt(false);
    });

    eb.on(PREVIEW_EVENT.REQUEST_PERMISSION_POP, () => {
      toggleShowAllowPermissionPrompt(true);
    });

    if (shouldRequestPermission && !isSupportPEPCFlow()) {
      Job.watch(JOB_ENUM.REQUEST_PERMISSION).then((permissionResult) => {
        if (isAudioInitSuccess) {
          onAudioInitSuccess(permissionResult);
        }
        if (isVideoInitSuccess) {
          onVideoInitSuccess(permissionResult);
        }
      });
    } else if (isSupportPEPCFlow()) {
      Job.watch(JOB_ENUM.PEPC_PERMISSION_REQUEST).then((permissionResult) => {
        if (isAudioInitSuccess) {
          onAudioInitSuccess(permissionResult);
        }
        if (isVideoInitSuccess) {
          onVideoInitSuccess(permissionResult);
        }
      });
    } else {
      if (isAudioInitSuccess) {
        onAudioInitSuccess();
      }
      if (isVideoInitSuccess) {
        onVideoInitSuccess();
      }
    }

    return () => {
      eb.offAll(Object.values(PREVIEW_EVENT));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAudioClick = () => {
    const { canAudioUnmute } = dynamicPropRef.current;
    switch (audioStatus) {
      case PREVIEW_AUDIO_STATUS.MUTED: {
        avsLogReport('preview user unmute audio', [
          AVS_TAGS.user_action,
          AVS_TAGS.audio_telemetry,
        ]);
        avSocket.unmuteAudio();
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.UNMUTED);
        setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
        break;
      }
      case PREVIEW_AUDIO_STATUS.UNMUTED: {
        avsLogReport('preview user mute audio', [
          AVS_TAGS.user_action,
          AVS_TAGS.audio_telemetry,
        ]);
        avSocket.muteAudio();
        cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
        setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
        break;
      }
      case PREVIEW_AUDIO_STATUS.NOT_CONNECTED: {
        const isAudioOn = getCachedAudioOn(cache) && canAudioUnmute;
        Job.start(JOB_ENUM.PREVIEW_CONNECT_AUDIO, () => {
          avSocket.connectAudio({ isAudioOn });
          setAudioStatus(PREVIEW_AUDIO_STATUS.CAPTURING);
          if (isAudioOn) {
            setNextAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.UNMUTED);
          } else {
            if (canAudioUnmute) {
              setNextAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            } else {
              setNextAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
            cacheAudioStatus(cache, PREVIEW_AUDIO_STATUS.MUTED);
          }
        })
          .then(() => {
            if (isAudioOn) {
              avSocket.unmuteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.UNMUTED);
            } else if (canAudioUnmute) {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.MUTED);
            } else {
              avSocket.muteAudio();
              setAudioStatus(PREVIEW_AUDIO_STATUS.DISABLED);
            }
          })
          .catch();
        break;
      }
      case PREVIEW_AUDIO_STATUS.FORBIDDEN: {
        if (
          isSupportPEPCFlow() &&
          shouldRequestPermission &&
          permissionRef.current.micPermissionStatus.state !== 'granted'
        ) {
          setGrantPermissionType('microphone');
        }
        break;
      }
      case PREVIEW_AUDIO_STATUS.DISABLED:
      case PREVIEW_AUDIO_STATUS.LOADING:
      case PREVIEW_AUDIO_STATUS.CAPTURING:
        break;
      default:
        break;
    }
  };

  const handleVideoClick = () => {
    switch (videoStatus) {
      case PREVIEW_VIDEO_STATUS.OPEN: {
        avsLogReport('preview user stop capture video', [
          AVS_TAGS.user_action,
          AVS_TAGS.video_telemetry,
        ]);
        stopVideo();
        setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
        cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.CLOSED);
        break;
      }
      case PREVIEW_VIDEO_STATUS.CLOSED: {
        handleStartVideo();
        break;
      }
      case PREVIEW_VIDEO_STATUS.FORBIDDEN: {
        if (
          isSupportPEPCFlow() &&
          shouldRequestPermission &&
          permissionRef.current.cameraPermissonStatus.state !== 'granted'
        ) {
          setGrantPermissionType(PEPC_PERMISSION_TYPE.CAMERA);
        } else {
          handleStartVideo();
        }

        break;
      }
      case PREVIEW_VIDEO_STATUS.LOADING:
      case PREVIEW_VIDEO_STATUS.CAPTURING:
        break;
      default:
        break;
    }
  };

  return {
    audioStatus,
    videoStatus,
    nextAudioStatus,
    nextVideoStatus,
    willRenderVideo,
    error,
    setError,
    handleAudioClick,
    handleVideoClick,
    speakers,
    microphones,
    cameras,
    activeSpeaker,
    activeMicrophone,
    activeCamera,
    setAudioStatus,
    setVideoStatus,
    handleAskPermissionResolve,
    handleGrantPermissionResolve,
  };
};

export default usePreviewVideo;
