/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { createRoot } from 'react-dom/client';
import classNames from 'classnames';
import { DOM_KEY_CODE } from '../../../global/enum';
import { LEARN_MORE } from '../../../global/resource';
import { isTeslaMode } from '../../../global/util';
import { DisallowTipType } from '../enum';
import { setDisallowTipVisible } from '../redux/dialog-action';
import {
  PREVENT_MICROPHONE_ACCESS_INTERPRETER,
  ALLOW_MICROPHONE_ACCESS_INTERPRETER,
  PREVENT_SHARE_ACCESS,
} from '../resource';
import { getBaseUrl } from '../../../tasks/global/task-utils/common-utils';

const tipRefMap = new Map(
  Object.values(DisallowTipType).map((value) => [value, React.createRef(null)]),
);

let DisallowTip = (props, ref) => {
  const { type, close, dispatch } = props;
  const tipRef = useRef(null);
  const closeTip = useCallback(() => {
    dispatch(setDisallowTipVisible(false));
    close();
  }, [dispatch, close]);
  useImperativeHandle(ref, () => ({
    close: closeTip,
  }));
  const onClick = useCallback(
    (event) => {
      event.nativeEvent.stopImmediatePropagation();
      if (event.keyCode === DOM_KEY_CODE.ESC) {
        closeTip();
      }
    },
    [closeTip],
  );
  useEffect(() => {
    document.addEventListener('click', closeTip, false);
    if (tipRef.current) {
      tipRef.current.focus();
    }
    dispatch(setDisallowTipVisible(true));
    return () => {
      document.removeEventListener('click', closeTip, false);
    };
  }, [dispatch, closeTip]);

  const disallowTipMap = {
    [DisallowTipType.SHARE]: (
      <>
        {PREVENT_SHARE_ACCESS}
        <a
          tabIndex="0"
          target="_blank"
          rel="noopener noreferrer"
          href={`${getBaseUrl()}/html/shareDisallowHelpPage.html`}
        >
          {LEARN_MORE}
        </a>
      </>
    ),
    [DisallowTipType.INTERPRETER_MICROPHONE]:
      PREVENT_MICROPHONE_ACCESS_INTERPRETER,
    [DisallowTipType.INTERPRETER_MICROPHONE_ALLOWED]:
      ALLOW_MICROPHONE_ACCESS_INTERPRETER,
  };

  return (
    <div
      className={classNames('disallow-menu', `disallow-menu__${type}`, {
        'disallow-menu__tesla': isTeslaMode(),
      })}
      tabIndex="0"
      role="button"
      ref={tipRef}
      onClick={onClick}
      onKeyDown={onClick}
    >
      {disallowTipMap[type]}
    </div>
  );
};
DisallowTip = forwardRef(DisallowTip);

export function disallowTip(config) {
  const div = document.createElement('div');
  const root = createRoot(div);
  document.body.appendChild(div);
  function render(props) {
    const ref = tipRefMap.get(props.type);

    root.render(<DisallowTip ref={ref} {...props} />, div);
  }
  function close() {
    root.unmount();
    if (div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }
  const currentConfig = { ...config, close };
  render(currentConfig);
}
export function closeDisallowTip({ type }) {
  const ref = tipRefMap.get(type);
  if (ref && ref.current) {
    ref.current.close();
  }
}
