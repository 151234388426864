import {
  WS_CONF_JOIN_RES,
  WS_CONF_END_INDICATION,
  WS_CONF_ROSTER_INDICATION,
  WS_CONF_HOLD_CHANGE_INDICATION,
  WS_SHARING_RECEIVING_CHL_READY_INDICATION,
  WS_SHARING_STATUS_INDICATION,
} from '../../../constants/ZoomSocketEventTypes';
import {
  LEAVEREASON_RECONNECT,
  LEAVESUBREASON_WAITINGROOMFAILOVER,
} from '../../../constants/LeaveReasons';
import { CONF_SUCCESS } from '../../../constants/ErrorTypes';
import { GOOGLE_MEET_TRANSITION_STATE } from './enum';
import { EXIT_MEETING_INTERVAL } from './constant';
import { setActiveCameraDevice } from '../../../features/video/redux/video-action';
import {
  setActiveSpeaker,
  setActiveMicrophone,
} from '../../../features/audio/redux/audio-action';
import { localReadyStatesObserver } from './local-ready-states-observer';
import {
  CONTROL_MODE_READY_FACTOR,
  CONTROL_MODE_CAP_FULL,
  CONTROL_MODE_CAP_LEVEL_VIEWONLY,
} from '../../enum';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import {
  generateLayoutConfig,
  generateSideBySideLayoutConfig,
} from './layout-helper';

export const rwgMsgHandler = (evt, payload, store, adaptor) => {
  const { dispatch } = store;
  const {
    meeting: { currentUser },
  } = store.getState();

  /**
   * RWG will always forward an initial roster indication event with bVideoOn: false, and update later with
   * a seocnd roster indication event if bVideoOn should be true. For Google Meet, we cannot automatically
   * unmute video from a muted state due to concern of privacy (a user can only manually unmute video).
   * We ignore the first mute video event from RWG to work around this.
   **/
  let ignoreNextMuteVideoEvent = true;

  switch (evt) {
    case WS_CONF_JOIN_RES:
      if (payload.body.res === CONF_SUCCESS) {
        dispatch(setActiveMicrophone(adaptor.getMicId()));
        dispatch(setActiveSpeaker(adaptor.getSpeakerId()));
        dispatch(setActiveCameraDevice(adaptor.getCameraId()));
      } else {
        setTimeout(() => {
          adaptor.notifyControllerJoinFailure();
        }, EXIT_MEETING_INTERVAL);
      }
      break;

    case WS_CONF_END_INDICATION:
      if (
        LEAVEREASON_RECONNECT === payload.body.reason &&
        LEAVESUBREASON_WAITINGROOMFAILOVER === payload.body.subReason
      ) {
        adaptor.notifyControllerStartJoining();
      } else {
        // This is the case when host ended the meeting.
        adaptor.notifyControllerMeetingUpdate({
          state: GOOGLE_MEET_TRANSITION_STATE.DISCONNECTING,
        });
        setTimeout(() => {
          adaptor.notifyControllerMeetingUpdate({
            state: GOOGLE_MEET_TRANSITION_STATE.DISCONNECTED,
          });
        }, EXIT_MEETING_INTERVAL);
      }
      break;

    case WS_CONF_ROSTER_INDICATION: {
      if (payload.body.update) {
        payload.body.update.forEach((user) => {
          if (user.id === currentUser.userId) {
            if (user.muted !== undefined) {
              if (user.muted) {
                adaptor.notifyControllerAudioMuted();
              } else {
                adaptor.notifyControllerAudioUnmuted();
              }
            }
            if (user.bVideoOn !== undefined) {
              if (user.bVideoOn) {
                adaptor.notifyControllerVideoUnmuted();
              } else {
                if (ignoreNextMuteVideoEvent) {
                  ignoreNextMuteVideoEvent = false;
                  return;
                }

                adaptor.notifyControllerVideoMuted();
              }
            }
          }
        });
      }
      if (!localReadyStatesObserver.isTargetCapSet()) {
        if (!payload.body.add) return;
        const me = payload.body.add.find(
          (user) => user.id === currentUser.userId,
        );
        if (!me) return;

        const isLimitedSupport = isViewOnly(me.role) && isWebinar();
        const targetVal = isLimitedSupport
          ? CONTROL_MODE_CAP_LEVEL_VIEWONLY
          : CONTROL_MODE_CAP_FULL;
        localReadyStatesObserver.setTargetCap(targetVal);
        localReadyStatesObserver.complete(
          CONTROL_MODE_READY_FACTOR.RWG_CONNECTED,
        );
        /* eslint-disable-next-line no-console */
        console.error(
          `Ready State Check: rwg mapp result : ${CONTROL_MODE_READY_FACTOR.RWG_CONNECTED}`,
        );
      }
      break;
    }

    case WS_CONF_HOLD_CHANGE_INDICATION: {
      if (payload.body.bHold) {
        adaptor.notifyControllerMeetingHoldOn();
      } else {
        adaptor.notifyControllerStartJoining();
      }
      break;
    }
    case WS_SHARING_RECEIVING_CHL_READY_INDICATION: {
      // when receiving a screen share
      adaptor.notifyControllerMeetingUpdate({
        layout: generateSideBySideLayoutConfig(),
      });
      adaptor.notifyControllerPresentationAllowed(false);
      break;
    }

    case WS_SHARING_STATUS_INDICATION: {
      const sharerSrc = payload?.body?.ssrc;

      // not receiving share
      if (sharerSrc === 0) {
        adaptor.notifyControllerMeetingUpdate({
          layout: generateLayoutConfig(store.getState()),
        });
        adaptor.notifyControllerPresentationAllowed(true);
      } else {
        adaptor.notifyControllerMeetingUpdate({
          layout: generateSideBySideLayoutConfig(),
        });
        adaptor.notifyControllerPresentationAllowed(false);
      }

      break;
    }

    default:
      break;
  }
};
