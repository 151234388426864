/* eslint-disable react/prop-types */
import React from 'react';
import Modal from '../../../../global/components/widget/modal';
import { getBaseUrl } from '../../../../tasks/global/task-utils/common-utils';
import { LEARN_MORE } from '../../../../global/resource';
import previewI18n from '../../resource';
import { NO_PERMISSION_HELP_LINK } from '../../consts';

const NoPermissionConfirm = (props) => {
  const { onClose } = props;
  return (
    <Modal
      visible={props.show}
      okText=""
      cancelText=""
      className="no-permission-confirm-container"
      isModal
    >
      <div className="no-permission-confirm">
        <img
          className="roadblock"
          src={`${getBaseUrl()}/image/roadblock.svg`}
          alt="roadblock"
        />
        <div className="desc-primary">{previewI18n.NoAudioVideoConfirm}</div>
        <div className="desc-secondary">{previewI18n.PermissionTip2}</div>
        <div>
          <button className="continue" onClick={onClose}>
            {previewI18n.ContinueWithoutAV}
          </button>
        </div>
        <a
          className="learn-more"
          href={NO_PERMISSION_HELP_LINK}
          target="_blank"
          rel="noreferrer"
        >
          {LEARN_MORE}
        </a>
      </div>
    </Modal>
  );
};

export default NoPermissionConfirm;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/no-permission-confirm/NoPermissionConfirm.scss';
