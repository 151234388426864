import {
  CONTROL_MODE_READY_FACTOR,
  CONTROL_MODE_COMMON_ERROR_TYPE,
} from '../../enum';
import {
  INIT_SUCCESS_VIDEO,
  INIT_SUCCESS_VIDEO_ENCODE,
  INIT_SUCCESS_VIDEO_DECODE,
  INIT_SUCCESS_AUDIO,
  INIT_SUCCESS_AUDIO_ENCODE,
  INIT_SUCCESS_AUDIO_DECODE,
  INIT_SUCCESS_SHARING,
  INIT_SUCCESS_SHARING_ENCODE,
  INIT_SUCCESS_SHARING_DECODE,
  USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS,
  USER_GRANT_AUDIO_CAPTURE,
  WEBGL_LOST_IN_MULTI_VIEW,
  MASK_SETTING_PARA_ERROR,
  USER_FORBIDDED_CAPTURE_VIDEO,
  AUDIO_CAPTURE_FAILED,
} from '../../../constants/AVNotifyAPPTypes';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { coOrHostSelector } from '../../../global/redux';
import { INIT_VIDEO_ENCODE_SUCCESS } from '../../../constants/SocketActionTypes';
import { showWebglLostNotification } from '../../../global/containers/notification-manager/msft-webgl-lost-notification';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { sendSocketMonitorLog } from '../../../global/redux/thunk-action/send-socket-monitor-log';
import { noAvailableCameraSelector } from '../../../features/video/redux/selectors/camera-control-selector';

const updateInitState = (evt, payload, result) => {
  /* eslint-disable-next-line no-console */
  console.error(
    `Ready State Check: ${evt}  ${payload}: map result : ${result}`,
  );
  localReadyStatesObserver.complete(result);
};
const callbackMap = {
  [INIT_SUCCESS_AUDIO + INIT_SUCCESS_AUDIO_ENCODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.AUDIO_ENCODE_INIT_SUCCESS,
    );
  },
  [INIT_SUCCESS_AUDIO + INIT_SUCCESS_AUDIO_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.AUDIO_DECODE_INIT_SUCCESS,
    );
  },

  [INIT_SUCCESS_VIDEO + INIT_SUCCESS_VIDEO_ENCODE]: (
    evt,
    payload,
    store,
    adaptor,
  ) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.VIDEO_ENCODE_INIT_SUCCESS,
    );
    const { getState, dispatch } = store;
    const state = getState();
    /**
     * In partner flow, save success redux state ealier becuase it will be immediately used after this line
     * It would too late if we depended on public logic to save state in the avsocket.js
     */
    dispatch({ type: INIT_VIDEO_ENCODE_SUCCESS });
    const {
      meeting: { currentUser, bCanUnmuteVideo },
      meetingUI,
    } = state;
    const coOrHost = coOrHostSelector(state);
    const isWb = isWebinar();
    const isViewOnlyUser = isViewOnly(currentUser.userRole);
    const noAvailableCamera = noAvailableCameraSelector(state);

    if (meetingUI.isOnHold) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
      });
      return false;
    }

    if (isWb && isViewOnlyUser) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
      });
      return false;
    }

    if (noAvailableCamera) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
      });
      return false;
    }
    if (
      (currentUser.bVideoMute && !coOrHost) ||
      (!bCanUnmuteVideo && !coOrHost)
    ) {
      adaptor.notifyControllerActionFailure({
        type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
      });
      return false;
    }
  },

  [INIT_SUCCESS_VIDEO + INIT_SUCCESS_VIDEO_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.VIDEO_DECODE_INIT_SUCCESS,
    );
  },

  [INIT_SUCCESS_SHARING + INIT_SUCCESS_SHARING_ENCODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.SHARE_ENCODE_INIT_SUCCESS,
    );
  },

  [INIT_SUCCESS_SHARING + INIT_SUCCESS_SHARING_DECODE]: (evt, payload) => {
    updateInitState(
      evt,
      payload,
      CONTROL_MODE_READY_FACTOR.SHARE_DECODE_INIT_SUCCESS,
    );
  },
};

export function mediaSDKMsgHandler(evt, payload, store, adaptor) {
  const { getState, dispatch } = store;
  const state = getState();
  const {
    meeting: {
      currentUser: { isAllowTalk: isCurrentUserAllowTalk, userRole, muted },
      bCanUnmute,
    },
    audio: { audioCaptureFailedReason },
  } = state;
  const coOrHost = coOrHostSelector(state);
  const isWb = isWebinar();
  const isViewOnlyUser = isViewOnly(userRole);
  const cb = callbackMap[evt + payload];
  if (typeof cb === 'function') {
    cb(evt, payload, store, adaptor);
  } else {
    switch (evt) {
      case USER_CAMERA_IS_TAKEN_BY_OTHER_PROGRAMS: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        });
        break;
      }
      case USER_GRANT_AUDIO_CAPTURE: {
        if (audioCaptureFailedReason) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          return false; // report error to MSFT
        }
        if (isWb && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          return false;
        }
        if (!bCanUnmute && muted && !coOrHost) {
          adaptor.notifyControllerActionFailure({
            type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
          });
          return false;
        }

        break;
      }
      case WEBGL_LOST_IN_MULTI_VIEW: {
        dispatch(sendSocketMonitorLog('MWGLMTR'));
        dispatch(showWebglLostNotification());
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.WEBGL_CONTEXT_LOST,
        });
        break;
      }
      case MASK_SETTING_PARA_ERROR: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        });
        break;
      }
      case USER_FORBIDDED_CAPTURE_VIDEO: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.START_VIDEO,
        });
        break;
      }
      case AUDIO_CAPTURE_FAILED: {
        adaptor.notifyControllerActionFailure({
          type: CONTROL_MODE_COMMON_ERROR_TYPE.UNMUTE_AUDIO,
        });
        break;
      }

      default: {
        return false;
      }
    }
  }
}
