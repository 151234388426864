import { setAvDisabledTipVisible } from '../../../../actions/MeetingUIActions';
import {
  getPreviewPermissionDismissStatus,
  isJoinAudio,
  isJoinVoIP,
  isSimulive,
  isSupportPEPCFlow,
  isSupportSimulivePhase2,
} from '../../../../global/service';
import { coOrHostSelector } from '../../../../global/redux/selector';
import { isTeslaMode } from '../../../../global/util';
import {
  PWAMeetingEvent,
  sendMsgToPWA,
} from '../../../../global/pwa-integration';
import { isViewOnly } from '../../../../global/service/user-types';
import { showNotAllowUnmuteDialog } from '../../../dialog/redux/dialog-thunk-action';
import { isCurrentUserSignLanguageInterpreterAllowedToTalkOrMuteSelector } from '../../../interpretation/redux/interpretation-selector';
import { SETTINGS_TAB_KEY } from '../../../settings/constant';
import { openSettingsAndToTabThunk } from '../../../settings/redux/settings-thunk-action';
import { hasAudioOptionsSelector } from '../audio-selector';
import {
  joinVoipThunk,
  leaveVoipAudio,
  muteAudio,
  reCaptureAudio,
  setJoinAudioDialogVisible,
  unmuteAudio,
} from '../audio-thunk-action';
import { setUnmuteMicAlertVisible } from '../../../dialog/redux/dialog-action';
import { CANNOT_CHANGE_AUDIO_STATUS } from '../../enum';
import { AUDIO_CAPTURE_FAILED_REASON } from '../../../../constants/Constants';
import { setAudioCaptureFailedReason } from '../audio-action';
import { changeRestartWebRtcTime } from '../../../../actions/MeetingActions';

const updateJoinAudioDialogStatus = (visible) => {
  return (dispatch) => {
    dispatch(setJoinAudioDialogVisible(visible));
  };
};

export const handleAudioButtonClickThunk =
  (event, disabled) => (dispatch, getState) => {
    const state = getState();
    const {
      meeting: { currentUser, bCanUnmute },
      audio: {
        isMicrophoneMutedBySystem,
        shouldRecaptureWhenAudioMuted,
        UI: { isJoinAudioDialogVisible, muteUnmuteActionInProgress },
        isControllerDisableAudio,
        audioCaptureFailedReason,
      },
    } = state;
    if (disabled) {
      return CANNOT_CHANGE_AUDIO_STATUS;
    }
    // Short circut if consent is blocking

    const { userRole, isAllowTalk } = currentUser;
    const isCurrentUserSignLanguageInterpreterAllowToTalkOrMute =
      isCurrentUserSignLanguageInterpreterAllowedToTalkOrMuteSelector(state);
    const isViewOnlyAttendee = isViewOnly(userRole);
    const isAttendeeNotAllowedToTalk =
      (isViewOnlyAttendee && !isAllowTalk) ||
      !isCurrentUserSignLanguageInterpreterAllowToTalkOrMute;
    const coOrHost = coOrHostSelector(state);
    const hasAudioOptions = hasAudioOptionsSelector(state);
    if (!hasAudioOptions) return CANNOT_CHANGE_AUDIO_STATUS;
    if (isTeslaMode() && muteUnmuteActionInProgress)
      return CANNOT_CHANGE_AUDIO_STATUS;
    if (isSimulive() && isSupportSimulivePhase2()) {
      if (isJoinAudio(currentUser)) {
        dispatch(openSettingsAndToTabThunk(SETTINGS_TAB_KEY.AUDIO));
      } else {
        dispatch(updateJoinAudioDialogStatus(!isJoinAudioDialogVisible));
      }
      return CANNOT_CHANGE_AUDIO_STATUS;
    } else if (
      isSimulive() ||
      (isJoinAudio(currentUser) && isAttendeeNotAllowedToTalk)
    ) {
      dispatch(updateJoinAudioDialogStatus(!isJoinAudioDialogVisible));
    } else if (isControllerDisableAudio && isJoinVoIP(currentUser)) {
      dispatch(setAvDisabledTipVisible(true));
      event?.nativeEvent.stopImmediatePropagation();
    } else if (audioCaptureFailedReason) {
      const isPreviewAudioDismissed =
        getPreviewPermissionDismissStatus('audio');
      if (
        audioCaptureFailedReason === AUDIO_CAPTURE_FAILED_REASON.USER_DISMISS &&
        isPreviewAudioDismissed &&
        isSupportPEPCFlow()
      ) {
        dispatch(setAudioCaptureFailedReason(null));
        dispatch(changeRestartWebRtcTime(0));
        dispatch(
          leaveVoipAudio(true, true, () => {
            dispatch(joinVoipThunk());
          }),
        );
      } else {
        /**
         * @see ZOOM-843733
         */
        dispatch(reCaptureAudio());
      }

      return CANNOT_CHANGE_AUDIO_STATUS;
    } else if (isJoinAudio(currentUser)) {
      const { muted } = currentUser;
      if (muted && !bCanUnmute && !coOrHost) {
        dispatch(showNotAllowUnmuteDialog());
        sendMsgToPWA(PWAMeetingEvent.BAN_UNMUTE_AUDIO);
        return CANNOT_CHANGE_AUDIO_STATUS;
      }
      if (isMicrophoneMutedBySystem) {
        if (shouldRecaptureWhenAudioMuted) {
          dispatch(reCaptureAudio());
          return;
        } else {
          dispatch(setUnmuteMicAlertVisible(true));
          return CANNOT_CHANGE_AUDIO_STATUS;
        }
      }
      if (muted) {
        dispatch(unmuteAudio(currentUser.userId));
      } else {
        dispatch(muteAudio(currentUser.userId, true));
      }
    } else {
      dispatch(updateJoinAudioDialogStatus(!isJoinAudioDialogVisible));
    }
    return CANNOT_CHANGE_AUDIO_STATUS;
  };
