/* eslint-disable react/prop-types */
import React, { useRef, useState, useImperativeHandle } from 'react';
import CaptchaContainer from './Captcha/index.js';
import { CaptchaTypes } from './Captcha/type.js';

/*
gRecaptchaInvisible, hCaptchaSiteKey, gRecaptchaVisible
come from backend index.html template render:
https://git.zoom.us/web/web/-/blob/release/web-main/src/main/webapp/WEB-INF/ftl/webclient/wc_meeting_v2.ftl#L182

For detail logic, contact backend: @oliver wang || @walker fang
*/
function getCaptchaKey(type) {
  let ret = window.gRecaptchaInvisible;
  if (type === CaptchaTypes.VisibleRecaptcha) {
    ret = window.gRecaptchaVisible;
  }
  return ret;
}

const Captcha = React.forwardRef(
  (
    {
      captchaType = CaptchaTypes.InvisibleRecaptcha,
      setCaptchaType,
      setVisibleCaptchaInProgress,
    },
    ref,
  ) => {
    const [errorMsg, setErrorMsg] = useState(null);
    const show = captchaType !== null;
    const captchaRef = useRef();
    const isInvisibleCaptcha = captchaType === CaptchaTypes.InvisibleRecaptcha;

    async function getRecaptchaToken() {
      return new Promise((resolve) => {
        captchaRef?.current?.getCaptchaCode(async (data) => {
          if (data?.success) {
            resolve(data?.code);
          } else {
            setErrorMsg('Captcha validate error, please try again.');
            resolve(data?.code);
          }
        });
      });
    }

    const onSmartCaptchaInputChange = () => {
      if (errorMsg) {
        setErrorMsg(null);
      }
    };

    useImperativeHandle(ref, () => ({
      refreshSmartCaptcha() {
        captchaRef?.current?.refreshSmartCaptcha();
      },
      setErrorMessage(msg) {
        setErrorMsg(msg);
      },
      getCaptchaValidateResult: async function () {
        let ret;
        if (isInvisibleCaptcha) {
          ret = await getRecaptchaToken();
        } else if (
          captchaType === CaptchaTypes.SmartCaptcha ||
          captchaType === CaptchaTypes.VisibleRecaptcha
        ) {
          captchaRef?.current?.getCaptchaCode((data) => {
            ret = data?.code;
          });
        }
        return ret;
      },
      resetCaptcha() {
        captchaRef?.current?.resetCaptcha();
      },
    }));

    if (!show) {
      return null;
    }

    return (
      <div
        style={{
          width: '100%',
          marginBottom: '12px',
          display: isInvisibleCaptcha ? 'none' : 'block',
        }}
      >
        <CaptchaContainer
          className={{
            error: !!errorMsg,
          }}
          ref={captchaRef}
          captchaType={captchaType}
          captchaSiteKey={getCaptchaKey(captchaType)}
          onSmartCaptchaInputChange={onSmartCaptchaInputChange}
          setCaptchaType={setCaptchaType}
          setVisibleCaptchaInProgress={setVisibleCaptchaInProgress}
        />
        {errorMsg && (
          <div className="preview-meeting-info__field-error">{errorMsg}</div>
        )}
      </div>
    );
  },
);
Captcha.displayName = 'Captcha';

export default Captcha;
