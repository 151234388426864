/* eslint max-len:[2,150,4] */
import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import {
  isRoomInProgress,
  isInRoom,
  isRoomClosing,
  isNotInRoom,
  isReturningToMainSession,
} from '../../features/breakout-room/utils';
import { restrictFeaturesSelector } from './restrict-features-selector';
import {
  isCoOrHost,
  isZRMultiStreamVideoChildUser,
  isQAWebSettingEnabled,
  isNewChatUIReady,
} from '../service';
import { isGoogleMeetMode, isRwgPhoneUser } from '../util';
import {
  CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS,
  CHAT_MSG_TYPE_TO_SILENT_MODE_USERS,
} from '../../features/chat/constants';
import {
  attentionModeStatusSelector,
  shareAttentionModeStatusSelector,
} from '../../features/attention-mode/redux/attention-mode-selector';
import { SHARE_ATTENTION_MODE_ENUM } from '../../features/attention-mode/enum';
import { isBrowserSupportReceiveFromMainSession } from '../../features/sharing/util';
import { JOIN_MEETING_POLICY, WAITING_ROOM_SORT_TYPES } from '../constant';
import { PREVIEW_OPTIONS } from '../../controller/enum';
import { WHITEBOARD_STATUS } from '../../features/whiteboard/enum';
import {
  allParticipantsItem,
  hostAndPanelistItem,
} from '../../features/zoom-app/constant';
import { isWebinar } from '../service/meeting-types';
import { isHost } from '../service/user-types';
import { PLATFORM_TYPE_CODE } from '../enum';
import { isSwitchToOneChatList, isUnifiedRender } from '../op-feature-option';
import { SHARER_STATUS_ENUM } from '../../features/sharing/enum';

const meetingOptions = meetingConfig.meetingOptions;

const bFollowHostVideoSelector = ({ meeting: { bFollowHostVideo } }) =>
  bFollowHostVideo;

const isHostSelector = ({ meeting: { isHost } }) => isHost;

export const attendeesListSelector = ({ attendeesList: { attendeesList } }) =>
  attendeesList;
const specialAdminLengthSelector = ({
  meeting: { bHasRMC },
  attendeesList: { assistants },
}) => {
  return [bHasRMC, ...assistants].filter((v) => v).length;
};
const xmppAttendeesSelector = ({
  meeting: {
    xmppUserList: { attendees },
  },
}) => attendees ?? [];

const xmppAllowTalkListSelector = ({ attendeesList: { xmppAllowTalkList } }) =>
  xmppAllowTalkList;

const breakoutRoomControlStatusSelector = ({
  breakoutRoom: { controlStatus },
}) => controlStatus;

const breakoutRoomStatusSelector = ({
  breakoutRoom: {
    attendee: { status },
  },
}) => status;
const boMainSessionAttendeeListSelector = ({
  breakoutRoom: { mainSessionAttendeeList },
}) => mainSessionAttendeeList;
export const viewOnlySelector = ({ meeting: { viewOnly } }) => viewOnly;
export const isReceiveSharingSelector = ({ sharing: { isReceiveSharing } }) => {
  return isReceiveSharing;
};
export const isSendShareSelector = ({ sharing: { sharerStatus } }) =>
  sharerStatus !== SHARER_STATUS_ENUM.ED;
const isReceiveSharingFromMainSessionSelector = ({
  sharing: { isReceiveSharingFromMainSession },
}) => isReceiveSharingFromMainSession;
const sharingNodeSelector = ({ sharing: { shareeCurrentActiveNodeId } }) =>
  shareeCurrentActiveNodeId;
const canAdmitSelector = ({
  meeting: {
    currentUser: { bCanAdmit },
  },
}) => bCanAdmit;
const meetingChatSelector = ({ chat: { meetingChat } }) => meetingChat;
export const isOnHoldSelector = ({ meetingUI: { isOnHold } }) => isOnHold;
export const currentUserSelector = ({ meeting: { currentUser } }) =>
  currentUser;
export const currentRoleSelector = ({
  meeting: {
    currentUser: { bCoHost },
    isHost,
  },
}) => ({ bCoHost, isHost });

//  return currentUser.isHost || currentUser.bCoHost;
export const currentUserIsHostSelector = (state) =>
  state.meeting.currentUser.isHost;
const currentUserIsCoHostSelector = (state) =>
  state.meeting.currentUser.bCoHost;
export const currentUserIsCoOrHostSelector = createSelector(
  [currentUserIsHostSelector, currentUserIsCoHostSelector],
  (isHost, bCoHost) => {
    return isHost || bCoHost;
  },
);

export const isNDIEnabledSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.some((v) => v.bNDIBroadcast),
);
export const isHostInMeetingSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.some((user) => isHost(user.userRole)),
);

export const shareUserSelector = createSelector(
  [
    attendeesListSelector,
    isReceiveSharingSelector,
    sharingNodeSelector,
    isReceiveSharingFromMainSessionSelector,
    boMainSessionAttendeeListSelector,
  ],
  (
    attendeesList = [],
    isReceiveSharing,
    shareeCurrentActiveNodeId,
    isReceiveSharingFromMainSession,
    boMainSessionAttendeeList,
  ) => {
    if (isReceiveSharing) {
      let target = {};
      // Due to old chrome don't support receiving sharing from main session
      // but support receiving sharing from old sdk webclient in the same BO
      // so the selector result for old chrome will be the same with the case isReceiveSharingFromMainSession is false
      if (
        isReceiveSharingFromMainSession &&
        isBrowserSupportReceiveFromMainSession()
      ) {
        target = boMainSessionAttendeeList.find(
          (user) =>
            user.userId === shareeCurrentActiveNodeId && user.bShareToBORooms,
        );
      } else {
        target = attendeesList.find(
          (user) => user.userId === shareeCurrentActiveNodeId && user.sharerOn,
        );
      }
      if (target) {
        let shareUserOs = target.os;
        if (target.pwaOS === 'mac') {
          shareUserOs = PLATFORM_TYPE_CODE.MAC;
        } else if (target.pwaOS === 'win') {
          shareUserOs = PLATFORM_TYPE_CODE.WIN;
        }
        return {
          shareUserName: target.displayName,
          shareUserId: target.userId,
          shareUserOs,
          isPwa: target.os === PLATFORM_TYPE_CODE.WEBCLIENT,
          userRole: target.userRole,
          role: target.role,
          bCoHost: target.bCoHost,
          caps: target.caps,
          bShareAudioOn: target.bShareAudioOn,
        };
      }
    }
    return null;
  },
);

export const coOrHostSelector = createSelector(
  [currentRoleSelector],
  ({ bCoHost, isHost }) => isHost || bCoHost,
);

export const participantsSizeSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.length,
);

const sortParticipants = (participants) => {
  const sortType = meetingOptions.waitingRoomUserSort;
  if (_.isNil(sortType)) {
    return participants;
  }
  if (sortType === WAITING_ROOM_SORT_TYPES.ALPHABETICAL) {
    return sortParticipantsByDisplayName(participants);
  } else {
    return sortParticipantsByIndex(participants);
  }
};

const sortParticipantsByIndex = (participants) => {
  return participants.sort((p, q) => {
    return p.uniqueIndex - q.uniqueIndex;
  });
};

const sortParticipantsByDisplayName = (participants) => {
  return participants.sort((p, q) => {
    const name1 = p.displayName.toUpperCase();
    const name2 = q.displayName.toUpperCase();
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;
    return p.uniqueIndex - q.uniqueIndex;
  });
};

export const waitingParticipantsSelector = createSelector(
  [
    attendeesListSelector,
    boMainSessionAttendeeListSelector,
    breakoutRoomStatusSelector,
  ],
  (attendeesList = [], boMainSessionAttendeeList = [], breakoutRoomStatus) => {
    let attendees = attendeesList;
    if (isInRoom(breakoutRoomStatus)) {
      attendees = boMainSessionAttendeeList;
    }
    const waitingParticipants = attendees.filter(
      (user) => user.bHold && !user.bid,
    );
    return sortParticipants(waitingParticipants);
  },
);

const participantFilterSelector = (_, searchVal) => searchVal;
export const onHoldParticipantsBySearchSelector = createSelector(
  [waitingParticipantsSelector, participantFilterSelector],
  (allWaitingRoomUsers, searchVal) => {
    if (!searchVal) return allWaitingRoomUsers;
    return allWaitingRoomUsers.filter((user) =>
      user.displayName.toLowerCase().includes(searchVal.toLowerCase()),
    );
  },
);
export const waitingParticipantsSizeSelector = createSelector(
  [waitingParticipantsSelector],
  (waitingParticipants = []) => {
    return waitingParticipants.length;
  },
);

export const inMeetingParticipantsSelector = createSelector(
  [
    attendeesListSelector,
    breakoutRoomControlStatusSelector,
    breakoutRoomStatusSelector,
  ],
  (attendeesList = [], controlStatus, attendeeStatus) => {
    if (
      (isRoomInProgress(controlStatus) || isRoomClosing(controlStatus)) &&
      (isInRoom(attendeeStatus) || isReturningToMainSession(attendeeStatus))
    ) {
      return attendeesList.filter((user) => !user.bHold);
    }
    return attendeesList.filter((user) => !user.bHold && !user.bid);
  },
);

export const inMeetingParticipantsExcludeZRChildUserSelector = createSelector(
  [
    attendeesListSelector,
    breakoutRoomControlStatusSelector,
    breakoutRoomStatusSelector,
  ],
  (attendeesList = [], controlStatus, attendeeStatus) => {
    if (
      (isRoomInProgress(controlStatus) || isRoomClosing(controlStatus)) &&
      isInRoom(attendeeStatus)
    ) {
      return attendeesList.filter(
        (user) => !user.bHold && !isZRMultiStreamVideoChildUser(user),
      );
    }
    return attendeesList.filter(
      (user) =>
        !user.bHold && !user.bid && !isZRMultiStreamVideoChildUser(user),
    );
  },
);

export const inMeetingParticipantsExcludeZRChildUserAndCoOrHostSelector =
  createSelector(
    [
      attendeesListSelector,
      breakoutRoomControlStatusSelector,
      breakoutRoomStatusSelector,
    ],
    (attendeesList = [], controlStatus, attendeeStatus) => {
      if (
        (isRoomInProgress(controlStatus) || isRoomClosing(controlStatus)) &&
        isInRoom(attendeeStatus)
      ) {
        return attendeesList.filter(
          (user) =>
            !user.bHold &&
            !isZRMultiStreamVideoChildUser(user) &&
            !user.bCoHost &&
            !user.isHost,
        );
      }
      return attendeesList.filter(
        (user) =>
          !user.bHold &&
          !user.bid &&
          !isZRMultiStreamVideoChildUser(user) &&
          !user.bCoHost &&
          !user.isHost,
      );
    },
  );

export const participantsExcepetMyselfAndHostSizeSelector = createSelector(
  [attendeesListSelector, currentUserSelector],
  (attendeesList = [], currentUser) => {
    const participantsExcepetMyselfAndHost = attendeesList.filter(
      (user) =>
        !user.bHold &&
        !isZRMultiStreamVideoChildUser(user) &&
        !user.isHost &&
        currentUser.userId !== user.userId,
    );

    return participantsExcepetMyselfAndHost.length;
  },
);

export const attendeesListExcludeZRChildUserSelector = ({
  attendeesList: { attendeesList },
}) => attendeesList.filter((user) => !isZRMultiStreamVideoChildUser(user));
export const attendeesListExcludeZRChildUserSelector2 = createSelector(
  [attendeesListSelector],
  (attendeesList) => {
    const tmp = attendeesList.filter(
      (user) => !isZRMultiStreamVideoChildUser(user) && user.bLogIn,
    );
    if (isWebinar()) {
      // don't support allParticipantsItem in 5.13.10
      return [hostAndPanelistItem].concat(tmp);
    } else {
      return [allParticipantsItem].concat(tmp);
    }
  },
);
export const xmppAllowTalkRaiseHandSyncListSelector = createSelector(
  [xmppAllowTalkListSelector, xmppAttendeesSelector],
  (xmppAllowTalkList, xmppAttendees) => {
    // get correct raise hand property;
    const xmppWithRaiseHandSyncList = xmppAllowTalkList.map((user) => {
      const userInXmpp = xmppAttendees.find(
        (attendee) => attendee.node && attendee.node === user.userId,
      );
      if (userInXmpp) {
        return {
          ...user,
          bRaiseHand: userInXmpp.bRaiseHand,
          nSkinTone: userInXmpp.nSkinTone,
        };
      }
      return user;
    });
    return xmppWithRaiseHandSyncList;
  },
);

export const raiseHandParticipantSelector = createSelector(
  [attendeesListSelector, xmppAllowTalkRaiseHandSyncListSelector],
  (attendeesList = [], xmppAllowTalkRaiseHandSyncList = []) =>
    [...attendeesList, ...xmppAllowTalkRaiseHandSyncList].filter(
      (user) => user.bRaiseHand,
    ),
);

export const webinarRaiseHandAttendeesSelector = createSelector(
  [xmppAttendeesSelector],
  (xmppAttendees) => xmppAttendees.filter((user) => user.bRaiseHand),
);

export const inMeetingParticipantsSizeSelector = createSelector(
  [
    inMeetingParticipantsSelector,
    specialAdminLengthSelector,
    breakoutRoomStatusSelector,
  ],
  (inMeetingParticipants, adminLength, breakoutRoomStatus) => {
    if (isNotInRoom(breakoutRoomStatus)) {
      return inMeetingParticipants.length + adminLength;
    }
    return inMeetingParticipants.length;
  },
);

export const inMeetingParticipantsSizeExcludeZRChildUserSelector =
  createSelector(
    [
      inMeetingParticipantsExcludeZRChildUserSelector,
      specialAdminLengthSelector,
      breakoutRoomStatusSelector,
    ],
    (inMeetingParticipants = [], adminLength, breakoutRoomStatus) => {
      if (isNotInRoom(breakoutRoomStatus)) {
        return inMeetingParticipants.length + adminLength;
      }
      return inMeetingParticipants.length;
    },
  );
export const inMeetingParticipantsSizeExcludeZRChildUserAndCoOrHostSelector =
  createSelector(
    [inMeetingParticipantsExcludeZRChildUserAndCoOrHostSelector],
    (inMeetingParticipants = []) => {
      return inMeetingParticipants.length;
    },
  );

export const canBeNewHostParticipantsSelector = createSelector(
  [
    attendeesListSelector,
    breakoutRoomStatusSelector,
    boMainSessionAttendeeListSelector,
  ],
  (attendeesList = [], attendeeStatus, mainSessionAttendeeList) => {
    let attendees = attendeesList;
    if (isInRoom(attendeeStatus)) {
      attendees = mainSessionAttendeeList;
    }
    return attendees.filter(
      (user) =>
        !user.bHold &&
        !user.isHost &&
        user.audio !== 'phone' &&
        !isRwgPhoneUser(user) &&
        !isZRMultiStreamVideoChildUser(user),
    );
  },
);

export const meetingHostSelectorInBo = createSelector(
  [boMainSessionAttendeeListSelector],
  (mainSessionAttendeeList = []) =>
    mainSessionAttendeeList.find((user) => user.isHost),
);

export const meetingHostSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.find((user) => user.isHost),
);

export const meetingCoOrHostSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) =>
    attendeesList.filter((user) => user.isHost || user.bCoHost),
);

export const meetingCoHostSelector = createSelector(
  [attendeesListSelector],
  (attendeeList = []) => attendeeList.filter((user) => user.bCoHost),
);

export const meetingCoOrHostSizeSelector = createSelector(
  [meetingCoOrHostSelector],
  (coOrHostList = []) => coOrHostList.length,
);

export const hasLocalRecordSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.some((user) => user.bLocalRecord),
);

export const showWaitingRoomListSelector = createSelector(
  [coOrHostSelector, canAdmitSelector],
  (coOrHost, bCanAdmit) => coOrHost || bCanAdmit,
);

export const noneHostParticipantSelector = createSelector(
  [attendeesListSelector],
  (attendeeList = []) =>
    attendeeList.filter(
      (v) => !(v.isHost || v.bCoHost) && !isZRMultiStreamVideoChildUser(v),
    ),
);

export const noneHostSizeSelector = createSelector(
  [
    viewOnlySelector,
    noneHostParticipantSelector,
    ({ meeting: { bAllowPanelistVote } }) => bAllowPanelistVote,
  ],
  (viewOnlySize, noneHostParticipant, bAllowPanelistVote) => {
    if (bAllowPanelistVote) {
      return viewOnlySize + noneHostParticipant.length;
    }
    return viewOnlySize;
  },
);

export const currentCCSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.find((user) => user.bCCEditor),
);

export const currentHostSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.find((user) => isHost(user.userRole)),
);

export const SRAdminSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.find((user) => user.bSRAdmin),
);

export const currentUserIsSRAdminSelector = createSelector(
  [SRAdminSelector, currentUserSelector],
  (srAdmin, currentUser) => srAdmin?.userId === currentUser.userId,
);

export const originalHostSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => {
    const ret = attendeesList.find((user) => user.isOriginhost);
    return ret;
  },
);

export const noneHostSizeForMeetingPollingSelector = createSelector(
  [viewOnlySelector, noneHostParticipantSelector],
  (viewOnlySize, noneHostParticipants) => {
    return viewOnlySize + noneHostParticipants.length;
  },
);

export const shareUserListSelector = createSelector(
  [
    attendeesListSelector,
    shareAttentionModeStatusSelector,
    coOrHostSelector,
    attentionModeStatusSelector,
    boMainSessionAttendeeListSelector,
    breakoutRoomStatusSelector,
    isReceiveSharingFromMainSessionSelector,
  ],
  (
    attendeesList = [],
    shareAttentionModeStatus,
    coOrHost,
    isEnableAttentionMode,
    mainSessionAttendeeList,
    attendeeStatus,
    isReceiveSharingFromMainSession,
  ) => {
    // Due to old chrome don't support receiving sharing from main session
    // but support receiving sharing from old sdk webclient in the same BO
    // so the selector result for old chrome will be the same with the case isReceiveSharingFromMainSession is false
    if (
      isInRoom(attendeeStatus) &&
      isReceiveSharingFromMainSession &&
      isBrowserSupportReceiveFromMainSession()
    ) {
      return mainSessionAttendeeList.filter(
        (user) => user.sharerOn && user.bShareToBORooms,
      );
    }
    if (
      coOrHost ||
      !isEnableAttentionMode ||
      shareAttentionModeStatus === SHARE_ATTENTION_MODE_ENUM.ALL_PARTICIPANT
    ) {
      return attendeesList.filter((user) => user.sharerOn);
    }
    return attendeesList
      .filter((user) => user.sharerOn)
      .filter((user) => isCoOrHost(user));
  },
);
export const shareUserCountSelector = createSelector(
  [shareUserListSelector],
  (shareUserList = []) => shareUserList.length,
);

export const phoneCallInParticipants = createSelector(
  [attendeesListSelector],
  (attendeesList = []) =>
    attendeesList.filter(
      (user) => !user.bHold && !user.bid && isRwgPhoneUser(user),
    ),
);
export const unconnectedAudioParticipants = createSelector(
  [attendeesListSelector],
  (attendeesList = []) =>
    attendeesList.filter(
      (user) =>
        !user.bHold &&
        !user.bid &&
        !user.audio &&
        !isZRMultiStreamVideoChildUser(user),
    ),
);
export const hasNonHostParticipantsSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) =>
    attendeesList.some((user) => !(user.isHost || user.bCoHost)),
);

export const newMeetingChatSelector = (state) => state.newChat.meetingChat;
export const meetingChatListSelector = createSelector(
  [
    meetingChatSelector,
    newMeetingChatSelector,
    isOnHoldSelector,
    currentUserSelector,
  ],
  (meetingChat, newMeetingChat, isOnHold, currentUser) => {
    const chatList = isNewChatUIReady(!!currentUser.bid)
      ? newMeetingChat
      : meetingChat;
    if (isOnHold) {
      return chatList.filter(
        (m) =>
          m.receiverId === CHAT_MSG_TYPE_TO_SILENT_MODE_USERS ||
          m.receiverId === CHAT_MSG_TYPE_SILENT_MODE_USERS_TO_HOSTS ||
          m.isSilentModeUser,
      );
    }
    return chatList;
  },
);

export const shouldConnectXMPPSocketSelector = ({
  poll: { hasPollingInMeeting },
}) => {
  return isWebinar() || hasPollingInMeeting;
};

const participantPropsSelector = (_, { participant }) => participant;
export const isMeSelector = createSelector(
  [participantPropsSelector, currentUserSelector],
  (participant, currentUser) => {
    return participant.userId === currentUser.userId;
  },
);
const userPronounSelector = ({ meeting: { userPronoun } }) => {
  return !!userPronoun;
};
export const isShowUnSharePronounSelector = createSelector(
  [isMeSelector, currentUserSelector],
  (isMe, { strPronoun }) => {
    return isMe && !!strPronoun;
  },
);

export const isShowSharePronounSelector = createSelector(
  [isMeSelector, currentUserSelector, userPronounSelector],
  (isMe, { strPronoun }, isEnableSharePronoun) =>
    isMe && !strPronoun && isEnableSharePronoun,
);

export const isBrandingEnableSelector = (state) => {
  const {
    meeting: { meetingOptions },
    video: { nameTagTemplates },
  } = state;
  return (
    meetingOptions.enableWebclientInMeetingSessionBranding &&
    !!meetingConfig.sessionBranding &&
    nameTagTemplates.length > 0
  );
};

export const isShowChangeAppearanceSelector = createSelector(
  [isMeSelector, coOrHostSelector, isBrandingEnableSelector],
  (isMe, coOrHost, isEnable) => {
    if (!isWebinar()) return false;
    if (!isEnable) return false;
    return coOrHost || isMe;
  },
);

export const createRestrictFeatureSelector = () =>
  createSelector(
    restrictFeaturesSelector,
    (_, featureNames) => featureNames,
    (restrictFeatures, featureName) => restrictFeatures[`${featureName}`],
  );

export const isQnASettingEnabledSelector = createSelector(
  restrictFeaturesSelector,
  (restrictFeatures) => {
    if (restrictFeatures[JOIN_MEETING_POLICY.QA]) return false;
    return isQAWebSettingEnabled();
  },
);

export const previewOptionsSelector = (state) => {
  const {
    audio: {
      isClientEnableAutoJoinAudio,
      isClientEnableMuteAudioWhenJoinMeeting,
    },
    video: { isClientEnableTurnOffVideoWhenJoinMeeting },
  } = state;
  let previewOptions = 0;
  if (isClientEnableAutoJoinAudio) {
    previewOptions += PREVIEW_OPTIONS.AUTO_JOIN_AUDIO;
  }
  if (!isClientEnableMuteAudioWhenJoinMeeting) {
    previewOptions += PREVIEW_OPTIONS.AUDIO_ON;
  }
  if (!isClientEnableTurnOffVideoWhenJoinMeeting) {
    previewOptions += PREVIEW_OPTIONS.VIDEO_ON;
  }
  return previewOptions;
};

export const isUserFollowStatusSelector = createSelector(
  [bFollowHostVideoSelector, isHostSelector],
  (bFollowHostVideo, isHost) => {
    return bFollowHostVideo && !isHost;
  },
);

const whiteboardStatusSelector = ({ whiteboard: { whiteboardStatus } }) =>
  whiteboardStatus;

export const isInSharingModeSelector = createSelector(
  [isReceiveSharingSelector, whiteboardStatusSelector],
  (isReceiveSharing, whiteboardStatus) => {
    if (whiteboardStatus !== WHITEBOARD_STATUS.OFF && !isReceiveSharing) {
      return true;
    }
    return isReceiveSharing;
  },
);

export const isCoOrHostInMeetingSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) => attendeesList.some((user) => isCoOrHost(user)),
);

export const interpreterIdsSelector = createSelector(
  [attendeesListSelector],
  (attendeesList = []) =>
    attendeesList
      .filter((user) => !user.bHold && !user.bid && user.isInterpreter)
      .map((user) => user.userId >> 10),
);

export const enableGiphyInMeetingSelector = (state) =>
  state.meeting.giphy && isSwitchToOneChatList();

export const bParticipantWillMoveToWRSelector = createSelector(
  [attendeesListSelector, breakoutRoomControlStatusSelector, isHostSelector],
  (attendeeList, boControlStatus, isHost) => {
    const coHostList = attendeeList.filter((user) => user.bCoHost);
    const { moveToWRIfHostLeave } = meetingConfig.meetingOptions;
    return (
      isHost &&
      moveToWRIfHostLeave &&
      coHostList.length <= 0 &&
      !isRoomInProgress(boControlStatus) &&
      attendeeList.length > 1
    );
  },
);

export const isExistShareAreaSelector = createSelector(
  [
    isInSharingModeSelector,
    isSendShareSelector,
    (state) => state.annotation.isAlreadyAnnotate,
  ],
  (isInSharingMode, isSendShare, isAlreadyAnnotate) =>
    isInSharingMode ||
    (isSendShare &&
      ((isAlreadyAnnotate && isUnifiedRender()) || isGoogleMeetMode())),
);
