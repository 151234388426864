import { getRedux } from '../../../telemetry/telemetry-utils';
import { isVoipEnabledSelector } from '../../../../features/audio/redux/audio-selector';
import { easyStore } from '../../../easy-store';
import { SESSIONSTORAGE_KEYS } from '../../../constant';
import _ from 'lodash';
import { isAutoTurnOnAudio } from '../../../../controller';
import { globalVariable } from '../../../global-variable';

export const needAutoJoinAudio = () =>
  getRedux(({ getState }) => {
    const state = getState();
    const audio = state.meeting.currentUser.audio;
    const isVoipEnabled = isVoipEnabledSelector(state);
    const avStatus = easyStore.easyGet(SESSIONSTORAGE_KEYS.avStatus);
    // @see ZOOM-843590
    if (!globalVariable.avSocket?.isEnableAutoJoinAudio?.()) {
      return false;
    }
    if (isVoipEnabled && (_.isEmpty(audio) || audio === 'computer')) {
      if (_.has(avStatus, 'audioJoin')) {
        //follow cached av status
        if (avStatus.audioJoin === 'joined') {
          return true;
        }
      } else if (isAutoTurnOnAudio()) {
        // follow preview options
        return true;
      }
    }
    return false;
  });
