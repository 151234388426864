import React from 'react';
import ZoomButton from '../../../../global/components/widget/button/zoom-button';
import AliveToast from '../../../../global/containers/notification-manager/alive-toast';
import { isWindows } from '../../../../global/util';
import {
  MUTED_TOAST,
  UNMUTE_TEXT,
  speakingWhenMutedTip_new,
  speakingWhenMutedTipFn_new,
  speakingWhenMutedTipFn2_new,
} from '../../resource';
import { handleAudioButtonClickThunk } from './handle-audio-button-click-thunk';
import { isJoinAudio } from '../../../../global';
import { easyStore, storeType } from '../../../../global/easy-store';

const getShortcut = () => {
  if (isWindows()) {
    return 'Alt+A';
  }
};

export const showUnmuteToast = () => (dispatch, getState) => {
  const currentToastKey = 'unmute_toast_key';
  AliveToast.uniqueToast({
    name: currentToastKey,
    aliveTime: 5000,
    message: MUTED_TOAST,
    btnComponent: (
      <ZoomButton
        type="primary"
        styleProps={{ fontWeight: 'bold' }}
        onClick={() => {
          const {
            meeting: { currentUser },
          } = getState();
          if (isJoinAudio(currentUser) && currentUser.muted) {
            dispatch(handleAudioButtonClickThunk(undefined, false));
          }
          AliveToast.close(currentToastKey);
        }}
      >
        {UNMUTE_TEXT}
      </ZoomButton>
    ),
  });
};

export const showShortCutUnmuteToast = () => (dispatch, getState) => {
  const currentToastKey = 'unmute_short_cut_toast_key';
  const {
    audio: { isClientEnableHoldSpaceKeyToUnmute },
  } = getState();
  const hasShowUnmuteToast = easyStore.easyGet('hasShowUnmuteToast');
  if (hasShowUnmuteToast) {
    return;
  }
  const shortcut = getShortcut();
  let toastText = '';
  if (shortcut && isClientEnableHoldSpaceKeyToUnmute) {
    toastText = speakingWhenMutedTipFn_new(shortcut);
  } else if (!shortcut && isClientEnableHoldSpaceKeyToUnmute) {
    toastText = speakingWhenMutedTip_new;
  } else if (shortcut && !isClientEnableHoldSpaceKeyToUnmute) {
    toastText = speakingWhenMutedTipFn2_new(shortcut);
  }
  if (toastText) {
    easyStore.easySet('hasShowUnmuteToast', true, storeType.memory);
    AliveToast.toast({
      name: currentToastKey,
      aliveTime: 5000,
      message: toastText,
    });
  }
};

export function showSpeakingWhenMutedTipThunk() {
  return (dispatch) => {
    /* const shortcut = getShortcut();
    let text = isClientEnableHoldSpaceKeyToUnmute
      ? speakingWhenMutedTip
      : youAreMuted;
    if (shortcut) {
      text = isClientEnableHoldSpaceKeyToUnmute
        ? speakingWhenMutedTipFn1(shortcut)
        : speakingWhenMutedTipFn2(shortcut);
    }
    wcToast({
      text,
      type: 'notify',
    }); */
    dispatch(showUnmuteToast());
  };
}
