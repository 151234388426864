import { escapeSensitiveContent } from '../escapeSensitiveContent';
import meetingConfig from 'meetingConfig';
import { measureDuration, PERFORMANCE_MARK } from '../../../performance';
import { getTrackingId, trackingId } from '../laplace';
import { sendSocketMonitorLog } from '../../redux/thunk-action/send-socket-monitor-log';
import { globalVariable } from '../../global-variable';
import { logBrowserCapabilities } from '../log-utils';
import { easyStore, storeType } from '../../easy-store';
import { isEnableJMFLog, isUseUnifiedRender } from '../../op-feature-option';
import { directReport } from './direct-report';
import { PWAExist } from '../../pwa-integration';
import { isWebinar } from '../../service/meeting-types';
import {
  isChromeOS,
  showBrowserUpgradeBanner,
  showGraphicAccelerationWarning,
} from '../../util';
import {
  cleanJoinFlowTelemetryData,
  getJoinFlowTelemetryData,
} from './join-flow-telemetry';
import { audioBridgeCheck } from '../../service/audio-bridge-service';
import { platformDescriptor } from '../../utils/platform-descriptor';
import {
  SocketType,
  WCSockets,
} from '../../../tasks/global/websocket/websocket-utils';

const parseJoinMeetingResponse = (message) => {
  if (!message?.body) {
    return {
      response: message,
    };
  }
  const body = message.body;

  const firstLogDuration = !easyStore.easyGet('not-first-4098');
  easyStore.easySet('not-first-4098', true, storeType.memory);

  const preJoinTo4098 = measureDuration(
    PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
    PERFORMANCE_MARK.inMeeting_RWG_success,
  )?.duration;
  const startTo4098 = measureDuration(
    PERFORMANCE_MARK.start_InMeetingJS,
    PERFORMANCE_MARK.inMeeting_RWG_success,
  )?.duration;
  const { conID, participantID, userGUID, userID, res } = body;

  return {
    conId: conID,
    userId: userID,
    userGUID,
    response: res,
    participantId: participantID,
    startTo4098: firstLogDuration ? startTo4098 : undefined,
    preJoinTo4098: firstLogDuration ? preJoinTo4098 : undefined,
    // isJoinSuccess: errorTypes.CONF_SUCCESS === message.body.res ? 'join successes' : 'join failed',
  };
};

const getClusterName = (tid) => {
  if (!tid || typeof tid !== 'string') {
    return '';
  }
  return Object.fromEntries(tid.split(';').map((v) => v.split('='))).clid ?? '';
};

const connectionInfoPayloadMaker = async (getState, message) => {
  const {
    meeting: {
      confId,
      svcUrl,
      jid,
      userGUID,
      tid,
      zoomId,
      rwcResponse,
      rwgRetryCount,
      useWBVideo,
      currentUser,
    },
    video: { mmrSaveOrderFlag },
    chat: { waitingRoomChatFlag },
  } = getState();

  const { reason: failOverFrom, failOverCount } =
    easyStore.easyGet('failOverReason') || {};
  easyStore.easySet(
    'failOverReason',
    (v) => ({ ...v, reason: '' }),
    storeType.sessionStorage,
  );
  const waitingRoomWithFailOver =
    easyStore.easyGet('waitingRoomWithFailOver') ?? undefined;
  easyStore.easyRemove('waitingRoomWithFailOver');

  const browserVersion = `browser-${platformDescriptor?.browser}`;
  const osVersion = `os-${platformDescriptor?.os}-${platformDescriptor?.osVersion}`;
  const model = platformDescriptor?.model;
  const caps = (await logBrowserCapabilities()) || {};
  const { currentAudioBridge, audioBridgeToWasm } = audioBridgeCheck();
  return {
    confId,
    userGUID,
    svcUrl,
    cluster: getClusterName(tid),
    accountId: escapeSensitiveContent(meetingConfig?.accountId),
    mmrFeature: waitingRoomChatFlag,
    mmrFeatureEx: mmrSaveOrderFlag,
    userRegion: meetingConfig.userRegion,
    jid: escapeSensitiveContent(jid),
    zoomId: escapeSensitiveContent(zoomId),
    meetingNumber: escapeSensitiveContent(meetingConfig.meetingNumber),
    rwgUrl: rwcResponse[rwgRetryCount]?.rwg,
    retryRWCCounts: rwgRetryCount, // start with 0, +1 when retry RWC
    failOverCount: failOverCount ?? 0,
    failOverFrom: failOverFrom ?? undefined,
    joinFromPreview: globalVariable.wcCTX().fromPreview ?? false,
    waitingRoomWithFailOver,
    mid: meetingConfig.mid,
    tid,
    isEnableHybridRWG: meetingConfig.enableHybridRWG || false,
    browserVersion,
    osVersion,
    model,
    isForceUpdateMeeting: meetingConfig.forceUpdateMeeting,
    // eslint-disable-next-line @babel/new-cap
    inPWA: PWAExist(),
    isWebinar: isWebinar(),
    isChromeOS: isChromeOS(),
    trackingId,
    IS_USE_UNIFIED_VIDEO_RENDER: isUseUnifiedRender(),
    reqUseWBVideo: globalVariable.useWBVideo,
    useWBVideo,
    isLowVersionBrowser: showBrowserUpgradeBanner(),
    audioBridgeToWasm,
    isAudioBridge: currentAudioBridge,
    isRLBisTP: WCSockets.get(SocketType.RWG).socket?.isRlb,
    showGraphicAccelerationWarning: showGraphicAccelerationWarning(),
    userRole: currentUser?.userRole,
    ...parseJoinMeetingResponse(message),
    ...caps,
    joinFlowTelemetry: getJoinFlowTelemetryData(),
    desktopWebrtc:
      meetingConfig.commonWCFeatureConfig?.mobileWebClientConfig
        ?.desktop_webrtc,
    webclientFeatureOptions: meetingConfig.webclientFeatureOptions,
    mediaFeatureOptions: meetingConfig.mediaFeatureOptions,
  };
};

const reportInfo = async (getState, message) => {
  if (!isEnableJMFLog()) {
    return;
  }
  const meetingInfo = await connectionInfoPayloadMaker(getState, message);
  cleanJoinFlowTelemetryData();
  window.connectionInfo = meetingInfo;
  directReport(meetingInfo, {
    tags: ['connection_info_map'],
    isConnectionInfoMap: true,
  });
  return meetingInfo;
};

export const reportConnectionInfo = (message) => (dispatch, getState) => {
  if (message?.body?.conID) {
    dispatch(
      sendSocketMonitorLog(
        `conId:${message.body?.conID}, trackingId:${getTrackingId()}`,
      ),
    );
  }

  reportInfo(getState, message).catch(() => {});
};
