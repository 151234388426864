/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import Modal from '../../../../global/components/widget/modal';
import React, { useEffect } from 'react';
import { getBaseUrl } from '../../../../tasks/global/task-utils/common-utils';
import previewI18n from '../../resource';

const AskPermissionPrompt = (props) => {
  const {
    askMicrophoneOnly,
    setAskMicrophoneOnly,
    show,
    onClose,
    handleAskPermissionResolve,
  } = props;

  useEffect(() => {
    const timer = setInterval(() => {
      const permissonButton = document.querySelector('#ask-permission-button');
      if (permissonButton) {
        permissonButton.addEventListener('resolve', handleAskPermissionResolve);
        clearInterval(timer);
      }
    }, 500);

    return () => {
      clearInterval(timer);
      const permissonButton = document.querySelector('#ask-permission-button');
      permissonButton?.removeEventListener(
        'resolve',
        handleAskPermissionResolve,
      );
    };
  }, [askMicrophoneOnly, handleAskPermissionResolve, show]);

  const askPermissionType = askMicrophoneOnly
    ? 'microphone'
    : 'camera microphone';

  return (
    <Modal
      visible={show}
      okText=""
      cancelText=""
      className="ask-permission-prompt-container"
      isModal
      customModalStyle={{
        overlay: {
          zIndex: 10001,
        },
      }}
    >
      <div className="ask-permission-prompt">
        <img
          className="zoom-placeholder"
          src={`${getBaseUrl()}/image/zoom-placeholder.png`}
          alt="zoom-placeholder"
        />
        <div className="desc-primary">
          {askMicrophoneOnly
            ? previewI18n.AskMicPermissionConfirm
            : previewI18n.AskPermissionConfirm}
        </div>
        <div className="desc-secondary">{previewI18n.PermissionTip3}</div>
        <permission
          className="ask-permission-button"
          id="ask-permission-button"
          type={askPermissionType}
          key={`ask-permission-prompt-${askMicrophoneOnly}`}
        />
        <div
          className="continue-without-mic-camera"
          onClick={() => {
            if (!askMicrophoneOnly) {
              setAskMicrophoneOnly(true);
            } else {
              onClose();
            }
          }}
          role="button"
          tabIndex={0}
        >
          {previewI18n.ContinueWithoutMicAndCamera}
        </div>
      </div>
    </Modal>
  );
};

export default AskPermissionPrompt;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/ask-permission-prompt/AskPermissionPrompt.scss';
