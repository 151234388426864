// Supports presenter and viewer to annotate screen shares.

import meetingConfig from 'meetingConfig';
import { easyStore, storeType } from '../../global/easy-store';
import { ANNO_CAPABILITY } from './enum';

/**
 * 0: all disabled
 * 1: only viewer enabled
 * 2: all enabled
 * @returns {ANNO_CAPABILITY} cap
 */
const getAnnotationCapability = () => {
  if (!meetingConfig.mediaFeatureOptions) {
    return 0;
  }

  const cap =
    JsMediaSDK_Instance?.util?.getAnnotationCapability?.(
      meetingConfig.mediaFeatureOptions,
    ) ?? 0;

  easyStore.easySet('annotation_ab_option', cap, storeType.memory);

  return cap;
};

export const isEnableAnnotateCap = () => {
  const cap =
    easyStore.easyGet('annotation_ab_option') ?? getAnnotationCapability();
  return cap === ANNO_CAPABILITY.ENABLED;
};
export const isOnlyEnableViewerAnnotateCap = () => {
  const cap =
    easyStore.easyGet('annotation_ab_option') ?? getAnnotationCapability();
  return cap === ANNO_CAPABILITY.ONLY_VIEWER_ENABLED;
};
