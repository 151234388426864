import { VIDEO_LAYOUT_SPEAK_VIEW } from '../../../features/video/enum';
import { GOOGLE_MEET_LAYOUTS } from './enum';

export const generateLayoutConfig = (state = {}) => {
  const {
    video: {
      UI: { videoLayout },
      shouldRebackPreviousView,
    },
  } = state;

  let config = {};

  if (videoLayout || shouldRebackPreviousView) {
    const isSpeakerView =
      videoLayout === VIDEO_LAYOUT_SPEAK_VIEW ||
      shouldRebackPreviousView === VIDEO_LAYOUT_SPEAK_VIEW;
    const currentlySelectedLayout = isSpeakerView
      ? GOOGLE_MEET_LAYOUTS.TOPBAR.id
      : GOOGLE_MEET_LAYOUTS.GRID.id;
    config = {
      currentlySelectedLayout: currentlySelectedLayout,
      availableLayouts: [
        {
          id: GOOGLE_MEET_LAYOUTS.GRID.id,
          localizedName: GOOGLE_MEET_LAYOUTS.GRID.name,
        },
        {
          id: GOOGLE_MEET_LAYOUTS.TOPBAR.id,
          localizedName: GOOGLE_MEET_LAYOUTS.TOPBAR.name,
        },
      ],
    };
  }

  return config;
};

export const generateSideBySideLayoutConfig = () => {
  return {
    currentlySelectedLayout: GOOGLE_MEET_LAYOUTS.SIDEBAR_RIGHT.id,
    availableLayouts: [
      {
        id: GOOGLE_MEET_LAYOUTS.SIDEBAR_RIGHT.id,
        localizedName: GOOGLE_MEET_LAYOUTS.SIDEBAR_RIGHT.name,
      },
    ],
  };
};
