/* eslint-disable react/prop-types */
import Modal from '../../../../global/components/widget/modal';
import React from 'react';
import { getBaseUrl } from '../../../../tasks/global/task-utils/common-utils';
import { ELEMENT_PLACEHOLDER } from '../../../../global/constant';
import previewI18n from '../../resource';
import reactStringReplace from '../../../../global/resct-string-replace';

const placeholder = ELEMENT_PLACEHOLDER;
const AllowPermissionPrompt = (props) => {
  const tipText = previewI18n.permissionTipFn(placeholder);
  const tip = reactStringReplace(tipText, placeholder, () => {
    return <u>{previewI18n.Allow}</u>;
  });
  return (
    <Modal
      visible={props.show}
      okText=""
      cancelText=""
      className="allow-permission-prompt-container"
      isModal
    >
      <div className="allow-permission-prompt">
        <img
          className="zoom-placeholder"
          src={`${getBaseUrl()}/image/zoom-placeholder.png`}
          alt="zoom-placeholder"
        />
        <div className="desc-primary">{tip}</div>
        <div className="desc-secondary">{previewI18n.PermissionTip3}</div>
      </div>
    </Modal>
  );
};

export default AllowPermissionPrompt;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/allow-permission-prompt/AllowPermissionPrompt.scss';
