import { iText } from '../../global/util';

export const JOIN_AUDIO_BY_PC = iText(
  'Join Audio by Computer',
  'apac.wc_join_audio_by_pc',
);
export const LEAVE_PC_AUDIO = iText(
  'Leave Computer Audio',
  'apac.wc_leave_pc_audio',
);
export const PC_AUDIO = iText('Computer Audio', 'apac.wc_pc_audio');
export const PC_AUDIO_CONNECTED = iText(
  'Computer Audio - Connected',
  'apac.wc_pc_audio_connected',
);
export const ARIA_CLOSE = iText('Close', 'apac.wc_chat.close');
export const SELECT_MICROPHONE = iText(
  'Select a Microphone',
  'apac.wc_select_microphone',
);
export const SELECT_SPEAKER = iText(
  'Select a Speaker',
  'apac.wc_select_speaker',
);

export const AUDIO_CONNECTING_THUMBNAIL_TEXT = iText(
  'Connecting to audio',
  'apac.wc_audio_connecting_thumbnail',
);

export function displayAudioConnectingActiveSpeakerText(displayUserName) {
  return iText(
    `${displayUserName} is connecting to audio and cannot hear you yet`,
    'apac.wc_audio_connecting_active_speaker',
    displayUserName,
  );
}

export function displayAudioConnectingText(displayUserName) {
  return iText(
    `${displayUserName} is connecting to audio`,
    'apac.wc_audio_connecting',
    displayUserName,
  );
}

export function displayAudioConnectingSuccessText(displayUserName) {
  return iText(
    `${displayUserName} connected to audio`,
    'apac.wc_audio_connecting_success',
    displayUserName,
  );
}

export function displayAudioConnectingFailedText(displayUserName) {
  return iText(
    `${displayUserName} did not connect to audio`,
    'apac.wc_audio_connecting_failed',
    displayUserName,
  );
}

export const AUDIO_OPTIONS = iText('Audio Options', 'apac.wc_audio_options');
export const AUDIO = iText('Audio', 'apac.wc_audio');
export const MUTE = iText('Mute', 'apac.toolbar_mute');
export const JOIN_AUDIO = iText('Join Audio', 'apac.toolbar_join_audio');
export const AUDIO_SETTING = iText('Audio Setting', 'apac.wc_audio_setting');
export const AUDIO_SETTINGS = iText('Audio Settings', 'apac.wc_audio_settings');
export const TAB_PHONE = iText('Phone Call', 'apac.wc_phone_call');
export const TAB_PHONE_CONNECTED = iText(
  'Phone Call - Connected',
  'apac.wc_phone_call_connect',
);
export const TAB_VOIP = iText('Computer Audio', 'apac.wc_pc_audio');
export const TAB_VOIP_CONNECTED = iText(
  'Computer Audio - Connected',
  'apac.wc_pc_audio_connected',
);
export const TAB_CALL_ME = iText('Call Me', 'apac.dialog.btn_callme');
export const TAB_CALL_ME_CONNECTED = iText(
  'Call Me - Connected',
  'apac.dialog.btn_callme_connected',
);
export const TAB_3RD_PARTY_AUDIO = iText(
  'Join by 3rd Party Audio',
  'apac.join_3prty_audio',
);
export const THIRD_PARTY_JOIN_INTRODUCTION = iText(
  'Follow the instructions below to join audio',
  'apac.wc_follow_intro_join_audio',
);

export const UPGRADE_BROWSER = iText(
  'Your browser doesn’t support using computer’s Audio device, please upgrade your browser to the lastest version.',
  'apac.wc_upgrade_browser',
);

export const INSTALL_BROWSER = iText(
  'Your browser does not support using the computer’s Audio device. To use Zoom, install the latest version of a standard browser, such as Chrome, Firefox and Chromium Edge.',
  'apac.wc_change_browser_2',
);

export const UPGRADE_CHROME_BROWSER = iText(
  'For better audio quality, please update your browser to the latest version.',
  'apac.wc_upgrade_chrome_browser',
);

export const PREVENT_MICROPHONE = iText(
  'Your browser is preventing access to your microphone.',
  'apac.wc_prevent_access_mic',
);
export const LEARN = iText('Learn', 'apac.wc_learn');
export const HOW_TO_ALLOW_MICROPHONE = iText(
  'how to allow access to your microphone.',
  'apac.wc_how_to_allow',
);
export const PHONE_CALL_TIP_1 = iText(
  'Please use your phone',
  'apac.wc_please_use_your_phone',
);
export const PHONE_CALL_TIP_2 = iText(
  'to call in to the meeting',
  'apac.wc_to_call_in_to_the_meeting',
);
export const PHONE_CALL_CONNECTED_1 = iText(
  'Already joined by telephone? Press #',
  'apac.wc_already_joined_by_telephone',
);
export const PHONE_CALL_CONNECTED_2 = iText(
  'on your phone.',
  'apac.wc_on_your_phone',
);
export const PASSWORD = iText('Passcode', 'apac.wc_tel_password');
export const TOLL_FREE = iText('Toll Free', 'apac.dialog.tollfree');
export const TOLL = iText('Toll', 'apac.dialog.toll');
export const DIAL_IN = iText(
  'Dial in based on your location',
  'apac.dialog.dial_in',
);
export const MEETING_ID = iText('Meeting ID', 'apac.wc_meetingid');
export const PARTICIPANT_ID = iText('Participant ID', 'apac.wc_participant_id');
export const PSTN_WARN_CONTACT = iText(
  'The meeting does not support dial in or Call me to join. You can not connect to the audio.',
  'apac.wc_pstn_warning_contact',
);
export const CALLING = iText('Calling...', 'apac.meeting_calling2');
export const RING = iText('Ring...', 'apac.meeting_ringing');
export const CALL_ACCEPTED = iText('Call accepted', 'apac.meeting_accept');
export const BUSY = iText('Busy', 'apac.meeting_busy');
export const FAIL_TO_CALL = iText('Fail to call', 'apac.meeting_fail');
export const PHONE_CONNECTED = iText(
  'Phone Connected',
  'apac.wc_phone_connected',
);
export const DISCONNECTING_CALL = iText(
  'Disconnecting Call',
  'apac.meeting_disconnect',
);
export const FAIL_TO_DISCONNECT_CALL = iText(
  'Fail to disconnect call',
  'apac.meeting_fail_disconnect',
);
export const DOSE_NOT_SUPPORT_COUNTRY = iText(
  'The meeting does not support to call to your country. You can try to Phone Call to connect.',
  'apac.dialog.does_not_support_country',
);
export const PHONE_NUMBER = iText(
  'Phone Number',
  'apac.dialog.placeholder_phone_number',
);
export const ENTER_YOUR_PHONE_NUMBER = iText(
  'Enter your phone number',
  'apac.dialog.placeholder_enter_your_phone_number',
);
export const REMEMBER_NUMBER = iText(
  'Remember the number on this computer',
  'apac.dialog.remeber_number',
);
export const COULD_NOT_FIND_NUMBER = iText(
  'We could not find your phone number. Please contact your admin.',
  'apac_dialog.cound_not_find_number',
);
export const CALL_YOUR_NUMBER = iText(
  'Call your phone number to join audio conference',
  'apac.dialog.call_your_number',
);
export const HANG_UP = iText('Hang Up', 'apac.dialog.btn_hangup');
export const USING_COMPUTER_AUDIO_TIP = iText(
  'Your audio is connected using Computer Audio',
  'apac.wc_audio.using_audio_tip',
);
export const USING_DIAL_IN_TIP = iText(
  'Your audio is connected using Dial In',
  'apac.wc_audio.using_audio_dial_tip',
);

export const HOST_MUTED_YOU = iText(
  'The host muted you',
  'apac.wc_audio.muted_notification1',
);
export const HOST_MUTED_EVERYONE = iText(
  'The host muted ereryone',
  'apac.wc_audio.muted_notification2',
);
export const HOST_UNMUTED_YOU = iText(
  'The host unmuted you',
  'apac.wc_audio.unmuted_notification1',
);
export const HOST_SPOTLIGHT_YOU = iText(
  'The host has spotlighted your video for everyone.',
  'apac.wc_audio.spotlight_notification',
);
export const YOUR_MIC_OFF_WARNING = iText(
  'Your microphone is turned off',
  'apac.wc_audio.mic_off_warning',
);
export const YOUR_CAM_OFF_WARNING = iText(
  'Your camera is turned off',
  'apac.wc_audio.cam_off_warning',
);
export const YOUR_MIC_CAM_OFF_WARNING = iText(
  'Your microphone and camera are turned off',
  'apac.wc_audio.mic_cam_off_warning',
);
export const ASK_TO_UNMUTE_TEXT = iText(
  'Ask to Unmute',
  'apac.toolbar_ask_unmute',
);
export const UNMUTE_TEXT = iText('Unmute', 'apac.toolbar_unmute');

export const speakingWhenMutedTipFn1 = (shortcut) =>
  iText(
    `You are muted, press (${shortcut}) to unmute your microphone or press and hold the SPACE key to temporarily unmute`,
    'apac.audio.speaking_when_muted_tip1',
    shortcut,
  );
export const speakingWhenMutedTipFn2 = (shortcut) =>
  iText(
    `You are muted, press (${shortcut}) to unmute your microphone`,
    'apac.audio.speaking_when_muted_tip2',
    shortcut,
  );

export const youAreMuted = iText('You are muted', 'apac.audio.you_are_muted');

export const speakingWhenMutedTip = iText(
  'You are muted, press and hold the SPACE key to temporarily unmute',
  'apac.audio.speaking_when_muted_tip',
);

export const speakingWhenMutedTipFn_new = (shortcut) =>
  iText(
    `Press (${shortcut}) to unmute your microphone or press and hold the SPACE key to temporarily unmute`,
    'apac.audio.speaking_when_muted_tip1_new',
    shortcut,
  );
export const speakingWhenMutedTipFn2_new = (shortcut) =>
  iText(
    `Press (${shortcut}) to unmute your microphone`,
    'apac.audio.speaking_when_muted_tip2_new',
    shortcut,
  );

export const speakingWhenMutedTip_new = iText(
  'Press and hold the SPACE key to temporarily unmute',
  'apac.audio.speaking_when_muted_tip_new',
);

export const TEST_SPEAKER_AND_MICROPHONE = iText(
  'Test Speaker & Microphone',
  'apac.audio.test_speaker_and_device',
);

export const CAN_NOT_DETECT_MICROPHONE = iText(
  'Cannot detect your microphone, please check the device and connection and try again.',
  'apac.wc_audio.cannot_detect_mircophone',
);

export const UNMUTE_MIC_ALERT_TITLE = iText(
  'Your mic is muted in system or browser settings.',
  'apac.audio.unmute_mic_alert_title_new',
);

export const UNMUTE_MIC_ALERT_DESC = iText(
  'Please open your settings to unmute and adjust the level',
  'apac.audio.unmute_mic_alert_desc_new',
);

export const OPEN_SOUND_SETTINGS = iText(
  'Open sound settings',
  'apac.audio.open_sound_settings',
);

export const USE_AUDIO_BRIDGE_STRATEGY = iText(
  'Use Audio Bridge Strategy',
  'apac.audio.use_audio_bridge_strategy',
);

export const WORKLET_PROCESS_EXCEPTIONS_TEXT = iText(
  'Oops! Something went wrong. Please rejoin audio to continue.',
  'apac.common.worklet_process_exceptions_text',
);

export const AUDIO_CAPTURE_FAILED_UNKONWN_REASON_TEXT = iText(
  'Your microphone is not working. Please check your connection or use a different mic.',
  'apac.common.audio_capture_failed_unknown_reason_text',
);

export const AUDIO_CAPTURE_FAILED_DEVICE_IN_USE_TEXT = iText(
  'Your microphone is being used by other apps. Close those apps and try again.',
  'apac.common.audio_capture_failed_device_in_use_text',
);

export const MUTED_TOAST = iText(
  'You are muted now.',
  'apac.audio.muted_toast',
);

export const AUDIO_TAG_ERROR_TEXT = iText(
  'Oops! Something went wrong. Please refresh your browser to try again.',
  'apac.common.audio_tag_error_text',
);
