import { createSelector } from 'reselect';
import meetingConfig from 'meetingConfig';
import { inMeetingParticipantsSelector, coOrHostSelector } from './selector';
import { spotlightVideoListSelector } from '../../features/video/redux/selectors/video-common-selector';
import {
  getAttendeeType,
  isChatEnabled,
  isSupportReport,
  isParticipantSupportReport,
  isZRMultiStreamVideoChildUser,
  isZRMultiStreamVideoParentUser,
  isH323User,
} from '../service';

import {
  isMeInRoomSelector,
  participantShouldNotBeMadeHostBecauseSelfSelectCapsSelector,
} from '../../features/breakout-room/redux/bo-room-selector';

import {
  WEBINAR_ATTENDEES_WEB,
  WEBINAR_ATTENDEES,
} from '../../constants/UserRoles';
import {
  CAPS_OPTION_SUPPORT_COHOST,
  CAPS_OPTION_SUPPORT_CLOSEDCAPTION,
  JOIN_MEETING_POLICY,
} from '../constant';
import { onlyExistManualCCSelector } from '../../features/new-live-transcription/redux/new-LT-selector';
import { isSignLanguageInterpreterAllowedToTalkSelector } from '../../features/interpretation/redux/interpretation-selector';
import { isSupportMultiView } from '../util';
import { isWebinar } from '../service/meeting-types';
import { checkRole, isPanelist } from '../service/user-types';

const participantPropsSelector = (_, { participant }) => participant;

const securityStatesSelector = ({ security: { reportDomain } }) => reportDomain;

const boAttendeeStatusSelector = ({
  breakoutRoom: {
    attendee: { status },
  },
}) => status;
const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

const meetingStatesSelector = ({
  meeting: {
    isHost,
    currentUser,
    currentUser: { userId, isGuest, bCoHost, bVideoOn },
    bAllowAttendeeRename,
    meetingOptions: { enableWaitingRoom, allowPutOnHold },
    bCanUnmuteVideo,
    restrictFeatures,
  },
}) => ({
  isHost,
  currentUser,
  userId,
  isGuest,
  bCoHost,
  bVideoOn,
  bAllowAttendeeRename,
  enableWaitingRoom,
  allowPutOnHold,
  bCanUnmuteVideo,
  restrictFeatures,
});

export const pinVideoListSelector = ({ video: { pinVideoList } }) =>
  pinVideoList;

const isAttendeeSelector = createSelector(
  [participantPropsSelector],
  (participant) => participant.role === WEBINAR_ATTENDEES,
);

const isMoreThanTwoSelector = createSelector(
  [inMeetingParticipantsSelector],
  (inMeetingParticipants) =>
    inMeetingParticipants && inMeetingParticipants.length > 2,
);

const getUserRoleSelector = createSelector(
  [participantPropsSelector],
  (participant) => {
    return checkRole(participant);
  },
);

// updated
const isShowReportSelector = createSelector(
  [securityStatesSelector, boAttendeeStatusSelector, coOrHostSelector],
  (reportDomain, boAttendeeStatus, coOrHost) => {
    return coOrHost
      ? isSupportReport(reportDomain, boAttendeeStatus)
      : isParticipantSupportReport(reportDomain, boAttendeeStatus);
  },
);

const isChatEnableSelector = createSelector(
  [participantPropsSelector, isAttendeeSelector],
  (participant, isAttendee) => {
    const isChatEnable =
      isChatEnabled() &&
      (!isAttendee ||
        (isAttendee &&
          getAttendeeType(participant.jid) === WEBINAR_ATTENDEES_WEB));
    return isChatEnable;
  },
);

const isPrivateShowChatSelector = createSelector(
  [
    participantPropsSelector,
    isChatEnableSelector,
    coOrHostSelector,
    meetingStatesSelector,
  ],
  (participant, isChatEnable, coOrHost, { currentUser, restrictFeatures }) => {
    if (isZRMultiStreamVideoChildUser(participant) || isH323User(participant)) {
      return false;
    }

    return (
      isChatEnable &&
      participant.userId !== currentUser.userId &&
      (meetingConfig.meetingOptions.isPrivateChatEnabled ||
        (coOrHost && !isWebinar())) &&
      !restrictFeatures[JOIN_MEETING_POLICY.CHAT]
    );
  },
);

const isSpotlightedSelector = createSelector(
  [participantPropsSelector, spotlightVideoListSelector],
  (participant, spotlightVideoList) =>
    spotlightVideoList.includes(participant.userId),
);

const isShowSpotlightSelector = createSelector(
  [
    participantPropsSelector,
    coOrHostSelector,
    isMoreThanTwoSelector,
    isMeInRoomSelector,
    (state, { participant }) =>
      isSignLanguageInterpreterAllowedToTalkSelector(state, participant),
  ],
  (
    participant,
    coOrHost,
    isMoreThanTwo,
    isMeInRoom,
    isSignLanguageInterpreterAllowedToTalk,
  ) =>
    !isMeInRoom &&
    participant.bVideoOn &&
    isSignLanguageInterpreterAllowedToTalk &&
    isMoreThanTwo &&
    coOrHost,
);

const isShowAddSpotlightSelector = createSelector(
  [
    participantPropsSelector,
    spotlightVideoListSelector,
    isShowSpotlightSelector,
  ],
  (participant, spotlightVideoList, isShowSpotlight) =>
    isShowSpotlight && !spotlightVideoList.includes(participant.userId),
);

const canUpdateLeadershipSelector = createSelector(
  [coOrHostSelector, isMoreThanTwoSelector, isMeInRoomSelector],
  (coOrHost, isMoreThanTwo, isMeInRoom) =>
    !isMeInRoom && isMoreThanTwo && coOrHost,
);

const isShowRemoveSpotlightSelector = createSelector(
  [
    participantPropsSelector,
    spotlightVideoListSelector,
    canUpdateLeadershipSelector,
  ],
  (participant, spotlightVideoList, hostCanUpdateLeaderShip) =>
    hostCanUpdateLeaderShip && spotlightVideoList.includes(participant.userId),
);

const isShowUpdateSpotlightSelector = createSelector(
  [
    participantPropsSelector,
    spotlightVideoListSelector,
    isShowSpotlightSelector,
  ],
  (participant, spotlightVideoList, isShowSpotlight) =>
    isShowSpotlight &&
    spotlightVideoList.length > 0 &&
    !spotlightVideoList.includes(participant.userId),
);

const isShowMakeHostSelector = createSelector(
  [
    isMeInRoomSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantShouldNotBeMadeHostBecauseSelfSelectCapsSelector,
    participantPropsSelector,
  ],
  (
    isMeInRoom,
    userRole,
    { isHost },
    participantShouldNotBeMadeHostBecauseSelfSelectCaps,
    participant,
  ) => {
    if (!(isHost && !isMeInRoom && !userRole.isHost)) {
      return false;
    }

    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }

    if (participantShouldNotBeMadeHostBecauseSelfSelectCaps) {
      return false;
    }

    if (!isWebinar()) {
      return true;
    }

    if (isWebinar() && (userRole.isPanelist || userRole.isCoHost)) {
      return true;
    }

    return false;
  },
);

const isShowMakeCoHostSelector = createSelector(
  [
    isMeInRoomSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantPropsSelector,
  ],
  (isMeInRoom, userRole, { isHost, currentUser }, participant) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }

    return (
      meetingConfig.meetingOptions.isCOHostEnabled &&
      isHost &&
      !isMeInRoom &&
      participant &&
      currentUser &&
      !!(participant.caps & CAPS_OPTION_SUPPORT_COHOST) &&
      (isWebinar()
        ? userRole.isPanelist
        : !(userRole.isCoHost || userRole.isHost))
    );
  },
);

const isShowWithdrawCoHostSelector = createSelector(
  [
    isMeInRoomSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantPropsSelector,
  ],
  (isMeInRoom, userRole, { isHost }, participant) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }

    return isHost && !isMeInRoom && userRole.isCoHost;
  },
);

const isShowMoveToWaitingRoomSelector = createSelector(
  [
    isMeInRoomSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantPropsSelector,
  ],
  (isMeInRoom, userRole, { isHost, bCoHost, allowPutOnHold }, participant) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }

    return (
      !isMeInRoom &&
      (isHost || bCoHost) &&
      (userRole.isAttendee || userRole.isPanelist) &&
      (!isWebinar() || (isWebinar() && allowPutOnHold && userRole.isPanelist))
    );
  },
);

const isShowRemoveSelector = createSelector(
  [getUserRoleSelector, meetingStatesSelector, participantPropsSelector],
  (userRole, { isHost, bCoHost }, participant) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }

    return (isHost || bCoHost) && (userRole.isAttendee || userRole.isPanelist);
  },
);

const newLTmanualCCData = ({
  newLiveTranscription: { isNewLTManualCCOn, newLTFeatureEnabled },
}) => ({ isNewLTManualCCOn, newLTFeatureEnabled });

const isShowCCPermissionSelector = createSelector(
  [
    onlyExistManualCCSelector,
    isMeInRoomSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantPropsSelector,
    newLTmanualCCData,
  ],
  (
    onlyExistManualCC,
    isMeInRoom,
    userRole,
    { isHost, currentUser, restrictFeatures },
    participant,
    { isNewLTManualCCOn, newLTFeatureEnabled },
  ) => {
    if (isZRMultiStreamVideoChildUser(participant)) {
      return false;
    }
    const { isEnableClosedCaption } = meetingConfig.meetingOptions;

    return (
      (!newLTFeatureEnabled || isNewLTManualCCOn || onlyExistManualCC) &&
      isHost &&
      !isMeInRoom &&
      participant.userId !== currentUser.userId &&
      (!isWebinar() ||
        (isWebinar() && (userRole.isPanelist || userRole.isCoHost))) &&
      isEnableClosedCaption &&
      !restrictFeatures[JOIN_MEETING_POLICY.CC] &&
      Boolean(participant.caps & CAPS_OPTION_SUPPORT_CLOSEDCAPTION)
    );
  },
);

const isMeSelector = createSelector(
  [participantPropsSelector, meetingStatesSelector],
  (participant, { currentUser }) => {
    return participant.userId === currentUser.userId;
  },
);

const isShowRenameSelector = createSelector(
  [isMeSelector, getUserRoleSelector, meetingStatesSelector],
  (isMe, userRole, { isHost, bCoHost, bAllowAttendeeRename }) =>
    isHost ||
    (bCoHost && (userRole.isAttendee || userRole.isPanelist || isMe)) ||
    (isMe && bAllowAttendeeRename), // for avatar
);

const isShowAllowMultiPinSelector = createSelector(
  [participantPropsSelector, coOrHostSelector, getUserRoleSelector],
  (participant, coOrHost, userRole) => {
    return (
      participant.bCapsPinMultiVideo &&
      coOrHost &&
      !userRole.isCoHost &&
      !userRole.isHost &&
      !userRole.isWebinarAttendee
    );
  },
);

const isAllowMultiPinSelector = createSelector(
  [participantPropsSelector],
  (participant) => {
    return participant.bCanPinMultiVideo;
  },
);

const isShowAttendeeVideoSelector = createSelector(
  [
    isMeSelector,
    coOrHostSelector,
    getUserRoleSelector,
    meetingStatesSelector,
    participantPropsSelector,
  ],
  (isMe, coOrHost, userRole, { bCanUnmuteVideo }, participant) => {
    if (!(coOrHost || isMe)) {
      return false;
    }

    if (!(bCanUnmuteVideo || participant.bVideoOn)) {
      return false;
    }

    if (!isWebinar()) {
      return true;
    }

    if (
      isWebinar() &&
      (userRole.isPanelist || userRole.isCoHost || userRole.isHost)
    ) {
      return true;
    }

    return false;
  },
);

// updated
const isShowPinSelector = createSelector(
  [
    participantPropsSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    coOrHostSelector,
    currentUserSelector,
    (state, { participant }) =>
      isSignLanguageInterpreterAllowedToTalkSelector(state, participant),
  ],
  (
    participant,
    pinVideoList,
    spotlightVideoList,
    coOrHost,
    currentUser,
    isSignLanguageInterpreterAllowedToTalk,
  ) => {
    if (pinVideoList.includes(participant.userId)) {
      return false;
    }

    if (!isSignLanguageInterpreterAllowedToTalk) {
      return false;
    }

    if (coOrHost) {
      return true;
    }

    if (!currentUser.bCanPinMultiVideo && pinVideoList.length > 0) {
      return false;
    }

    if (isPanelist(currentUser.userRole)) {
      return true;
    }

    if (spotlightVideoList.length > 0) {
      return false;
    }

    return true;
  },
);

const isPinedSelector = createSelector(
  [participantPropsSelector, pinVideoListSelector],
  (participant, pinVideoList) => pinVideoList.includes(participant.userId),
);

const getReceiversSelector = createSelector(
  [meetingStatesSelector, inMeetingParticipantsSelector],
  ({ currentUser }, inMeetingParticipants) => {
    return inMeetingParticipants.filter(
      (participant) => participant.userId !== currentUser.userId,
    );
  },
);

const isShowZrMainUserMenuSelector = createSelector(
  [
    participantPropsSelector,
    isSpotlightedSelector,
    isPinedSelector,
    inMeetingParticipantsSelector,
  ],
  (participant, isSpotlighted, isPined, inMeetingParticipants) => {
    if (!isSupportMultiView()) {
      return false;
    }
    const hasChildUser = inMeetingParticipants.some((item) =>
      isZRMultiStreamVideoChildUser(item),
    );
    if (!hasChildUser) {
      return false;
    }

    if (isSpotlighted || isPined) {
      return false;
    }

    if (!participant.bVideoOn || participant.bRaiseHand) {
      return false;
    }

    if (
      isZRMultiStreamVideoChildUser(participant) ||
      isZRMultiStreamVideoParentUser(participant)
    ) {
      return true;
    }
    return false;
  },
);

const isShowNameBoxSelector = createSelector(
  [participantPropsSelector],
  (participant) => !participant.bDisableNameBox,
);

export {
  isAttendeeSelector,
  isMoreThanTwoSelector,
  isShowReportSelector,
  getUserRoleSelector,
  isPrivateShowChatSelector,
  isShowSpotlightSelector,
  isShowMakeHostSelector,
  isShowMakeCoHostSelector,
  isShowWithdrawCoHostSelector,
  isShowMoveToWaitingRoomSelector,
  isShowRemoveSelector,
  isShowCCPermissionSelector,
  isShowRenameSelector,
  isSpotlightedSelector,
  isShowAllowMultiPinSelector,
  isAllowMultiPinSelector,
  isShowAddSpotlightSelector,
  isShowRemoveSpotlightSelector,
  isShowUpdateSpotlightSelector,
  isShowAttendeeVideoSelector,
  isShowPinSelector,
  isPinedSelector,
  getReceiversSelector,
  isShowZrMainUserMenuSelector,
  isShowNameBoxSelector,
};
