import { SIDE_BY_SIDE_GALLERY } from '../../../global';
import { GALLERY_VIEW, SPEAKER_VIEW } from './resource';

export const ZOOM_CONNECTOR_EVT_ENUM = Object.freeze({
  MUTE_AUDIO: 'muteAudio',
  UNMUTE_AUDIO: 'unmuteAudio',
  MUTE_VIDEO: 'muteVideo',
  UNMUTE_VIDEO: 'unmuteVideo',
  USER_INITIATED_LOG_UPLOAD: 'userInitiatedLogUpload',
  START_CONTENT_SHARE: 'startContentShare',
  STOP_CONTENT_SHARE: 'stopContentShare',
  MUTE_CONTENT_SHARE_AUDIO: 'muteContentShareAudio',
  UNMUTE_CONTENT_SHARE_AUDIO: 'unmuteContentShareAudio',
  LAYOUT_CHANGE: 'layoutChange',
});

// the specpfic view names here are exposed on Google Meet UI, and should be localized
export const GOOGLE_MEET_LAYOUTS = Object.freeze({
  GRID: {
    id: 'grid',
    name: GALLERY_VIEW,
  },
  TOPBAR: {
    id: 'topbar',
    name: SPEAKER_VIEW,
  },
  SIDEBAR_RIGHT: {
    id: 'sidebarright',
    name: SIDE_BY_SIDE_GALLERY,
  },
});

export const GOOGLE_MEET_MESSAGE = Object.freeze({
  REQUEST_MEET_SYSTEM_STATE: 'roomSystemStateRequest',
});

export const GOOGLE_MEET_END_MEETING_REASON = Object.freeze({
  LOCAL_HANGUP: 'hangup',
});

export const GOOGLE_MEET_TRANSITION_STATE = Object.freeze({
  UNKNOWN: 'unknown',
  CONNECTING: 'connecting',
  LOBBY: 'lobby',
  CONNECTED: 'connected',
  DISCONNECTING: 'disconnecting',
  POST_PROCESSING: 'postProcessing',
  DISCONNECTED: 'disconnected',
});

export const GOOGLE_MEET_ERROR_TYPE = Object.freeze({
  DEVICES: 'devices',
  NETWORK: 'network',
  VERSION: 'version',
  OTHER: 'other',
});

export const GOOGLE_MEET_ERROR_CODE_PREFIX = Object.freeze({
  BAD_REQUEST: '400',
  UNAUTHROIZED: '401',
  FORBIDDEN: '403',
  NOT_FOUND: '404',
  TOO_MANY_REQUESTS: '429',
  INTERNAL_SERVER_ERROR: '500',
  APPLICATION_ERROR: '600',
});

export const GOOGLE_MEET_ERROR_CODE_SUFFIX = Object.freeze({
  DEFAULT: '000',
  MUTE_AUDIO_FAILED: '001',
  UNMUTE_AUDIO_FAILED: '002',
  MUTE_VIDEO_FAILED: '003',
  UNMUTE_VIDEO_FAILED: '004',
  HANGUP_FAILED: '005',
  CONTENT_SHARE_NOT_ALLOWED: '006',
  CONTENT_SHARE_FAILED_TO_OPEN_MEDIA_STREAM: '007',
  CONTENT_SHARE_MEDIA_STREAM_TRACK_ENDED: '008',
  CONTENT_SHARE_FAILED: '009',
  CONTENT_SHARE_AUDIO_MUTE_FAILED: '010',
  CONTENT_SHARE_AUDIO_UNMUTE_FAILED: '011',
  CONTENT_SHARE_AUDIO_UNMUTE_NOT_ALLOWED: '012',
});

export const GOOGLE_MEET_ERROR_SEVERITY = Object.freeze({
  FATAL: 'fatal',
  WARNING: 'warning',
});

export const CONTENT_SHARE_METHOD = Object.freeze({
  GET_USER_MEDIA: 'getUserMedia',
  GET_DISPLAY_MEDIA: 'getDisplayMedia',
});

export const CONTENT_SHARE_STATE = Object.freeze({
  ACTIVE: 'active',
  INACTIVE: 'inactive',
});

export const VIDEO_RESOLUTION = Object.freeze({
  WIDTH: 1920,
  HEIGHT: 1080,
});
