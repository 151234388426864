import { isAllowOnlyCanTalk, isViewOnly } from '../../../service/user-types';
import { coOrHostSelector } from '../../../redux/selector';
import { bMutedUponEntryOnSelector } from '../../../selectors/meeting-selector';
import { easyStore } from '../../../easy-store';
import { SESSIONSTORAGE_KEYS } from '../../../constant';
import { canAVEnableSelector } from '../../../../features/audio/redux/audio-selector';
import _ from 'lodash';
import { isAutoUnmuteAudio } from '../../../../controller';

export const isNeedUnmute = (state) => {
  const viewOnly = isViewOnly(state.meeting.currentUser.userRole);
  const bAllowOnlyCanTalk = isAllowOnlyCanTalk(
    state.meeting.currentUser.userRole,
  );
  const coOrHost = coOrHostSelector(state);
  const bCanUnmute = state.meeting.bCanUnmute;
  const isOriginhost = state.meeting.isOriginhost;
  const bMutedUponEntry = bMutedUponEntryOnSelector(state);
  const avStatus = easyStore.easyGet(SESSIONSTORAGE_KEYS.avStatus);
  const canAVEnable = canAVEnableSelector(state);
  if (
    !canAVEnable ||
    (!coOrHost && !bCanUnmute) ||
    (viewOnly && !bAllowOnlyCanTalk) // view only attendee
  ) {
    return false;
  }

  if (_.has(avStatus, 'audio')) {
    return avStatus.audio === 'unmute';
  }
  return !!(isAutoUnmuteAudio() && (isOriginhost || !bMutedUponEntry));
};
