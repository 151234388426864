import { createActions } from 'redux-actions';

export const TOGGLE_IS_HOVER_FOOTER = 'TOGGLE_IS_HOVER_FOOTER';
export const TOGGLE_IS_MOUSE_MOVE = 'TOGGLE_IS_MOUSE_MOVE';
const TOGGLE_IS_ALWAYS_SHOW_FOOTER = 'TOGGLE_IS_ALWAYS_SHOW_FOOTER';
const SET_MORE_BUTTON_DROPDOWN_VISIBLE = 'SET_MORE_BUTTON_DROPDOWN_VISIBLE';
const SET_CLOSED_CAPTION_BUTTON_DROPDOWN_VISIBLE =
  'SET_CLOSED_CAPTION_BUTTON_DROPDOWN_VISIBLE';
const SET_SELECT_ROOM_BUTTON_DROPDOWN_VISIBLE =
  'SET_SELECT_ROOM_BUTTON_DROPDOWN_VISIBLE';
const SET_HAS_SHOW_SELECT_ROOM_TOOLTIP = 'SET_HAS_SHOW_SELECT_ROOM_TOOLTIP';
const FOOTER_RESET = 'FOOTER_RESET';
const TOGGLE_SHOW_LEAVE_MEETING_OPTION_BOX =
  'TOGGLE_SHOW_LEAVE_MEETING_OPTION_BOX';
const TOGGLE_SHOW_ASSIGN_NEW_HOST_BOX = 'TOGGLE_SHOW_ASSIGN_NEW_HOST_BOX';
const TOGGLE_IS_TIPS_ABOVE_FOOTER = 'TOGGLE_IS_TIPS_ABOVE_FOOTER';
const SET_FOOTER_STATE = 'SET_FOOTER_STATE';
const TOGGLE_SHOW_END_SIMULIVE_CONFIRM_BOX =
  'TOGGLE_SHOW_END_SIMULIVE_CONFIRM_BOX';

export const {
  toggleIsHoverFooter,
  toggleIsMouseMove,
  toggleIsAlwaysShowFooter,
  setMoreButtonDropdownVisible,
  setClosedCaptionButtonDropdownVisible,
  setSelectRoomButtonDropdownVisible,
  footerReset,
  toggleShowLeaveMeetingOptionBox,
  toggleShowAssignNewHostBox,
  setHasShowSelectRoomTooltip,
  toggleIsTipsAboveFooter,
  setFooterState,
  toggleShowEndSimuliveConfirmBox,
} = createActions(
  TOGGLE_IS_HOVER_FOOTER,
  TOGGLE_IS_MOUSE_MOVE,
  TOGGLE_IS_ALWAYS_SHOW_FOOTER,
  SET_MORE_BUTTON_DROPDOWN_VISIBLE,
  SET_CLOSED_CAPTION_BUTTON_DROPDOWN_VISIBLE,
  SET_SELECT_ROOM_BUTTON_DROPDOWN_VISIBLE,
  FOOTER_RESET,
  TOGGLE_SHOW_LEAVE_MEETING_OPTION_BOX,
  TOGGLE_SHOW_ASSIGN_NEW_HOST_BOX,
  SET_HAS_SHOW_SELECT_ROOM_TOOLTIP,
  TOGGLE_IS_TIPS_ABOVE_FOOTER,
  SET_FOOTER_STATE,
  TOGGLE_SHOW_END_SIMULIVE_CONFIRM_BOX,
);
