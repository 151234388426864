/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import Modal from '../../../../global/components/widget/modal';
import React, { useEffect } from 'react';
import { getBaseUrl } from '../../../../tasks/global/task-utils/common-utils';
import previewI18n from '../../resource';
import ZoomButton from '../../../../global/components/widget/button/zoom-button';

const GrantPermissionDialog = (props) => {
  const { grantPermissionType, handleGrantPermissionResolve, onClose } = props;

  useEffect(() => {
    const timer = setInterval(() => {
      const permissonButton = document.querySelector(
        '#grant-permission-button',
      );
      if (permissonButton) {
        clearInterval(timer);
        permissonButton.addEventListener(
          'resolve',
          handleGrantPermissionResolve,
        );
      }
    }, 500);

    return () => {
      clearInterval(timer);
      const permissonButton = document.querySelector(
        '#agrant-permission-button',
      );
      permissonButton?.removeEventListener(
        'resolve',
        handleGrantPermissionResolve,
      );
    };
  }, [handleGrantPermissionResolve, grantPermissionType]);

  const renderTitle = () => {
    return (
      <ZoomButton
        className="grant-permission-dialog-close"
        ariaLabel="close"
        onClick={(e) => {
          e.stopPropagation();
          onClose(e);
        }}
      />
    );
  };

  return (
    <Modal
      visible={!!grantPermissionType}
      okText=""
      cancelText=""
      className="grant-permission-dialog-container"
      isModal
      title={renderTitle()}
      customModalStyle={{
        overlay: {
          zIndex: 10001,
        },
      }}
    >
      <div className="grant-permission-dialog">
        <img
          className="zoom-placeholder"
          src={`${getBaseUrl()}/image/zoom-placeholder.png`}
          alt="zoom-placeholder"
        />
        <div className="desc-primary">
          {grantPermissionType === 'audio'
            ? previewI18n.AskMicPermissionConfirm
            : previewI18n.AskPermissionConfirm}
        </div>
        <div className="desc-secondary">{previewI18n.PermissionTip3}</div>
        <permission
          className="grant-permission-button"
          id="grant-permission-button"
          type={grantPermissionType}
          key={`grant-permission-dialog-${grantPermissionType}`}
        />
      </div>
    </Modal>
  );
};

export default GrantPermissionDialog;

import '/home/jenkins/agent/workspace/Web/PWAClient/WebClient/build/web-client/src/features/preview/component/grant-permission-dialog/GrantPermissionDialog.scss';
