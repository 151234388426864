import { changeRestartWebRtcTime } from '../../../../actions/MeetingActions';
import deviceManager from '../../../../device-manager';
import { globalVariable } from '../../../../global/global-variable';
import { CHANGE_VIDEO_CAPTURE_DEVICE } from '../../enum';
import { setIsChangeVideo } from '../video-action';
import { currentSettingsTabSelector } from '../../../settings/redux/settings-selector';

export function changeCamera(deviceId) {
  return (dispatch, getState) => {
    const state = getState();
    const {
      video: { activeCameraDevice },
      meeting: {
        currentUser: { bVideoOn: isCurrentUserStartedVideo },
      },
      settings: {
        settingsDialog: { isShowSettingsDialog },
      },
    } = state;
    const currentSettingsTab = currentSettingsTabSelector(state);
    if (activeCameraDevice !== deviceId) {
      if (isCurrentUserStartedVideo) {
        dispatch(setIsChangeVideo(true));
      }
      if (
        isCurrentUserStartedVideo ||
        (isShowSettingsDialog &&
          ['video', 'background'].includes(currentSettingsTab))
      ) {
        globalVariable.avSocket.sendSocket(CHANGE_VIDEO_CAPTURE_DEVICE, {
          VideoSelectValue: deviceId !== 'default' ? deviceId : null,
        });
      }

      dispatch(changeRestartWebRtcTime(0));
    }
  };
}

export function manuallyChangeCameraThunk(deviceId) {
  return (dispatch) => {
    dispatch(changeCamera(deviceId));
    deviceManager.manuallySelectCamera(deviceId);
  };
}
