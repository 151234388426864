import { globalVariable } from '../../../global/global-variable';
import {
  setIsAlreadyAnnotate,
  setIsOpen,
  setRedoStatus,
  setShareUserAnnotationInfo,
  setUndoStatus,
  updateUsedAnnotationSharingList,
} from './annotation-action';
import * as AVNotifyMediaSDKTypes from '../../../constants/AVNotifyMediaSDKTypes';
import { ANNO_ACTIONS, ANNOTATION_STATUS } from '../enum';
import { shareTypeOfSupportAnnotate } from '../constants';
import {
  isSendShareSelector,
  shareUserListSelector,
} from '../../../global/redux/selector';
import {
  VERTICAL_RIGHT_MIN_WIDTH,
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_STANDARD_VIEW,
} from '../../video/enum';
import {
  getPanelViewState,
  PanelName,
} from '../../../global/redux/set-panel-action';
import { relativeWindowHeightSelector } from '../../video/redux/selectors/video-layout-selector';
import { getVideoHorizontalTopHeight } from '../../video/service/service';

export const toggleCloseAnnoToolBarThunk = () => (dispatch, getState) => {
  const {
    annotation: { isOpen },
  } = getState();

  if (isOpen) {
    dispatch(setIsOpen(false));
    dispatch(setUndoStatus(false));
    dispatch(setRedoStatus(false));
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.ANNOTATION_ACTIONS,
      {
        actionType: ANNO_ACTIONS.CLOSE_TOOL_BAR,
      },
    );
  }

  return;
};

// When the presenter starts sharing, the mediaSDK will initialize the anno instance
// When receiving share, the anno instance is initialize after click anno entry button.
export const toggleStartAnnotationThunk =
  (isOpenAnnotationToolBar) => (dispatch, getState) => {
    const state = getState();
    const {
      annotation: { usedAnnotationSharingList, isAlreadyAnnotate },
      sharing: { shareeCurrentActiveNodeId, sharerSsrc },
      meeting: {
        currentUser: { displayName: currentUserName },
      },
    } = getState();
    const isSendShare = isSendShareSelector(state);
    let currentShareUserSsrc = '';
    if (isSendShare) {
      currentShareUserSsrc = sharerSsrc;
    } else {
      const shareUserList = shareUserListSelector(state);
      const currentShareUser = shareUserList.find(
        (user) => user.userId === shareeCurrentActiveNodeId,
      );
      if (!currentShareUser) {
        dispatch(toggleCloseAnnoToolBarThunk());
        return;
      }

      currentShareUserSsrc = currentShareUser.shareSsrc;
    }

    const presenterId = usedAnnotationSharingList.find(
      (ssrc) => ssrc === currentShareUserSsrc,
    );

    if (isOpenAnnotationToolBar) {
      dispatch(setIsOpen(true));
    }
    // presenter's sharing is annotated for the first time(presenter click annotation entry or attendee do annotation).
    // At this moment, presenter's sharing is not displayed on active area.
    // hide annotation entry, and directly show annotation-tools when sharing content is display on active area
    if (
      typeof isOpenAnnotationToolBar === 'boolean' &&
      isSendShare &&
      !isAlreadyAnnotate
    ) {
      dispatch(setIsAlreadyAnnotate(true));
    }

    if (!presenterId) {
      const newList = [...usedAnnotationSharingList, currentShareUserSsrc];
      dispatch(updateUsedAnnotationSharingList(newList));
    }

    globalVariable.avSocket.sendSocket(AVNotifyMediaSDKTypes.START_ANNOTATION, {
      userName: currentUserName,
      presenterId: currentShareUserSsrc,
      shareSessionId: sharerSsrc,
    });
  };

export const toggleStopAnnotationThunk =
  (updateList) => (dispatch, getState) => {
    const state = getState();
    const {
      annotation: {
        usedAnnotationSharingList,
        isOpen,
        shareUserAnnotationInfo,
      },
      sharing: { shareeCurrentActiveNodeId, sharerSsrc },
    } = state;
    const isSendShare = isSendShareSelector(state);
    const shareUserList = shareUserListSelector(state);
    let currentActiveShareNodeId = shareeCurrentActiveNodeId;
    if (isSendShare) {
      currentActiveShareNodeId = sharerSsrc;
    }
    const currentShareUser = shareUserList.find(
      (user) => user.userId === currentActiveShareNodeId,
    );

    if (!updateList) {
      return;
    }

    // stop share
    const stopOrPausedSharingUserList = updateList.filter(
      (item) =>
        typeof item.bShareOn === 'boolean' &&
        (item.bShareOn === false || typeof item.bSharePause === 'boolean'),
    );
    if (!stopOrPausedSharingUserList.length) {
      return;
    }
    const filterList = [];

    const newShareAnnotationInfos = { ...shareUserAnnotationInfo };
    Object.keys(shareUserAnnotationInfo).forEach((userId) => {
      const sharingUser = stopOrPausedSharingUserList.find(
        (updateUser) => updateUser.id === Number(userId),
      );

      if (sharingUser) {
        //  current viewing sharing is stoped, need to close
        if (
          currentShareUser &&
          sharingUser.shareSsrc === currentShareUser.shareSsrc &&
          isOpen
        ) {
          dispatch(toggleCloseAnnoToolBarThunk());
        }

        if (!sharingUser.bShareOn) {
          if (newShareAnnotationInfos[sharingUser.id]) {
            delete newShareAnnotationInfos[sharingUser.id];
          }
          // stop & clear annotation
          globalVariable.avSocket.sendSocket(
            AVNotifyMediaSDKTypes.STOP_ANNOTATION,
            {
              presenterId: sharingUser.shareSsrc,
            },
          );
          filterList.push(sharingUser.shareSsrc);
          return;
        }

        // pause share
        const updateAnnotationInfo = {
          ...(newShareAnnotationInfos[sharingUser.id] || {
            userId: sharingUser.id,
          }),
          hideAnnoteBtn: sharingUser.bSharePause,
        };
        newShareAnnotationInfos[sharingUser.id] = updateAnnotationInfo;
      }
    });

    if (usedAnnotationSharingList.length > 0) {
      dispatch(
        updateUsedAnnotationSharingList(
          usedAnnotationSharingList.filter(
            (ssrc) => !filterList.includes(ssrc),
          ),
        ),
      );
    }

    dispatch(setShareUserAnnotationInfo(newShareAnnotationInfos));
    return;
  };

export const handleChangeRedoOrUndoStatusThunk = (type, data) => (dispatch) => {
  const { status } = data;

  if (type === ANNO_ACTIONS.REDO) {
    dispatch(setRedoStatus(status));
  } else if (type === ANNO_ACTIONS.UNDO) {
    dispatch(setUndoStatus(status));
  }
};

export const toggleAnnotateAction =
  (type, params = {}) =>
  () => {
    globalVariable.avSocket.sendSocket(
      AVNotifyMediaSDKTypes.ANNOTATION_ACTIONS,
      {
        actionType: type,
        ...params,
      },
    );
  };

export const updateShareUserAnnoInfoThunk = (data) => (dispatch, getState) => {
  const {
    annotation: { shareUserAnnotationInfo, isOpen },
    sharing: { shareeCurrentActiveNodeId },
  } = getState();

  const { shareUserId, ...info } = data;

  let newShareAnnotationInfo = {};
  if (shareUserAnnotationInfo[shareUserId]) {
    newShareAnnotationInfo = {
      ...shareUserAnnotationInfo[shareUserId],
      ...info,
    };
  } else {
    newShareAnnotationInfo = {
      ...info,
      userId: shareUserId,
    };
  }
  shareUserAnnotationInfo[shareUserId] = newShareAnnotationInfo;
  // presenter disable annotate or switch shareType
  if (
    shareeCurrentActiveNodeId &&
    (info.annotationOff === ANNOTATION_STATUS.OFF ||
      !shareTypeOfSupportAnnotate.includes(info.shareType)) &&
    shareUserId === shareeCurrentActiveNodeId &&
    isOpen
  ) {
    dispatch(toggleCloseAnnoToolBarThunk());
  }
  // update share user annotate status
  dispatch(setShareUserAnnotationInfo(shareUserAnnotationInfo));
};

export const notifyMediaCurrentAnnotateUserName =
  (userSsrc) => (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();

    const currentAnnotateUser = attendeesList?.find(
      (item) => item.userId >> 10 === userSsrc >> 10,
    );
    if (currentAnnotateUser) {
      dispatch(
        toggleAnnotateAction(ANNO_ACTIONS.ANNOTATOR_ID_TO_NAME, {
          userId: userSsrc,
          userName: currentAnnotateUser.displayName,
        }),
      );
    }
  };

export const notifyMediaSdkInitCanvasSizeWhenSendShare =
  (shareeOriginalDimension) => (dispatch, getState) => {
    const state = getState();
    const {
      meetingUI: { rightContainerWidth, fullScreen: isFullScreen, sizeScale },
      video: {
        UI: { videoLayout },
        isChangeSideBySideSpeakerLayoutByUser,
      },
      annotation: { isAlreadyAnnotate },
    } = state;

    const isSendShare = isSendShareSelector(state);
    if (!isSendShare || isAlreadyAnnotate) {
      return;
    }

    let nextVideoLayout = '';
    // get layout when samil canvas to active canvas
    if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW) {
      if (isChangeSideBySideSpeakerLayoutByUser) {
        nextVideoLayout = VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW;
      } else {
        nextVideoLayout = VIDEO_LAYOUT_STANDARD_VIEW;
      }
    } else if (videoLayout === VIDEO_LAYOUT_GALLERY_VIEW) {
      nextVideoLayout = VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW;
    } else if (videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW) {
      nextVideoLayout = VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW;
    }

    const showRightContainer = getPanelViewState(
      state,
      PanelName.rightPanelContainer,
    );
    const containerWidth = showRightContainer
      ? window.innerWidth - rightContainerWidth
      : window.innerWidth;

    let nextSizeScale = { ...sizeScale };
    const relativeWindowHeight = relativeWindowHeightSelector(state);
    if (nextVideoLayout === VIDEO_LAYOUT_STANDARD_VIEW) {
      if (isFullScreen) {
        nextSizeScale = {
          widthScale: 1,
          heightScale: 0,
        };
      } else {
        nextSizeScale = {
          widthScale: 1,
          heightScale: getVideoHorizontalTopHeight() / relativeWindowHeight,
        };
      }
    } else if (
      nextVideoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW ||
      nextVideoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_GALLERY_VIEW ||
      nextVideoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW
    ) {
      const pane1 = document.querySelector('.Pane1');
      const pane1widthWithPx = pane1 && pane1.style && pane1.style.width;
      const pane1width = pane1widthWithPx && pane1widthWithPx.replace('px', '');

      const pane2width = pane1width
        ? containerWidth - pane1width
        : VERTICAL_RIGHT_MIN_WIDTH;

      if (pane2width >= 0) {
        nextSizeScale = {
          widthScale: pane2width / containerWidth,
          heightScale: 1,
        };
      }
    }

    // calculate canvas initialize size when start share
    // compute scale value
    let wWidth = containerWidth;
    const wHeight = relativeWindowHeight;
    const { width: sWidth, height: sHeight } = shareeOriginalDimension;

    if (nextSizeScale.heightScale === 1) {
      wWidth -= wWidth * nextSizeScale.widthScale;
    }

    let wHeightDelta = 0;
    if (nextSizeScale.widthScale === 1) {
      wHeightDelta = wHeight * nextSizeScale.heightScale;
    }

    const scaleRatio = Math.min(
      wWidth / sWidth,
      (wHeight - wHeightDelta) / sHeight,
    );

    const canvasWidth = sWidth * scaleRatio;
    const canvasHeight = sHeight * scaleRatio;

    // notify mediaSDK
    dispatch(
      toggleAnnotateAction(ANNO_ACTIONS.ANNO_CANVAS_SIZE_UPDATE, {
        width: canvasWidth,
        height: canvasHeight,
      }),
    );
  };

export const saveAnnotationToolBarStateThunk =
  (params = {}) =>
  (dispatch, getState) => {
    const { shareNodeId, ...toolbarStates } = params;
    const {
      annotation: { shareUserAnnotationInfo },
    } = getState();

    if (shareUserAnnotationInfo[shareNodeId]) {
      const newShareAnnotationInfos = { ...shareUserAnnotationInfo };
      const updateAnnotationInfo = {
        ...newShareAnnotationInfos[shareNodeId],
        initState: toolbarStates,
      };
      newShareAnnotationInfos[shareNodeId] = updateAnnotationInfo;
      dispatch(setShareUserAnnotationInfo(newShareAnnotationInfos));
    }
  };
