import { ANNO_TOOL_CONTAINER_LAYOUT, SHARE_TYPE } from './enum';

export enum CURSOR_TYPE {
  MOUSE = 'default',
  PEN = 'pen-cursor',
  ERASER = 'eraser-cursor',
  PEN_HIGHLIGHTER = 'pen-highlight-cursor',
  SHAPES = 'shapes-cursor',
  SPOTLIGHT = 'spotlight-cursor',
  SPOTLIGHT_ARROW = 'spotlight-arrow-cursor',
  TEXT = 'text-box',
}

export const PEN_COLORS = [
  [0xffffffff, 'White'],
  [0xffff1919, 'Red'],
  [0xffffde32, 'Yellow'],
  [0xff82c786, 'Green'],
  [0xff2e8cff, 'Blue'],
  [0xffb479ff, 'Light purple'],
  [0xffff38c7, 'Pink'],
  [0xffff8a00, 'Orange'],
  [0xff49d61e, 'Lime-tree green'],
  [0xff51d8eb, 'Ice blue'],
  [0xff000000, 'Dark grey'],
  [0xff7f0000, 'Dark red'],
  [0xff774408, 'Brown'],
  [0xff0b7228, 'Dark green'],
  [0xff144fc3, 'Dark blue'],
];

export const shareTypeOfSupportAnnotate = [
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_SCREEN,
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_WINDOW,
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_FRAME,
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_PICKER,
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_DATA,
  SHARE_TYPE.SSB_MC_AS_SHARED_OBJ_WHITEBOARD,
];

export const annoToolIconStyle = {
  width: 20,
  height: 20,
};

export const annoShapeIconStyle = {
  width: 16,
  height: 16,
};

export const DROPDOWN_DIRECT = {
  [ANNO_TOOL_CONTAINER_LAYOUT.HORIZONTAL]: 'down-centered',
  [ANNO_TOOL_CONTAINER_LAYOUT.VERTICAL]: 'end',
};
