import { createSelector } from 'reselect';
import {
  inMeetingParticipantsSizeSelector,
  isExistShareAreaSelector,
  isReceiveSharingSelector,
} from '../../../../global/redux/selector';
import {
  isGoogleMeetMode,
  isMSFTMode,
  isSupportMultiView,
  isTeslaMode,
} from '../../../../global/util';
import {
  ACTIVE_VIDEO,
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW,
  VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
  VIDEO_LAYOUT_STANDARD_VIEW,
} from '../../enum';
import {
  currentUserRoleSelector,
  multiSpeakerActiveVideoSelector,
  multiSpeakerConfigSelector,
  multiSpeakerMainVideoBaseSelector,
  multiSpeakerMainVideoSelector,
  speakerViewBarListSelector,
  teslaAllViewParticipantsSelector,
  teslaBarVideoListSelector,
  videoBasicAllAttendeeListSizeSelector,
  videoWithCommonFilterListSelector,
} from './video-list-selector';
import {
  spotlightVideoListSelector,
  videoLayoutSelector,
} from './video-common-selector';
import { isWebinar } from '../../../../global/service/meeting-types';
import { isViewOnly } from '../../../../global/service/user-types';
import { isFullScreenSelector } from '../../../../global/selectors/global-video-selector';
import { isAlwaysShowFooterSelector } from '../../../footer/redux/footer-selector';
import { whiteboardStatusSelector } from '../../../whiteboard/redux/whiteboard-selector';
import { WHITEBOARD_STATUS } from '../../../whiteboard/enum';
import { FOOTER_HEIGHT, HEADER_HEIGHT } from '../../../../constants/Constants';

const isMinimizeModeSelector = ({ meetingUI: { isMinimizeMode } }) =>
  isMinimizeMode;
const isPIPModeSelector = ({ meetingUI: { isPIPMode } }) => isPIPMode;

const pinVideoListSelector = ({ video: { pinVideoList } }) => pinVideoList;

const isSwapVideoSharedSelector = ({
  video: {
    UI: { isSwapVideoShared },
  },
}) => isSwapVideoShared;

const windowHeightSelector = (state) => state.meetingUI.windowSize?.height;

export const hasChangedVideoOrderSelector = ({
  video: { hasChangedVideoOrder },
}) => hasChangedVideoOrder;

const currentUserSelector = ({ meeting: { currentUser } }) => currentUser;

const selfVideoShowTypeSelector = ({
  video: {
    UI: { selfVideoShowType },
  },
}) => selfVideoShowType;

const initVideoDecodeStatusSelector = ({
  socketStatus: { initVideoDecodeStatus },
}) => initVideoDecodeStatus;

const isWebinarOnlyShowContentSelector = ({
  video: { isWebinarOnlyShowContent },
}) => isWebinarOnlyShowContent;
//------------------------------------------------------------------------------
export const isShowSuspensionViewSelector = createSelector(
  [
    isFullScreenSelector,
    videoLayoutSelector,
    initVideoDecodeStatusSelector,
    videoWithCommonFilterListSelector,
    currentUserSelector,
    inMeetingParticipantsSizeSelector,
    spotlightVideoListSelector,
    isExistShareAreaSelector,
  ],
  (
    isFullScreen,
    videoLayout,
    initVideoDecodeStatus,
    videoWithCommonFilterList,
    currentUser,
    inMeetingParticipantsSize,
    spotlightVideoList,
    isExistShareArea,
  ) => {
    if (
      isSupportMultiView() &&
      isFullScreen &&
      videoWithCommonFilterList.length > 0 &&
      ((videoLayout === VIDEO_LAYOUT_SPEAK_VIEW &&
        !(isViewOnly(currentUser.userRole) && isWebinar())) ||
        videoLayout === VIDEO_LAYOUT_STANDARD_VIEW) &&
      initVideoDecodeStatus === 'success'
    ) {
      return true;
    }

    if (
      !isSupportMultiView() &&
      inMeetingParticipantsSize > 1 &&
      (isExistShareArea ||
        (currentUser.bVideoOn &&
          !spotlightVideoList.includes(currentUser.userId)))
    ) {
      return true;
    }

    return false;
  },
);

export const isSuspensionSharedSelector = createSelector(
  [
    videoLayoutSelector,
    isSwapVideoSharedSelector,
    isShowSuspensionViewSelector,
    isReceiveSharingSelector,
  ],
  (videoLayout, isSwapVideoShared, isShowSuspensionView, isReceiveSharing) => {
    if (!isShowSuspensionView) {
      return false;
    }

    if (!isReceiveSharing) {
      return false;
    }

    if (videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW) {
      return false;
    }

    if (!isSwapVideoShared) {
      return false;
    }

    return true;
  },
);

export const isShowSuspensionSharedSelector = createSelector(
  [isSuspensionSharedSelector, selfVideoShowTypeSelector],
  (isSuspensionShared, selfVideoShowType) => {
    if (!isSuspensionShared) {
      return false;
    }

    if (selfVideoShowType !== ACTIVE_VIDEO) {
      return false;
    }

    return true;
  },
);

export const isShowSpeakViewSelector = createSelector(
  [
    videoLayoutSelector,
    initVideoDecodeStatusSelector,
    speakerViewBarListSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    inMeetingParticipantsSizeSelector,
  ],
  (
    videoLayout,
    initVideoDecodeStatus,
    speakerViewBarList,
    pinVideoList,
    spotlightVideoList,
    inMeetingParticipantsSize,
  ) => {
    if (initVideoDecodeStatus !== 'success') {
      return false;
    }

    if (!isSupportMultiView()) {
      return false;
    }

    if (
      videoLayout !== VIDEO_LAYOUT_SPEAK_VIEW &&
      videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW &&
      videoLayout !== VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW
    ) {
      return false;
    }

    if (
      speakerViewBarList.length > 0 ||
      pinVideoList.length === inMeetingParticipantsSize ||
      spotlightVideoList.length === inMeetingParticipantsSize
    ) {
      return true;
    }

    if (inMeetingParticipantsSize > 0) {
      return true;
    }

    return false;
  },
);

export const isShowSpeakerBarSelector = createSelector(
  [
    isShowSpeakViewSelector,
    currentUserSelector,
    isReceiveSharingSelector,
    pinVideoListSelector,
    spotlightVideoListSelector,
    videoBasicAllAttendeeListSizeSelector,
    isShowSuspensionViewSelector,
    videoLayoutSelector,
    isSwapVideoSharedSelector,
    speakerViewBarListSelector,
    isExistShareAreaSelector,
    isWebinarOnlyShowContentSelector,
  ],
  (
    isShowSpeakView,
    currentUser,
    isReceiveSharing,
    pinVideoList,
    spotlightVideoList,
    videoBasicAllAttendeeListSize,
    isShowSuspensionView,
    videoLayout,
    isSwapVideoShared,
    speakerViewBarList,
    isExistShareArea,
    isWebinarOnlyShowContent,
  ) => {
    if (!isShowSpeakView) {
      return false;
    }

    if (isShowSuspensionView) {
      return false;
    }

    if (isViewOnly(currentUser.userRole) && isWebinar() && !isReceiveSharing) {
      return false;
    }

    if (isWebinarOnlyShowContent) {
      return false;
    }

    if (
      pinVideoList.length === videoBasicAllAttendeeListSize &&
      videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW
    ) {
      return false;
    }

    if (
      spotlightVideoList.length === videoBasicAllAttendeeListSize &&
      videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW
    ) {
      return false;
    }

    if (videoBasicAllAttendeeListSize === 1 && !isExistShareArea) {
      return false;
    }

    if (videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_SPEAKER_VIEW) {
      return false;
    }

    if (
      isViewOnly(currentUser.userRole) &&
      videoLayout === VIDEO_LAYOUT_STANDARD_VIEW
    ) {
      return false;
    }

    if (
      !isViewOnly(currentUser.userRole) &&
      videoLayout === VIDEO_LAYOUT_STANDARD_VIEW &&
      isSwapVideoShared
    ) {
      return false;
    }

    if (!speakerViewBarList.length) {
      return false;
    }

    return true;
  },
);

export const isShowSpeakerActiveSelector = createSelector(
  [
    isShowSpeakViewSelector,
    videoLayoutSelector,
    isSwapVideoSharedSelector,
    currentUserSelector,
  ],
  (isShowSpeakView, videoLayout, isSwapVideoShared, currentUser) => {
    if (!isShowSpeakView) {
      return false;
    }

    if (
      !isViewOnly(currentUser.userRole) &&
      videoLayout === VIDEO_LAYOUT_STANDARD_VIEW &&
      !isSwapVideoShared
    ) {
      return false;
    }

    return true;
  },
);

export const isStandardAttendeeSelector = createSelector(
  [videoLayoutSelector, currentUserSelector],
  (videoLayout, currentUser) => {
    if (videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW) {
      return false;
    }

    if (isViewOnly(currentUser.userRole) && isWebinar()) {
      return true;
    }

    return false;
  },
);

// for tesla
export const isShowTeslaBarSelector = createSelector(
  [
    teslaBarVideoListSelector,
    isReceiveSharingSelector,
    currentUserRoleSelector,
  ],
  (teslaBarVideoList, isReceiveSharing, currentUserRole) => {
    if (isReceiveSharing) {
      return true;
    }
    if (teslaBarVideoList.length > 2) {
      return true;
    }
    if (
      isViewOnly(currentUserRole) &&
      isWebinar() &&
      teslaBarVideoList.length > 0
    ) {
      return true;
    }
    return false;
  },
);

// for tesla
export const isShowTeslaSuspensionSelector = createSelector(
  [
    teslaAllViewParticipantsSelector,
    isReceiveSharingSelector,
    currentUserRoleSelector,
  ],
  (teslaAllViewParticipants, isReceiveSharing, currentUserRole) => {
    if (isViewOnly(currentUserRole) && isWebinar()) {
      return false;
    }
    if (isReceiveSharing) {
      return false;
    }
    if (teslaAllViewParticipants.length === 2) {
      return true;
    }
    return false;
  },
);

export const isShowBrandingSelector = ({ video: { isBranding } }) => {
  return isBranding;
};

export const relativeWindowHeightSelector = createSelector(
  [
    windowHeightSelector,
    isAlwaysShowFooterSelector,
    videoLayoutSelector,
    whiteboardStatusSelector,
    isShowSpeakerBarSelector,
    isFullScreenSelector,
  ],
  (
    height,
    isAlwaysShowFooter,
    videoLayout,
    whiteboardStatus,
    isShowSpeakerBar,
  ) => {
    // Google or Tesla mode -> only show header
    if (isGoogleMeetMode() || isTeslaMode()) {
      return height - HEADER_HEIGHT;
    }

    // MTR case -> show header and footer
    if (isMSFTMode()) {
      return height - HEADER_HEIGHT - FOOTER_HEIGHT;
    }

    // views with space for header and footer
    if (
      isAlwaysShowFooter ||
      videoLayout === VIDEO_LAYOUT_GALLERY_VIEW ||
      videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW ||
      whiteboardStatus !== WHITEBOARD_STATUS.OFF
    ) {
      return height - HEADER_HEIGHT - FOOTER_HEIGHT;
    }
    // views with just space for header
    else if (isShowSpeakerBar) {
      return height - HEADER_HEIGHT;
    }
    // full window height (only share screen and header/footer hidable)
    return height;
  },
);

export const mainLayoutOffsetTopSelector = createSelector(
  [
    isAlwaysShowFooterSelector,
    isReceiveSharingSelector,
    videoLayoutSelector,
    isFullScreenSelector,
    isShowSpeakerBarSelector,
    isMinimizeModeSelector,
    isPIPModeSelector,
    whiteboardStatusSelector,
  ],
  (
    isAlwaysShowFooter,
    isReceiveSharing,
    videoLayout,
    isFullScreen,
    isShowSpeakerBar,
    isMinimizeMode,
    isPIPMode,
    whiteboardStatus,
  ) => {
    // these modes don't have header
    if (isMinimizeMode || isPIPMode) {
      return 0;
    }

    // gallery view is always offset from top to accomodate the header
    if (
      videoLayout === VIDEO_LAYOUT_GALLERY_VIEW ||
      videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW
    ) {
      return HEADER_HEIGHT;
    }

    // if the header is always visible, always offset by header height
    if (isAlwaysShowFooter) {
      return HEADER_HEIGHT;
    }

    if (isReceiveSharing && videoLayout !== VIDEO_LAYOUT_STANDARD_VIEW) {
      return 0;
    }

    if (isFullScreen && whiteboardStatus === WHITEBOARD_STATUS.OFF) {
      return 0;
    }

    // single user view case when view is set to speaker view
    if (videoLayout === VIDEO_LAYOUT_SPEAK_VIEW && !isShowSpeakerBar) {
      return 0;
    }

    // offset for all other cases
    return HEADER_HEIGHT;
  },
);

export const isShowMultiSpeakerViewSelector = createSelector(
  [videoLayoutSelector],
  (videoLayout) => {
    if (
      videoLayout === VIDEO_LAYOUT_MULTI_SPEAKER_VIEW ||
      videoLayout === VIDEO_LAYOUT_SIDE_BY_SIDE_MULTI_SPEAKER_VIEW
    ) {
      return true;
    }

    return false;
  },
);

export const isShowMultiSpeakerActiveSelector = createSelector(
  [
    isShowMultiSpeakerViewSelector,
    multiSpeakerMainVideoBaseSelector,
    multiSpeakerActiveVideoSelector,
    multiSpeakerConfigSelector,
  ],
  (
    isShowMultiSpeakerView,
    multiSpeakerMainVideoBase,
    multiSpeakerActiveVideo,
    multiSpeakerConfig,
  ) => {
    if (!isShowMultiSpeakerView) return false;
    if (
      multiSpeakerActiveVideo.length > 0 &&
      multiSpeakerActiveVideo.length + multiSpeakerMainVideoBase.length >
        multiSpeakerConfig[0][2]
    ) {
      return true;
    }
    return false;
  },
);

export const isShowMultiSpeakerMainSelector = createSelector(
  [
    isShowMultiSpeakerViewSelector,
    isShowMultiSpeakerActiveSelector,
    multiSpeakerMainVideoSelector,
  ],
  (isShowMultiSpeakerView, isShowMultiSpeakerActive, multiSpeakerMainVideo) => {
    if (!isShowMultiSpeakerView) return false;
    return isShowMultiSpeakerActive ? multiSpeakerMainVideo.length > 0 : true;
  },
);
