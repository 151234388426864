import { createSelector } from 'reselect';
import { isExternalControlledMode } from '../../../global/util';
import meetingConfig from '../../../global/meetingConfig';
import { isHideSharingButtonSelector } from '../../sharing/redux/sharing-selector';
import { isBrowserSupportSharer } from '../../sharing/util';
import { isViewOnly } from '../../../global/service/user-types';
import { currentUserRoleSelector } from '../../../global/selectors/meeting-selector';

const meetingUISelector = ({
  meetingUI: { showInviteDialog, showJoinDialog, showAudioMenu },
}) => ({
  showInviteDialog,
  showJoinDialog,
  showAudioMenu,
});
const meetingSelector = ({
  meeting: {
    closedCaption: {
      showClosedCaptionAvailableTip,
      showClosedCaptionAssignTip,
    },
    loading,
  },
  newLiveTranscription: { newLTSubTipsType },
}) => ({
  showClosedCaptionAvailableTip,
  showClosedCaptionAssignTip,
  newLTSubTipsType,
  loading,
});
const sharingSelector = ({
  sharing: {
    UI: { sharerShowPermissionSetting },
  },
}) => ({
  isSharingPermissionMenuVisible: sharerShowPermissionSetting,
});
const settingsSelector = ({
  settings: {
    settingsDialog: { isShowSettingsDialog },
  },
}) => isShowSettingsDialog;

const interpretationSelector = ({
  interpretation: {
    UI: { isInterpretationToolTip },
  },
}) => isInterpretationToolTip;
const footerSelector = ({
  footer: {
    visibility: {
      isHoverFooter,
      isMouseMove,
      isAlwaysShowFooter,
      showLeaveMeetingOptionBox,
      showAssignNewHostBox,
      showEndSimuliveConfirmBox,
      isTipsAboveFooter,
    },
    moreButton: { isDropDownOpen },
  },
}) => ({
  isHoverFooter,
  isMouseMove,
  isAlwaysShowFooter,
  isDropDownOpen,
  showLeaveMeetingOptionBox,
  showAssignNewHostBox,
  showEndSimuliveConfirmBox,
  isTipsAboveFooter,
});

const breakoutRoomSelector = ({
  breakoutRoom: {
    UI: {
      showWhereUnassigendAttendeesPopoverTip,
      showWhereJoinRoomPopoverTip,
      showCreateBoRoomsWindow,
      showManageBoRoomsWindow,
      showNeedHelpDialog,
      showInviteJoinDialog,
      showRequestJoinDialog,
      showTimeUpNotifyDialog,
      showAskForHelpDialog,
      showLeaveRoomDialog,
      showRoomWillCloseDialog,
    },
  },
}) => ({
  showWhereUnassigendAttendeesPopoverTip,
  showWhereJoinRoomPopoverTip,
  showCreateBoRoomsWindow,
  showManageBoRoomsWindow,
  showNeedHelpDialog,
  showInviteJoinDialog,
  showRequestJoinDialog,
  showTimeUpNotifyDialog,
  showAskForHelpDialog,
  showLeaveRoomDialog,
  showRoomWillCloseDialog,
});
const meetingInfoSelector = ({
  meetingInfo: {
    UI: { isMeetingsInfoIconFocused },
  },
}) => ({
  isMeetingsInfoIconFocused,
});
const audioSelector = ({
  audio: {
    UI: { isAudioMenuVisible, isJoinAudioDialogVisible },
  },
}) => ({
  isAudioMenuVisible,
  isJoinAudioDialogVisible,
});
const videoSelector = ({
  video: {
    UI: { isVideoMenuVisible },
  },
}) => isVideoMenuVisible;
const securitySelector = ({
  security: {
    UI: { isSecurityDropdownVisible },
  },
}) => isSecurityDropdownVisible;
const dialogSelector = ({
  dialog: {
    disallowTip: { visible: disallowTipVisible },
  },
}) => disallowTipVisible;
const viewBtnFocusedSelector = ({ meetingUI: { isViewButtonFocused } }) =>
  isViewButtonFocused;

const isReactionDropdownOpenSelector = ({
  reaction: {
    reactionPickerVisible,
    webinarReactionDisplayModeDropdownVisible,
  },
}) => reactionPickerVisible || webinarReactionDisplayModeDropdownVisible;

const chartSettingDropdownSelector = (state) => {
  return state.chat.showChatSettingDropdown;
};

const recordSettingDropdownSelector = (state) => {
  return state.recording.showRecordSettingDropdown;
};

export const isAlwaysShowFooterSelector = (state) => {
  return (
    state.footer.visibility.isAlwaysShowFooter || isExternalControlledMode()
  );
};

export const isFooterVisibleSelector = createSelector(
  [
    meetingUISelector,
    meetingSelector,
    footerSelector,
    breakoutRoomSelector,
    meetingInfoSelector,
    audioSelector,
    sharingSelector,
    videoSelector,
    securitySelector,
    dialogSelector,
    interpretationSelector,
    settingsSelector,
    viewBtnFocusedSelector,
    isReactionDropdownOpenSelector,
    chartSettingDropdownSelector,
    recordSettingDropdownSelector,
  ],
  (
    { showJoinDialog, showInviteDialog, showAudioMenu }, // meeting UI
    { showClosedCaptionAssignTip, newLTSubTipsType, loading }, // meeting
    {
      isHoverFooter,
      isMouseMove,
      isAlwaysShowFooter,
      isDropDownOpen,
      showLeaveMeetingOptionBox,
      showAssignNewHostBox,
      showEndSimuliveConfirmBox,
      isTipsAboveFooter,
    }, // footer
    {
      showWhereUnassigendAttendeesPopoverTip,
      showWhereJoinRoomPopoverTip,
      showCreateBoRoomsWindow,
      showManageBoRoomsWindow,
      showNeedHelpDialog,
      showInviteJoinDialog,
      showRequestJoinDialog,
      showTimeUpNotifyDialog,
      showAskForHelpDialog,
      showLeaveRoomDialog,
      showRoomWillCloseDialog,
    }, // breakout room
    { isMeetingsInfoIconFocused }, // meetting info
    { isAudioMenuVisible, isJoinAudioDialogVisible }, // audio
    { isSharingPermissionMenuVisible }, // sharing
    isVideoMenuVisible, // video
    isSecurityDropdownVisible, // security
    disallowTipVisible, // dialog
    isInterpretationToolTip,
    isShowSettingsDialog, // settings
    isViewButtonFocused,
    isReactionDropdownOpen,
    showChatSettingDropdown,
    showRecordSettingDropdown,
  ) =>
    showJoinDialog ||
    showInviteDialog ||
    showAudioMenu ||
    isVideoMenuVisible ||
    showClosedCaptionAssignTip ||
    isHoverFooter ||
    isMouseMove ||
    isAlwaysShowFooter ||
    isTipsAboveFooter ||
    isDropDownOpen ||
    showWhereJoinRoomPopoverTip ||
    showWhereUnassigendAttendeesPopoverTip ||
    isSharingPermissionMenuVisible ||
    isShowSettingsDialog ||
    isSecurityDropdownVisible ||
    newLTSubTipsType ||
    loading ||
    disallowTipVisible ||
    isAudioMenuVisible ||
    isJoinAudioDialogVisible ||
    isExternalControlledMode() ||
    showLeaveMeetingOptionBox ||
    showAssignNewHostBox ||
    showEndSimuliveConfirmBox ||
    showCreateBoRoomsWindow ||
    showManageBoRoomsWindow ||
    showNeedHelpDialog ||
    showInviteJoinDialog ||
    showRequestJoinDialog ||
    showTimeUpNotifyDialog ||
    showAskForHelpDialog ||
    showLeaveRoomDialog ||
    showRoomWillCloseDialog ||
    isInterpretationToolTip ||
    isMeetingsInfoIconFocused ||
    isViewButtonFocused ||
    isReactionDropdownOpen ||
    showChatSettingDropdown ||
    showRecordSettingDropdown,
);

export const sharingButtonVisibleSelector = createSelector(
  [isHideSharingButtonSelector, currentUserRoleSelector],
  (isHideShareButton, currentUserRole) => {
    const simulive = meetingConfig.isSimuliveWebinar;
    const isSharingButtonShow =
      meetingConfig.meetingOptions.isScreenShareEnabled &&
      !isViewOnly(currentUserRole) &&
      !simulive &&
      isBrowserSupportSharer() &&
      !isExternalControlledMode() &&
      !isHideShareButton;

    return isSharingButtonShow;
  },
);
