import { iText } from '../../global/util';

const POPOVER_REQUEST_EXTENSION_INSTALLATION_TITLE = iText(
  'Install Chrome extension',
  'apac_wc_sharer_request_extension_installation_title',
);

const POPOVER_REQUEST_EXTENSION_INSTALLATION_DESC = iText(
  'Please click Share again after installation. ',
  'apac_wc_sharer_request_extension_installation_desc',
);
const POPOVER_REQUEST_EXTENSION_INSTALLATION_BUTTON1_LABEL = iText(
  'Install',
  'apac_wc_sharer_request_extension_installation_button1_label',
);

const POPOVER_DEVICE_NOT_SUPPORT_TITLE = iText(
  'Your browser does not support feature - Screen Share',
  'apac_wc_device_not_support_title',
);

const POPOVER_DEVICE_NOT_SUPPORT_DESC = iText(
  'If you want to use this feature, please use Chrome, Firefox or Chromium Edge to join this meeting again.',
  'apac_wc_device_not_support_desc_2',
);

const POPOVER_SHARER_IN_PROGRESS_DESC = iText(
  'Preview',
  'apac_wc_sharer_in_progress_desc',
);

const POPOVER_SHARER_DEVICE_DISABLED_BY_DEVICE_DEC = iText(
  'Your browser is preventing access to your Share Screen.',
  'apac_wc_sharer_device_disabled_by_device_dec',
);

export {
  POPOVER_REQUEST_EXTENSION_INSTALLATION_TITLE,
  POPOVER_REQUEST_EXTENSION_INSTALLATION_DESC,
  POPOVER_REQUEST_EXTENSION_INSTALLATION_BUTTON1_LABEL,
  POPOVER_DEVICE_NOT_SUPPORT_TITLE,
  POPOVER_DEVICE_NOT_SUPPORT_DESC,
  POPOVER_SHARER_IN_PROGRESS_DESC,
  POPOVER_SHARER_DEVICE_DISABLED_BY_DEVICE_DEC,
};

const POP_UP_BOX_DISABLED_SHARE_BUTTON_LABEL = iText(
  'Ok',
  'apac_wc_sharer_disabled_share_button_label',
);
const POP_UP_BOX_DISABLED_SHARE_TITLE = iText(
  'Disabled Share',
  'apac_wc_sharer_disabled_share_title',
);
export {
  POP_UP_BOX_DISABLED_SHARE_BUTTON_LABEL,
  POP_UP_BOX_DISABLED_SHARE_TITLE,
};

const POP_UP_BOX_DISABLED_SHARE1_DESC = iText(
  'Host disabled attendee screen sharing.',
  'apac_wc_sharer_disabled_share1_desc',
);
export { POP_UP_BOX_DISABLED_SHARE1_DESC };

const POP_UP_BOX_DISABLED_SHARE2_DESC = iText(
  'You cannot start screen share while the other participant is sharing.',
  'apac_wc_sharer_disabled_share2_desc',
);
export { POP_UP_BOX_DISABLED_SHARE2_DESC };

export const POP_UP_BOX_DISABLED_SHARE3_DESC = iText(
  'Only the host can share in this meeting.',
  'apac_wc_sharer_disabled_share3_desc',
);

const POP_UP_BOX_START_SHARE_BUTTON1_LABEL = iText(
  'Cancel',
  'apac_wc_sharer_start_share_button1_label',
);
const POP_UP_BOX_START_SHARE_BUTTON2_LABEL = iText(
  'Continue',
  'apac_wc_sharer_start_share_button2_label',
);
const POP_UP_BOX_START_SHARE_TITLE = iText(
  'Start Share',
  'apac_wc_sharer_start_share_title',
);
const POP_UP_BOX_START_SHARE_DESC = iText(
  'This will stop other’s screen sharing. Do you want to continue?',
  'apac_wc_sharer_start_share_desc',
);

const ARIA_SHARER_POP_UP_BOX_CLOSE_LABEL = iText(
  'Close',
  'apac.wc_aria_txt_dialog_close_btn',
);

export {
  POP_UP_BOX_START_SHARE_BUTTON1_LABEL,
  POP_UP_BOX_START_SHARE_BUTTON2_LABEL,
  POP_UP_BOX_START_SHARE_TITLE,
  POP_UP_BOX_START_SHARE_DESC,
  ARIA_SHARER_POP_UP_BOX_CLOSE_LABEL,
};

const SHARER_ENTRY_BUTTON_LABEL1 = iText(
  'Share Screen',
  'apac_wc_sharer_entry_button_label1',
);
const SHARER_ENTRY_BUTTON_LABEL2 = iText(
  'New Share',
  'apac_wc_sharer_entry_button_label2',
);

const SHARER_ENTRY_BUTTON_OPTION_LABEL = iText(
  'Share Screen Options',
  'apac_wc_sharer_entry_button_options_label',
);

const ARIA_SHARER_SETTING_MENU_ENTRY_BUTTON_TEXT = iText(
  'Share permission setting',
  'apac_wc_aria_sharer_setting_menu_entry_button_text',
);
const SHARER_SETTING_MENU_CATEGORY1_TITLE = iText(
  'Who can share?',
  'apac_wc_sharer_setting_menu_category1_title',
);
const SHARER_SETTING_MENU_CATEGORY2_TITLE = iText(
  'Who can start sharing when someone else is sharing?',
  'apac_wc_sharer_setting_menu_category2_title',
);

const SHARER_SETTING_MENU_CATEGORY_OPTION1 = iText(
  'Only Host',
  'apac_wc_sharer_setting_menu_category_option1',
);
const SHARER_SETTING_MENU_CATEGORY_OPTION2 = iText(
  'All Participants',
  'apac_wc_sharer_setting_menu_category_option2',
);
const SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR = iText(
  'Host and Panelists',
  'apac_wc_sharer_setting_menu_category_option2_webinar',
);
const SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR_WB = iText(
  'Host and All panelists',
  'apac_wc_sharer_setting_menu_category_option2_webinar_wb',
);
export function sharerSettingMenuCategoryOption2Factory(isWebinar) {
  return !isWebinar
    ? SHARER_SETTING_MENU_CATEGORY_OPTION2
    : SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR;
}
const SHARER_SETTING_MENU_CATEGORY_OPTION3 = iText(
  'Host Only',
  'apac_wc_sharer_setting_menu_category_option3',
);
const SHARER_SETTING_MENU_CATEGORY3_TITLE = iText(
  'How many participants can share at the same time?',
  'apac_wc_sharer_setting_menu_category3_title',
);
const SHARER_SETTING_MENU_CATEGORY3_TITLE_WEBINAR = iText(
  'How many panelists can share at the same time?',
  'apac_wc_sharer_setting_menu_category3_title_webinar',
);
export function sharerSettingMenuCategory3TitleFactory(isWebinar) {
  return !isWebinar
    ? SHARER_SETTING_MENU_CATEGORY3_TITLE
    : SHARER_SETTING_MENU_CATEGORY3_TITLE_WEBINAR;
}
const SHARER_SETTING_MENU_ITEM1 = iText(
  'One participant can share at a time',
  'apac_wc_sharer_setting_menu_item1',
);
const SHARER_SETTING_MENU_ITEM1_WEBINAR = iText(
  'One panelist can share at a time',
  'apac_wc_sharer_setting_menu_item1_webinar',
);
export function sharerSettingMenuItem1Factory(isWebinar) {
  return !isWebinar
    ? SHARER_SETTING_MENU_ITEM1
    : SHARER_SETTING_MENU_ITEM1_WEBINAR;
}
const SHARER_SETTING_MENU_ITEM2 = iText(
  'Multiple participants can share simultaneously',
  'apac_wc_sharer_setting_menu_item2',
);
const SHARER_SETTING_MENU_ITEM2_WEBINAR = iText(
  'Multiple panelists can share simultaneously',
  'apac_wc_sharer_setting_menu_item2_webinar',
);
const SHARER_SETTING_MENU_ITEM2_WEBINAR_PLUS = iText(
  '(dual monitors recommended)',
  'apac_wc_sharer_setting_menu_item2_webinar_plus',
);
export function sharerSettingMenuItem2Factory(isWebinar, hasPlus) {
  return !isWebinar
    ? SHARER_SETTING_MENU_ITEM2
    : SHARER_SETTING_MENU_ITEM2_WEBINAR +
        (hasPlus ? ` ${SHARER_SETTING_MENU_ITEM2_WEBINAR_PLUS}` : '');
}
const SHARER_SETTING_MENU_ADVANCE_ITEM = iText(
  'Advanced Sharing Options...',
  'apac_wc_sharer_setting_menu_advanced_item',
);
export {
  SHARER_ENTRY_BUTTON_LABEL1,
  SHARER_ENTRY_BUTTON_LABEL2,
  ARIA_SHARER_SETTING_MENU_ENTRY_BUTTON_TEXT,
  SHARER_SETTING_MENU_CATEGORY1_TITLE,
  SHARER_SETTING_MENU_CATEGORY2_TITLE,
  SHARER_SETTING_MENU_CATEGORY_OPTION1,
  SHARER_SETTING_MENU_CATEGORY_OPTION2,
  SHARER_SETTING_MENU_CATEGORY_OPTION3,
  SHARER_SETTING_MENU_ADVANCE_ITEM,
  SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR,
  SHARER_SETTING_MENU_CATEGORY_OPTION2_WEBINAR_WB,
};

const SHARER_CONTROLBAR_CONTAINER_REC_LABEL = iText(
  'Stop Recording',
  'apac_wc_sharer_controlbar_container_rec_label',
);
const SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP1 = iText(
  "You're sharing the screen",
  'apac_wc_sharer_controlbar_container_rec_status_tip1',
);
const SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP2 = iText(
  'Your screen sharing is paused',
  'apac_wc_sharer_controlbar_container_rec_status_tip2',
);
export const SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP3 = iText(
  "You're screen sharing",
  'apac_wc_sharer_controlbar_container_rec_status_tip3',
);
export const SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP4 = iText(
  "You're screen sharing to breakout rooms",
  'apac_wc_sharer_controlbar_container_rec_status_tip4',
);
const SHARER_CONTROLBAR_CONTAINER_BUTTON1_LABEL = iText(
  'Pause Share',
  'apac_wc_sharer_controlbar_container_button1_label',
);
const SHARER_CONTROLBAR_CONTAINER_BUTTON2_LABEL = iText(
  'Resume Share',
  'apac_wc_sharer_controlbar_container_button2_label',
);
const SHARER_CONTROLBAR_CONTAINER_BUTTON3_LABEL = iText(
  'Stop Share',
  'apac_wc_sharer_controlbar_container_button3_label',
);

const SHARER_CONTROLBAR_CONTAINER_SHARE_TO_BO_LABEL = iText(
  'Share to Breakout Rooms',
  'apac_wc_sharer_controlbar_container_share_to_bo_label',
);
const SHARER_CONTROLBAR_CONTAINER_PAUSE_AUDIO_SHARE = iText(
  'Pause Audio Share',
  'apac_wc_sharer_controlbar_container_pause_audio_share',
);
const SHARER_CONTROLBAR_CONTAINER_PLAY_AUDIO_SHARE = iText(
  'Play Audio Share',
  'apac_wc_sharer_controlbar_container_play_audio_share',
);

export {
  SHARER_CONTROLBAR_CONTAINER_REC_LABEL,
  SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP1,
  SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP2,
  SHARER_CONTROLBAR_CONTAINER_BUTTON1_LABEL,
  SHARER_CONTROLBAR_CONTAINER_BUTTON2_LABEL,
  SHARER_CONTROLBAR_CONTAINER_BUTTON3_LABEL,
  SHARER_ENTRY_BUTTON_OPTION_LABEL,
  SHARER_CONTROLBAR_CONTAINER_SHARE_TO_BO_LABEL,
  SHARER_CONTROLBAR_CONTAINER_PAUSE_AUDIO_SHARE,
  SHARER_CONTROLBAR_CONTAINER_PLAY_AUDIO_SHARE,
};

export function shareeViewSharing(userName) {
  return iText(
    `You are viewing ${userName}'s screen`,
    'apac.wc_sharing_viewing',
    [userName],
  );
}
export function sharerSharing() {
  return SHARER_CONTROLBAR_CONTAINER_REC_STATUS_TIP3;
}
export function shareeViewControlled(userName) {
  return iText(
    `${userName} is controlling your screen`,
    'apac.wc_sharing_be_controlled',
    [userName],
  );
}

export function shareeJoinRemoteControlSession(userName) {
  return iText(
    `Joining ${userName}'s remote control session`,
    'apac.wc_sharing_join_remote',
    [userName],
  );
}

export function shareeViewShaingFromMainSession(userName) {
  return iText(
    `You are viewing ${userName}'s screen from the main session`,
    'apac.wc_sharing_viewing_from_main_session',
    [userName],
  );
}
export function shareeCanRemoteControl(userName) {
  return iText(
    `You can control ${userName}'s screen`,
    'apac.wc_remote_control.can_control',
    [userName],
  );
}
export function shareeInRemoteControl(userName) {
  return iText(
    `You are controlling ${userName}'s screen`,
    'apac.wc_remote_control.is_controlling',
    [userName],
  );
}
export function shareeNewShareUser(userName) {
  return iText(
    `${userName} has started screen share, choose Options to view`,
    'apac.wc_sharing_new_user',
    [userName],
  );
}

export const SHAREE_SHARING_FIT = iText(
  'Fit to Window',
  'apac.wc_sharing_fit_to_window',
);
export const SHAREE_SHARING_ORIGINAL = iText(
  'Original Size',
  'apac.wc_sharing_original_size',
);
export const SHAREE_REQUEST_REMOTE_CONTROL = iText(
  'Request Remote Control',
  'apac.wc_remote_control.request',
);
export const SHAREE_GIVE_UP_REMOTE_CONTROL = iText(
  'Give Up Remote Control',
  'apac.wc_remote_control.give_up',
);
export const SHARER_STOP_REMOTE_CONTROL = iText(
  'Stop Remote Control',
  'apac.wc_remote_control.stop',
);
export const REMOTE_CONTROL_HELP_TITLE = iText(
  'Remote Control',
  'apac.wc_remote_control.help_title',
);
export const REMOTE_CONTROL_HELP_CONTENT = iText(
  'Now you can remotely control the screens of users who are not using Zoom client. The users being controlled must share an entire screen and install a 5MB app to proceed.',
  'apac.wc_remote_control.help_content',
);
export function requestControlWaitingApproveTip(userName) {
  return iText(
    `Waiting ${userName} to join remote control session`,
    'apac.wc_remote_control_pwa.waiting_approve_tip',
    userName,
  );
}

export function requestControlClickableTip(userName) {
  return iText(
    `Click to start to control  ${userName}’s  screen`,
    'apac.wc_remote_control.clickable-tip',
    userName,
  );
}
export const remoteControlEndedByRcappTip = iText(
  'Remote control session has ended',
  'apac.wc_remote_control.ended_by_rcapp',
);
export const SHAREE_SHARING_VIEW_OPTIONS = iText(
  'View Options',
  'apac.wc_sharing_view_options',
);
export const SHAREE_SHARED_SCREENS = iText(
  'Shared Screens',
  'apac.wc_sharing_shared_screens',
);
export const SHAREE_COPY_BTN = iText('Copy', 'apac.wc_click_to_copy');

export const SHAREE_MENU_OPTION1 = iText(
  'Share to Main Session Only',
  'apac.wc_sharing_menu_option1',
);
export const SHAREE_MENU_OPTION2 = iText(
  'Share to Main Session and Breakout Rooms',
  'apac.wc_sharing_menu_option2',
);

export const SHAREE_TO_BO_CONFIRM_DIALOG_TITLE = iText(
  'Share screen to all breakout rooms',
  'apac.wc_sharing_to_bo_confirm_dialog_title',
);
export const SHAREE_TO_BO_CONFIRM_DIALOG_CONTENT1 = iText(
  'Your screen will be shared in the main session and in all breakout rooms',
  'apac.wc_sharing_to_bo_confirm_dialog_content1',
);
export const SHAREE_TO_BO_CONFIRM_DIALOG_CONTENT2 = iText(
  'Your video and audio will not be shared with Breakout Rooms',
  'apac.wc_sharing_to_bo_confirm_dialog_content2',
);
export const SHAREE_TO_BO_CONFIRM_DIALOG_CONTENT3 = iText(
  'Screens that others are sharing in the rooms will be stopped',
  'apac.wc_sharing_to_bo_confirm_dialog_content3',
);
export const DO_NOT_SHOW_AGAIN_TEXT = iText(
  'Don’t show this again',
  'apac.wc_do_not_show_again',
);

export const SHARE_TEXT = iText('Share', 'apac.wc_share');
export const SHARE_LIMIT_TEXT = iText(
  'You are restricted from screen sharing in this meeting',
  'apac.wc_share_limit_text',
);
export const SHARING_LIMIT_TITLE = iText(
  'Your screen sharing has stopped',
  'apac.wc_sharing_limit_title',
);
export const SHARING_LIMIT_TEXT = iText(
  'Because you are restricted from screen sharing when external users are present',
  'apac.wc_sharing_limit_text',
);
export const SHAREE_NOTIFICATION_TEXT = iText(
  'Participants can now see your screen',
  'apac.wc_sharing_notify_text',
);
export const FORCE_STOP_SHARING_TIP = iText(
  'Your screen sharing has stopped because someone is sharing from the main session.',
  'apac.wc_share_force_stop_sharing_tip',
);

export const SHARE_AUDIO_TIP = iText(
  'Share audio from your computer',
  'apac.wc_share_audio_tip',
);

export const SHARE_AUDIO_FROM_A_CHROME_TAB_TIP = iText(
  'Share audio from a Chrome tab',
  'apac.wc_share_audio_from_a_chrome_tab_tip',
);

export const SHARE_AUDIO_NOTE = iText(
  'Note: you will be muted while sharing audio',
  'apac.wc_share_audio_note',
);
export const GOT_IT = iText('Got It', 'apac.wc_share_got_it');
export const MUTED_WHILE_SHARING_AUDIO_TIP = iText(
  "You are muted when you started sharing audio. You can unmute youself when you're done sharing.",
  'apac.wc_share_muted_while_sharing_audio_tip',
);
export const MUTED_WHILE_SHARING_AUDIO_TIP_2 = iText(
  'Your microphone is disabled when sharing computer audio. When you pause or stop sharing audio, your microphone will be reactivated.',
  'apac.wc_share_muted_while_sharing_audio_tip2',
);
export const makeMuteCurrentActiveSharingAudioText = (userName) =>
  iText(
    `Mute ${userName}'s Computer Audio`,
    'apac.wc_share_mute_current_active_sharing_audio',
    [userName],
  );
export const makeUnmuteCurrentActiveSharingAudioText = (userName) =>
  iText(
    `Unmute ${userName}'s Computer Audio`,
    'apac.wc_share_unmute_current_active_sharing_audio',
    [userName],
  );
export const OPTIMIZE_FOR_VIDEO_CLIP = iText(
  'Optimize for video clip',
  'apac.wc_optimize_for_video_clip',
);
export const SHARE_TO_BO_TEXT = iText(
  'Share to Breakout Rooms',
  'apac.wc_share_to_bo',
);
export const STOP_SHARE_TO_BO_TEXT = iText(
  'Stop Sharing to Breakout Rooms',
  'apac.wc_stop_share_to_bo',
);
export const UNABLE_VIEW_SHARE_FROM_MAINSESSION_TEXT = iText(
  'Unable to view shared screen from the main session',
  'apac.wc_share_unable_view_share_from_mainsession',
);

export const SHARING_DISABLED_NOTIFICATION = iText(
  'Screen sharing is disabled because your vehicle is not in park',
  'apac.wc_video.sharing-disabled-notification',
);

export const OLD_VERSION_CANNOT_RECEIVE_SHARED_SOUND_IN_BO_NOTIFICATION = iText(
  'Participants using older Zoom versions in breakout rooms cannot hear the shared sound',
  'apac.wc_share_old_version_cannot_receive_shared_sound_in_bo_notification',
);

export const OK_TEXT = iText('OK', 'apac.wc_share.ok');

export const SHARE_RESUME_TIP = iText(
  'sharing is resumed',
  'apac.share-resume',
);

export const SHARE_PAUSED_TIP = iText('sharing is paused', 'apac.share-paused');

export const DISABLE_ANNOTATION_FOR_OTHERS = iText(
  'Disable annotation for others',
  'apac.wc_disable_annotation_for_others',
);

export const ENABLE_ANNOTATION_FOR_OTHERS = iText(
  'Enable annotation for others',
  'apac.wc_enable_annotation_for_others',
);

export const HIDE_NAMES_OF_ANNOTATOR = iText(
  'Hide names of annotators',
  'apac.wc_enable_annotation_for_others',
);

export const SHOW_NAMES_OF_ANNOTATOR = iText(
  'Show names of annotators',
  'apac.wc_enable_annotation_for_others',
);
