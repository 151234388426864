import {
  CONTROL_MODE_CONNECTORS_ENUM,
  CONTROL_MODE_ZOOM_MSG_TYPE,
} from '../../enum';
import {
  GOOGLE_MEET_TRANSITION_STATE,
  GOOGLE_MEET_ERROR_TYPE,
  GOOGLE_MEET_ERROR_CODE_PREFIX,
  GOOGLE_MEET_ERROR_CODE_SUFFIX,
  GOOGLE_MEET_ERROR_SEVERITY,
  CONTENT_SHARE_STATE,
} from './enum';
import { isGoogleMeetMode } from '../../../global/util';
import { mediaSDKMsgHandler } from './media-sdk-msg-handler';
import { rwgMsgHandler } from './rwg-msg-handler';
import { xmppMsgHandler } from './xmpp-msg-handler';
import { localEvtHandler } from './local-evt-handler';
import { externalEvtHandler } from './external-evt-handler';
import { localReadyStatesObserver } from './local-ready-states-observer';
import { FAIL_TO_JOIN_RWG } from './resource';
import { generateUUID } from '../../../global/service/generate-uuid';
import { generateLayoutConfig } from './layout-helper';

const googleMeetAdaptor = ({ store }) => {
  let _cameraId;
  let _micId;

  return {
    shouldLoad() {
      return isGoogleMeetMode();
    },
    init() {
      externalEvtHandler(store, this);

      localReadyStatesObserver.onReady(
        this.notifyControllerJoinSuccess.bind(this),
      );
    },

    handleZoomMsg(msgType, evt, payload) {
      switch (msgType) {
        case CONTROL_MODE_ZOOM_MSG_TYPE.UI: {
          localEvtHandler(evt, payload, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.MEDIA_SDK: {
          mediaSDKMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.RWG: {
          rwgMsgHandler(evt, payload, store, this);
          break;
        }
        case CONTROL_MODE_ZOOM_MSG_TYPE.XMPP: {
          xmppMsgHandler(evt, this);
          break;
        }
        default:
          return false;
      }
    },
    /**
     * @returns {string} - The vendor ID for Google Meet
     */
    getVendorId() {
      return CONTROL_MODE_CONNECTORS_ENUM.GOOGLE_MEET;
    },
    /**
     * @returns {string} - The camera ID for the Google Meet device
     */
    getCameraId() {
      return _cameraId;
    },
    setCameraId(id) {
      _cameraId = id;
      window?.deviceManager?.manuallySelectCamera(_cameraId);
    },
    /**
     * @returns {string} - The speaker ID of the Google Meet device
     */
    getSpeakerId() {
      return 'default';
    },
    /**
     * @returns {string} - The mic ID of the Google Meet device
     */
    getMicId() {
      return _micId;
    },
    setMicId(id) {
      _micId = id;
      window?.deviceManager?.manuallySelectMicrophone(_micId);
    },
    isAllowedOrigin(requestor) {
      const allowedOriginsList = [
        'https://meet.google.com',
        '.meet.google.com',
        '.interop.meet.google.com',
        '.googlers.google.com',
        'https://jamboard.google.com',
        '.corp.google.com',
        '.googlers.com',
      ];

      return (
        allowedOriginsList.filter((origin) => origin.endsWith(requestor))
          .length !== -1 && requestor !== undefined
      );
    },
    /**
     * @param {Object} payload - The data to send to the controller interfaced with the Google Meet device
     * @return {undefined}
     */
    notifyController(payload) {
      const originsList = window.location.ancestorOrigins || [];
      let topOrigin = '';

      for (let i = 0; i < originsList.length; i++) {
        const current = originsList.item(i);
        if (this.isAllowedOrigin(current)) {
          topOrigin = current;
          break;
        }
      }

      if (window.parent === window) return;
      if (topOrigin === '') return;
      if (payload.data === undefined) payload.data = '';

      window.parent.postMessage(
        { id: `ZOOM-${generateUUID()}`, ...payload },
        topOrigin,
      );
    },
    requestRoomSystemState() {
      this.notifyController({
        type: 'roomSystemStateRequest',
      });
    },
    notifyControllerInitialMeetingStatus(data) {
      this.notifyController({
        type: 'initialMeetingState',
        data,
      });
    },
    notifyControllerMeetingUpdate(data) {
      this.notifyController({
        type: 'meetingStateUpdate',
        data,
      });
    },
    notifyControllerResponse(requestId = '', data = {}) {
      this.notifyController({
        type: 'meetingStateResponse',
        requestId,
        data,
      });
    },
    notifyControllerActionFailure({
      errorCode,
      message,
      localizedMessage,
      severity = GOOGLE_MEET_ERROR_SEVERITY.WARNING,
    }) {
      const error = {
        code: errorCode,
        localizedMessage: localizedMessage,
        message: message,
        severity: severity,
      };
      this.notifyControllerMeetingUpdate({ error: error });
    },
    notifyControllerAudioUnmuted() {
      this.notifyControllerMeetingUpdate({ audioMuted: false });
    },
    notifyControllerAudioMuted() {
      this.notifyControllerMeetingUpdate({ audioMuted: true });
    },
    notifyControllerVideoUnmuted() {
      this.notifyControllerMeetingUpdate({ videoMuted: false });
    },
    notifyControllerVideoMuted() {
      this.notifyControllerMeetingUpdate({ videoMuted: true });
    },
    notifyControllerStartJoining() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.CONNECTING,
      });
    },
    notifyControllerJoinSuccess() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.CONNECTED,
      });
    },
    notifyControllerJoinFailure() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.DISCONNECTED,
        error: {
          type: GOOGLE_MEET_ERROR_TYPE.OTHER,
          code: parseInt(
            GOOGLE_MEET_ERROR_CODE_PREFIX.INTERNAL_SERVER_ERROR +
              GOOGLE_MEET_ERROR_CODE_SUFFIX.DEFAULT,
          ),
          localizedMessage: FAIL_TO_JOIN_RWG,
          message: 'RWG failed to connect audio and video',
        },
      });
    },
    notifyControllerMeetingHoldOn() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.LOBBY,
      });
    },
    notifyControllerMeetingIsEnding() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.DISCONNECTING,
      });
    },
    notifyControllerMeetingHasEnded() {
      this.notifyControllerMeetingUpdate({
        state: GOOGLE_MEET_TRANSITION_STATE.DISCONNECTED,
      });
    },
    notifyControllerPresentationAllowed(isAllowed = true) {
      this.notifyControllerMeetingUpdate({
        contentShare: { isAllowed: isAllowed },
      });
    },
    notifyControllerPresentationStarted() {
      this.notifyControllerMeetingUpdate({
        contentShare: { state: CONTENT_SHARE_STATE.ACTIVE },
      });
    },
    notifyControllerPresentationStopped() {
      const { getState } = store;
      const state = getState();

      const layout = generateLayoutConfig(state);

      this.notifyControllerPresentationAllowed();
      this.notifyControllerMeetingUpdate({
        contentShare: { state: CONTENT_SHARE_STATE.INACTIVE },
        layout: layout,
      });
    },
    notifyControllerPresentationAudioMuted() {
      this.notifyControllerMeetingUpdate({
        contentShare: { isAudioMuted: true },
      });
    },
    notifycontrollerPresentationAudioUnmuted() {
      this.notifyControllerMeetingUpdate({
        contentShare: { isAudioMuted: false },
      });
    },
  };
};

export default googleMeetAdaptor;
