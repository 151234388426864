import { createActions } from 'redux-actions';

const SET_IS_OPEN = 'SET_IS_OPEN';
const TOGGLE_IS_HOVER_ANNO_BTN = 'TOGGLE_IS_HOVER_ANNO_BTN';
const UPDATE_USED_ANNOTATION_SHARING_LIST =
  'UPDATE_USED_ANNOTATION_SHARING_LIST';
const SET_REDO_STATUS = 'SET_REDO_STATUS';
const SET_UNDO_STATUS = 'SET_UNDO_STATUS';
const SET_SHARE_USER_ANNOTATION_INFO = 'SET_SHARE_USER_ANNOTATION_INFO';
const SET_ANNOTATION_OFF = 'SET_ANNOTATION_OFF';
const SET_IS_ALREADY_ANNOTATE = 'SET_IS_ALREADY_ANNOTATE';
const SET_SHOW_ANNOTATOR_NAME = 'SET_SHOW_ANNOTATOR_NAME';

export const {
  setIsOpen,
  toggleIsHoverAnnoBtn,
  updateUsedAnnotationSharingList,
  setRedoStatus,
  setUndoStatus,
  setShareUserAnnotationInfo,
  setAnnotationOff,
  setIsAlreadyAnnotate,
  setShowAnnotatorName,
} = createActions({
  [SET_IS_OPEN]: (payload) => payload,
  [TOGGLE_IS_HOVER_ANNO_BTN]: (payload) => payload,
  [UPDATE_USED_ANNOTATION_SHARING_LIST]: (payload) => payload,
  [SET_REDO_STATUS]: (payload) => payload,
  [SET_UNDO_STATUS]: (payload) => payload,
  [SET_SHARE_USER_ANNOTATION_INFO]: (payload) => payload,
  [SET_ANNOTATION_OFF]: (payload) => payload,
  [SET_IS_ALREADY_ANNOTATE]: (payload) => payload,
  [SET_SHOW_ANNOTATOR_NAME]: (payload) => payload,
});
