import {
  VIDEO_TYPE_90P,
  VIDEO_TYPE_180P,
  VIDEO_TYPE_360P,
  VIDEO_TYPE_720P,
  VIDEO_TYPE_1080P,
  QUALITY_RANGE_MAP,
} from '../enum';
import {
  isMac,
  isSafari,
  isChromeOS,
  isLowEndChromeBook,
  isTeslaMode,
  isSupportMultiView,
} from '../../../global/util';
import { isSupportVideoDecode1080P } from './service';
import { isOpOpen720PVideoDecodeFlag } from '../../../global/op-feature-option';

export const VIDEO_LAYOUT_ZONE = {
  GALLERY_MAIN_VIDEO: 11,
  SPEAKER_BAR_VIDEO: 21,
  SPEAKER_ACTIVE_VIDEO: 22,
  SUSPENSION_VIDEO: 31,
  MINIMIZE_VIDEO: 81,
  INTERPRETER_VIDEO: 91,
  SIMULIVE_STREAM_VIDEO: 101,
  MULTI_SPEAKER_ACTIVE_VIDEO: 111,
  MULTI_SPEAKER_MAIN_VIDEO: 112,
};

const ZONE_GROUPS = [
  VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO,
  VIDEO_LAYOUT_ZONE.GALLERY_MAIN_VIDEO,
  VIDEO_LAYOUT_ZONE.SIMULIVE_STREAM_VIDEO,
  VIDEO_LAYOUT_ZONE.MULTI_SPEAKER_ACTIVE_VIDEO,
  VIDEO_LAYOUT_ZONE.MULTI_SPEAKER_MAIN_VIDEO,
];

export default function calculateQuality(
  currentRenderVideo,
  receivedVideoQuality = VIDEO_TYPE_360P,
  isSelfSupport1080P,
  useWBVideo,
) {
  const currentRenderVideoZoneLengthMap = currentRenderVideo.reduce(
    (obj, item) => {
      if (obj[item.zone]) {
        obj[item.zone].push(item);
      } else {
        obj[item.zone] = [item];
      }
      return obj;
    },
    {},
  );

  const templist = currentRenderVideo.map((item) => {
    // other 90p
    let quality = VIDEO_TYPE_90P;
    // 720p use origin pixel height
    let dpiHeight = item.height;
    if (window.devicePixelRatio) {
      dpiHeight = item.height * window.devicePixelRatio;
    }

    const zoneListLength = currentRenderVideoZoneLengthMap[item.zone]?.length;

    if (
      // origin pixel height >= 850
      ZONE_GROUPS.includes(item.zone) &&
      isSelfSupport1080P &&
      isSupportVideoDecode1080P() &&
      zoneListLength === 1 &&
      dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_1080P.MIN
    ) {
      quality = VIDEO_TYPE_1080P;
    } else if (
      //mac origin pixel height>= 700, 1080p switch also use for mac 720
      ZONE_GROUPS.includes(item.zone) &&
      zoneListLength === 1 &&
      isMac() &&
      (!isSafari() || isOpOpen720PVideoDecodeFlag()) &&
      dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_MAC
    ) {
      quality = VIDEO_TYPE_720P;
    } else if (
      //chromebook origin pixel height >= 520,not include single view
      item.zone === VIDEO_LAYOUT_ZONE.SPEAKER_ACTIVE_VIDEO &&
      zoneListLength === 1 &&
      isChromeOS() &&
      (useWBVideo || (!useWBVideo && !isLowEndChromeBook())) &&
      dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_CHROMEBOOK
    ) {
      quality = VIDEO_TYPE_720P;
    } else if (
      //windows origin pixel height >= 700, limit multiview
      ZONE_GROUPS.includes(item.zone) &&
      (useWBVideo || (!useWBVideo && isSupportMultiView())) &&
      zoneListLength === 1 &&
      !isChromeOS() &&
      !isMac() &&
      dpiHeight >= QUALITY_RANGE_MAP.VIDEO_TYPE_720P.MIN_1_WINDOWS
    ) {
      quality = VIDEO_TYPE_720P;
    } else if (
      zoneListLength <= 4 &&
      item.height >= QUALITY_RANGE_MAP.VIDEO_TYPE_360P.MIN_4
    ) {
      // num<=4 height >= 270  height< 720
      quality = VIDEO_TYPE_360P;
    } else if (
      // num>=4 num <= 16height >= 180
      zoneListLength <= 16 &&
      item.height >= QUALITY_RANGE_MAP.VIDEO_TYPE_180P.MIN_9
    ) {
      quality = VIDEO_TYPE_180P;
    } else if (
      // num>=16
      zoneListLength > 16
    ) {
      quality = VIDEO_TYPE_90P;
    }

    // speaker view/ suspension view  90p
    if (item.isStableQuality) {
      quality = VIDEO_TYPE_90P;
    }

    if (!useWBVideo) {
      if (isTeslaMode()) {
        quality = Math.min(receivedVideoQuality, quality, VIDEO_TYPE_360P);
      } else if (
        (quality === VIDEO_TYPE_1080P &&
          receivedVideoQuality !== VIDEO_TYPE_720P) ||
        quality !== VIDEO_TYPE_1080P
      ) {
        quality = Math.min(receivedVideoQuality, quality);
      }
    }

    return {
      ...item,
      quality,
    };
  });

  // for multi speaker view
  // only one 720p exist
  // if active area exist, main area will be max 180p
  if (
    templist.length >= 2 &&
    templist[0].zone === VIDEO_LAYOUT_ZONE.MULTI_SPEAKER_ACTIVE_VIDEO &&
    templist[templist.length - 1].zone ===
      VIDEO_LAYOUT_ZONE.MULTI_SPEAKER_MAIN_VIDEO
  ) {
    for (let i = 0; i < templist.length; i++) {
      if (templist[i].zone === VIDEO_LAYOUT_ZONE.MULTI_SPEAKER_MAIN_VIDEO) {
        templist[i].quality = Math.min(VIDEO_TYPE_180P, templist[i].quality);
      }
    }
  }
  return templist;
}
