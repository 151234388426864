/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PreviewVideo from '../component/PreviewVideo';
import usePreviewVideo from '../hooks/usePreviewVideo';
import AllowPermissionPrompt from '../component/allow-permission-prompt/AllowPermissionPrompt';
import NoPermissionConfirm from '../component/no-permission-confirm/NoPermissionConfirm';
import {
  CACHE_KEY,
  PREVIEW_SIZE,
  PREVIEW_VIDEO_STATUS,
  REQUEST_PERMISSION_STATUS,
} from '../consts';
import { cacheVideoStatus } from '../service';
import { Job } from '@zoom/common-utils';
import { JOB_ENUM } from '../../../job_enum';
import AskPermissionPrompt from '../component/ask-permission-prompt/AskPermissionPrompt';
import GrantPermissionDialog from '../component/grant-permission-dialog/GrantPermissionDialog';
import { setPreviewPermissionDismissStatus } from '../../../global';

const PreviewVideoContainer = (props) => {
  const {
    className,
    isAudioInitSuccess,
    isVideoInitSuccess,
    cache = CACHE_KEY.PREVIEW_OPTIONS,
    canAudioUnmute,
    canVideoUnmute,
    displayName,
    size,
    isAudioEnabled = true,
    resizable,
    sessionBranding,
    avatar,
    needDefaultAvatar,
  } = props;
  const [showAllowPermissionPrompt, toggleShowAllowPermissionPrompt] =
    useState(false);
  const [showNoPermissionConfirm, toggleShowNoPermissionConfirm] =
    useState(false);
  const [showAskPermissionConfirm, toggleShowAskPermissionConfirm] =
    useState(false);
  const [askMicrophoneOnly, setAskMicrophoneOnly] = useState(false);
  const [grantPermissionType, setGrantPermissionType] = useState(null);
  // do not request permission on waiting room
  const shouldRequestPermission = size !== PREVIEW_SIZE.SMALL;
  const {
    audioStatus,
    videoStatus,
    speakers,
    microphones,
    cameras,
    activeSpeaker,
    activeMicrophone,
    activeCamera,
    nextAudioStatus,
    nextVideoStatus,
    willRenderVideo,
    error,
    setError,
    handleAudioClick,
    handleVideoClick,
    setVideoStatus,
    handleAskPermissionResolve,
    handleGrantPermissionResolve,
  } = usePreviewVideo({
    shouldRequestPermission,
    isAudioInitSuccess: isAudioInitSuccess,
    isVideoInitSuccess: isVideoInitSuccess,
    cache: cache,
    canAudioUnmute: canAudioUnmute,
    canVideoUnmute: canVideoUnmute,
    askMicrophoneOnly,
    grantPermissionType,
    toggleShowAllowPermissionPrompt,
    toggleShowNoPermissionConfirm,
    toggleShowAskPermissionConfirm,
    setGrantPermissionType,
  });

  return (
    <>
      <PreviewVideo
        className={className}
        displayName={displayName}
        audioStatus={audioStatus}
        videoStatus={videoStatus}
        nextAudioStatus={nextAudioStatus}
        nextVideoStatus={nextVideoStatus}
        speakers={speakers}
        microphones={microphones}
        cameras={cameras}
        activeSpeaker={activeSpeaker}
        activeMicrophone={activeMicrophone}
        activeCamera={activeCamera}
        willRenderVideo={willRenderVideo}
        error={error}
        setError={setError}
        onAudioClick={handleAudioClick}
        onVideoClick={handleVideoClick}
        size={size}
        isAudioEnabled={isAudioEnabled}
        avatar={avatar}
        resizable={resizable}
        sessionBranding={sessionBranding}
        needDefaultAvatar={needDefaultAvatar}
      />
      <AllowPermissionPrompt
        show={showAllowPermissionPrompt}
        onClose={() => toggleShowAllowPermissionPrompt(false)}
      />
      <NoPermissionConfirm
        show={showNoPermissionConfirm}
        onClose={() => {
          toggleShowNoPermissionConfirm(false);
          //close video in preview to avoid trigger permission again
          setVideoStatus(PREVIEW_VIDEO_STATUS.CLOSED);
          cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.CLOSED);
          Job.complete(
            JOB_ENUM.REQUEST_PERMISSION,
            REQUEST_PERMISSION_STATUS.DENIED,
          );
        }}
      />
      <AskPermissionPrompt
        show={showAskPermissionConfirm}
        onClose={() => {
          toggleShowAskPermissionConfirm(false);
          setVideoStatus(PREVIEW_VIDEO_STATUS.FORBIDDEN);
          cacheVideoStatus(cache, PREVIEW_VIDEO_STATUS.FORBIDDEN);
          Job.complete(
            JOB_ENUM.PEPC_PERMISSION_REQUEST,
            REQUEST_PERMISSION_STATUS.DISMISS,
          );
          setPreviewPermissionDismissStatus('audio', true);
          setPreviewPermissionDismissStatus('video', true);
        }}
        askMicrophoneOnly={askMicrophoneOnly}
        setAskMicrophoneOnly={setAskMicrophoneOnly}
        toggleShowAskPermissionConfirm={toggleShowAskPermissionConfirm}
        handleAskPermissionResolve={handleAskPermissionResolve}
      />
      <GrantPermissionDialog
        onClose={() => {
          setGrantPermissionType(null);
        }}
        grantPermissionType={grantPermissionType}
        handleGrantPermissionResolve={handleGrantPermissionResolve}
      />
    </>
  );
};

export default PreviewVideoContainer;
