import { iText } from '../../../global/util';

export const FAIL_TO_JOIN_RWG = iText(
  'RWG failed to connect audio and video',
  'apac.googleMeet.fail_to_join_rwg',
);

export const SPEAKER_VIEW = iText('Gallery View', 'apac.common.speaker_view');

export const GALLERY_VIEW = iText('Speaker View', 'apac.common.gallery_view');
