/**
 * Supported Features:
 * - Layout Selection
 * - HDMI Ingest (Screen Share)
 *
 * Limited Support:
 * - User joining BO room (only if host has setting to automatically join GMH user)
 *
 * Unsupported (or not publically supported) Features:
 * - Webinar
 */
import {
  ZOOM_CONNECTOR_EVT_ENUM,
  GOOGLE_MEET_END_MEETING_REASON,
  GOOGLE_MEET_TRANSITION_STATE,
  GOOGLE_MEET_ERROR_CODE_PREFIX as CODE_PREFIX,
  GOOGLE_MEET_ERROR_CODE_SUFFIX as CODE_SUFFIX,
  CONTENT_SHARE_STATE,
  VIDEO_RESOLUTION,
  GOOGLE_MEET_LAYOUTS,
} from './enum';
import { coOrHostSelector } from '../../../global/redux';
import { makeLogger, reportToGlobalTracing } from '../../../global/logger';
import { setAvDisabledTipVisible } from '../../../actions/MeetingUIActions';
import { setControllerDisableAudio } from '../../../features/audio/redux/audio-action';
import {
  unmuteAudio,
  muteAudio,
} from '../../../features/audio/redux/audio-thunk-action';
import {
  showNotAllowUnmuteDialog,
  muteVideo,
  showDisableSharingDialog,
} from '../../../features/dialog/redux/dialog-thunk-action';
import {
  setControllerDisableVideo,
  setIsNeedNotifyStartVideoFail,
  setShowSelfView,
} from '../../../features/video/redux/video-action';
import {
  startCaptureVideo,
  stopCaptureVideo,
} from '../../../features/video/redux/thunks/start-stop-capture-video-thunk';
import { isVideoEncodeReadySelector } from '../../../features/video/redux/selectors/video-status-selector';
import {
  MUTE_VIDEO_MODAL_STOP,
  VIDEO_LAYOUT_GALLERY_VIEW,
  VIDEO_LAYOUT_SPEAK_VIEW,
} from '../../../features/video/enum';
import { isWebinar } from '../../../global/service/meeting-types';
import { isViewOnly } from '../../../global/service/user-types';
import { leaveMeetingThunkAction } from '../../../global/redux/thunk-action/end-meeting-request';
import { isAutoTurnOnVideo } from '../../utils';
import { globalVariable } from '../../../global/global-variable';
import {
  START_DESKTOP_SHARING,
  SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
  SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
  SHARER_PERMISSION_CODE,
} from '../../../features/sharing/enum';
import { getShareCaptureMode } from '../../../features/sharing/service';
import {
  stopSharer,
  pauseOrResumeShareAudio,
} from '../../../features/sharing/redux/sharing-thunk-action';
import { setVideoLayoutThunk } from '../../../features/video/redux/thunks/video-layout-change-thunk';
import { generateLayoutConfig } from './layout-helper';

const handleLayoutChange = (
  adaptor,
  dispatch,
  videoLayout,
  data = {},
  requestId,
) => {
  const layoutId = data?.data?.data?.layoutId || '';

  switch (layoutId) {
    case GOOGLE_MEET_LAYOUTS.GRID.id:
      dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_GALLERY_VIEW));
      adaptor?.notifyControllerResponse(requestId, {
        layout: {
          currentlySelectedLayout: GOOGLE_MEET_LAYOUTS.GRID.id,
        },
      });
      break;
    case GOOGLE_MEET_LAYOUTS.TOPBAR.id:
      dispatch(setVideoLayoutThunk(VIDEO_LAYOUT_SPEAK_VIEW));
      adaptor?.notifyControllerResponse(requestId, {
        layout: {
          currentlySelectedLayout: GOOGLE_MEET_LAYOUTS.TOPBAR.id,
        },
      });
      break;
    default:
      adaptor?.notifyControllerResponse(requestId, {
        layout: {
          currentlySelectedLayout: layoutId,
        },
      });
      break;
  }
};

export const externalEvtHandler = (store, adaptor) => {
  const { getState, dispatch } = store;

  // Always force self view to be true to avoid privacy issues related to this value being off.
  dispatch(setShowSelfView(true));

  let {
    meeting: {
      currentUser: { muted },
    },
    meetingUI: { isOnHold },
    sharing: { sharerIsShareOptionLocked, sharerEnabled },
  } = getState();

  const selectHardware = (label, selectedLabel, callback) => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const targetDevice = devices.find(
        (device) =>
          device.kind === label && device.label.includes(selectedLabel),
      );

      if (targetDevice) {
        callback(targetDevice.deviceId);
      }
    });
  };

  const videoLogger = makeLogger(['Google-Meet Video']);
  const audioLogger = makeLogger(['Google-Meet Audio']);
  const shareLogger = makeLogger(['Google-Meet Share']);

  const handlePostMessage = async ({ data, origin }) => {
    if (!adaptor.isAllowedOrigin(origin)) return;
    let messageType = data?.type;

    if (messageType !== 'roomSystemStateResponse') {
      messageType = data?.data?.type;
    }

    const requestId = data?.id;

    const state = getState();
    const {
      meeting: {
        res,
        bCanUnmute,
        bCanUnmuteVideo,
        loginUserUid,
        currentUser,
        currentUser: {
          userRole,
          isAllowTalk: isCurrentUserAllowTalk,
          bVideoMute: isCurrentUserVideoMuted,
        },
      },
      settings: {
        vbSettings: { enableVB, enableForceUseVB },
      },
      audio: { audioCaptureFailedReason },
      video: {
        UI: { loading, videoLayout },
        cameraDevicesList,
        isCameraCaptureLoading,
        isCameraForbidden,
        isNeedNotifyStartVideoFail,
      },
      dialog: {
        unmuteByHost: { visible: isUnmuteByHostVisible },
      },
      sharing: { sharerSsrc, sharerPermissionCode },
    } = state;

    const isViewOnlyUser = isViewOnly(userRole);
    const coOrHost = coOrHostSelector(state);
    const isVideoEncodeReady = isVideoEncodeReadySelector(state);

    switch (messageType) {
      case 'roomSystemStateResponse': {
        // 4. Finish synchronizing state, based on Host's state

        const layout = generateLayoutConfig(state);

        const capabilities = {
          contentShare: {
            isAllowed: sharerIsShareOptionLocked || sharerEnabled,
            state: CONTENT_SHARE_STATE.INACTIVE,
            isAudioMuted: false,
          },
          layout: layout,
        };

        const { selectedCameraLabel, selectedMicLabel } = data?.data || {};

        // store selected camera label
        selectHardware('videoinput', selectedCameraLabel, adaptor.setCameraId);

        const videoState = {
          videoMuted: !isAutoTurnOnVideo(),
        };

        // store selected mic label
        selectHardware('audioinput', selectedMicLabel, adaptor.setMicId);

        const audioState = {
          audioMuted: !!muted,
        };

        adaptor.notifyControllerMeetingUpdate({
          ...capabilities,
          ...audioState,
          ...videoState,
        });

        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_AUDIO: {
        if (data.isHardwareLevel) {
          dispatch(setAvDisabledTipVisible(true));
          dispatch(setControllerDisableAudio(true));
        }

        if (audioCaptureFailedReason) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_AUDIO_FAILED,
            message: `Unable to mute audio (audio capture failed): ${audioCaptureFailedReason}`,
            localizedMessage: `Unable to mute audio (audio capture failed): ${audioCaptureFailedReason}`,
          });
          audioLogger.error(
            `Value of audioCaptureFailedReason: ${!!audioCaptureFailedReason}`,
            ['GOOGLE_MEET_MUTE_AUDIO'],
          );

          return;
        }

        if (isWebinar() && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_AUDIO_FAILED,
            message:
              'Unable to mute audio without permission to speak during webinar.',
            localizedMessage:
              'Unable to mute audio without permission to speak during webinar.',
          });
          audioLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_MUTE_AUDIO',
          ]);
          audioLogger.error(
            `Value of isCurrentUserAllowToTalk: ${isCurrentUserAllowTalk}`,
            ['GOOGLE_MEET_MUTE_AUDIO'],
          );

          return;
        }

        adaptor.notifyControllerMeetingUpdate({ audioMuted: true });
        dispatch(muteAudio(currentUser.userId));
        audioLogger.log('Google-Meet audio successfully muted', [
          'GOOGLE_MEET_MUTE_AUDIO',
        ]);

        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_AUDIO: {
        const isAllowedToMute =
          coOrHost || bCanUnmute || !currentUser.muted || isUnmuteByHostVisible;

        if (data.isHardwareLevel) {
          dispatch(setControllerDisableAudio(false));
        }

        if (audioCaptureFailedReason) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
            message: `Unable to unmute audio (audio capture failed): ${audioCaptureFailedReason}`,
            localizedMessage: `Unable to unmute audio (audio capture failed): ${audioCaptureFailedReason}`,
          });
          audioLogger.error(
            `Value of audioCaptureFailedReason: ${!!audioCaptureFailedReason}`,
            ['GOOGLE_MEET_UNMUTE_AUDIO'],
          );
          return;
        }

        if (isWebinar() && isViewOnlyUser && !isCurrentUserAllowTalk) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
            message:
              'Unable to unmute audio without permission to speak during webinar.',
            localizedMessage:
              'Unable to unmute audio without permission to speak during webinar.',
          });
          dispatch(showNotAllowUnmuteDialog());
          audioLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_UNMUTE_AUDIO',
          ]);
          audioLogger.error(
            `Value of isCurrentUserAllowToTalk: ${isCurrentUserAllowTalk}`,
            ['GOOGLE_MEET_UNMUTE_AUDIO'],
          );
          return;
        }

        if (!isAllowedToMute) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_AUDIO_FAILED,
            message: 'Unable to mute audio without permission to mute.',
            localizedMessage:
              'Unable to mute audio without permission to mute.',
          });
          dispatch(showNotAllowUnmuteDialog());
          audioLogger.error(
            `Value of isAllowedToMute: ${isAllowedToMute}`,
            'GOOGLE_MEET_UNMUTE_AUDIO',
          );
          return;
        }

        adaptor.notifyControllerMeetingUpdate({ audioMuted: false });
        dispatch(unmuteAudio(currentUser.userId));
        audioLogger.log('Google-Meet audio successfully unmuted', [
          'GOOGLE_MEET_UNMUTE_AUDIO',
        ]);

        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_VIDEO: {
        if (data.isHardwareLevel) {
          dispatch(setAvDisabledTipVisible(true));
          dispatch(setControllerDisableVideo(true));
        }

        if (isWebinar() && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_VIDEO_FAILED,
            message:
              'Unable to turn off video as user only has view privilege in webinar.',
            localizedMessage:
              'Unable to turn off video as user only has view privilege in webinar.',
          });
          videoLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_MUTE_VIDEO',
          ]);
          return;
        }

        if (isCameraCaptureLoading || isCameraForbidden) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.MUTE_VIDEO_FAILED,
            message:
              'Unable to turn off video without appropriate camera permissions.',
            localizedMessage:
              'Unable to turn off video without appropriate camera permissions.',
          });
          videoLogger.error(
            `Value of isCameraCaptureLoading: ${isCameraCaptureLoading}`,
            ['GOOGLE_MEET_MUTE_VIDEO'],
          );
          videoLogger.error(
            `Value of isCameraForbidden: ${isCameraForbidden}`,
            ['GOOGLE_MEET_MUTE_VIDEO'],
          );
          return;
        }

        adaptor.notifyControllerResponse(requestId, { videoMuted: true });
        dispatch(stopCaptureVideo());
        videoLogger.log('Google-Meet video successfully muted', [
          'GOOGLE_MEET_MUTE_VIDEO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_VIDEO: {
        if (data.isHardwareLevel) {
          dispatch(setControllerDisableVideo(false));
        }

        if (isWebinar() && isViewOnlyUser) {
          adaptor.notifyControllerActionFailure({
            errorCode: CODE_PREFIX.FORBIDDEN + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message:
              'Unable to turn on video as user only has view privilege in webinar.',
            localizedMessage:
              'Unable to turn on video as user only has view privilege in webinar.',
          });
          videoLogger.error(`Value of isViewOnlyUser: ${isViewOnlyUser}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (!isVideoEncodeReady || loading) {
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message:
              'Unable to turn on video due to video encoder not being ready.',
            localizedMessage:
              'Unable to turn on video due to video encoder not being ready.',
          });
          videoLogger.error(
            `Value of isVideoEncodeReady: ${isVideoEncodeReady}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
          videoLogger.error(`Value of loading: ${loading}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (cameraDevicesList.length < 1) {
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message: 'Unable to turn on video, no camera detected.',
            localizedMessage: 'Unable to turn on video, no camera detected.',
          });
          videoLogger.error(
            `Value of cameraDeviceList.length: ${cameraDevicesList.length}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
        }

        if (
          (isCurrentUserVideoMuted && !coOrHost) ||
          (!bCanUnmuteVideo && !coOrHost)
        ) {
          dispatch(
            muteVideo({
              type: MUTE_VIDEO_MODAL_STOP,
            }),
          );
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message: 'Unable to turn on video, insufficient permission.',
            localizedMessage:
              'Unable to turn on video, insufficient permission.',
          });
          videoLogger.error(
            `Value of isCurrentUserVideoMuted: ${isCurrentUserVideoMuted}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
          videoLogger.error(`Value of bCanUnmuteVideo: ${bCanUnmuteVideo}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          return;
        }

        if (loginUserUid && enableForceUseVB && !enableVB) {
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message:
              'Unable to turn on video due to virtual background related errors.',
            localizedMessage:
              'Unable to turn on video due to virtual background related errors.',
          });
          videoLogger.error(`Value of loginUserId: ${loginUserUid}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          videoLogger.error(`Value of enableForceUseVB: ${enableForceUseVB}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
          videoLogger.error(`Value of enableVB: ${enableVB}`, [
            'GOOGLE_MEET_UNMUTE_VIDEO',
          ]);
        }

        if (isNeedNotifyStartVideoFail) {
          dispatch(setIsNeedNotifyStartVideoFail(false));
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.BAD_REQUEST + CODE_SUFFIX.UNMUTE_VIDEO_FAILED,
            message: 'Video failed to start.',
            localizedMessage: 'Video failed to start.',
          });
          videoLogger.error(
            `Value of isNeedNotifyStartVideoFail: ${isNeedNotifyStartVideoFail}`,
            ['GOOGLE_MEET_UNMUTE_VIDEO'],
          );
        }

        adaptor.notifyControllerResponse(requestId, { videoMuted: false });
        dispatch(startCaptureVideo());
        videoLogger.log('Google-Meet video successfully unmuted', [
          'GOOGLE_MEET_UNMUTE_VIDEO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.LAYOUT_CHANGE: {
        handleLayoutChange(adaptor, dispatch, videoLayout, data, requestId);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.USER_INITIATED_LOG_UPLOAD: {
        const config = {
          userId: currentUser?.userId,
          filter: () => true,
          confId: currentUser?.confId,
          conID: currentUser?.conId,
          svcUrl: currentUser?.svcUrl,
          res,
        };

        reportToGlobalTracing(config);
        break;
      }

      case GOOGLE_MEET_END_MEETING_REASON.LOCAL_HANGUP: {
        const isHungUpRemotely = true;

        adaptor.notifyControllerMeetingIsEnding();
        dispatch(leaveMeetingThunkAction({ isHungUpRemotely }));
        adaptor.notifyControllerMeetingHasEnded();
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.START_CONTENT_SHARE: {
        if (
          sharerPermissionCode ===
            SHARER_PERMISSION_CODE.CMM_SHARE_SETTING_LOCK_SHARE &&
          !coOrHost
        ) {
          dispatch(showDisableSharingDialog());
          adaptor.notifyControllerActionFailure({
            errorCode:
              CODE_PREFIX.APPLICATION_ERROR + CODE_SUFFIX.CONTENT_SHARE_FAILED,
            message: 'Sharing has not been permitted for this meeting.',
            localizedMessage:
              'Sharing has not been permitted for this meeting.',
          });
          shareLogger.error(
            `Value of sharerPermissionCode: ${sharerPermissionCode}`,
            ['GOOGLE_MEET_START_CONTENT_SHARE'],
          );
          return;
        }

        const getDeviceId = async (data, deviceKind) => {
          const deviceLabel =
            deviceKind.indexOf('video') !== -1
              ? data?.video?.deviceLabel
              : data?.audio?.deviceLabel;

          const devices = await navigator.mediaDevices.enumerateDevices();
          const selectedDevice = devices.find(
            (device) =>
              device.kind == deviceKind && device.label === deviceLabel,
          );

          return selectedDevice?.deviceId ?? '';
        };

        const contentShareParamsData = (data || {}).data?.data;
        const audioDeviceId = await getDeviceId(
          contentShareParamsData,
          'audioinput',
        );
        const videoDeviceId = await getDeviceId(
          contentShareParamsData,
          'videoinput',
        );

        globalVariable.avSocket.sendSocket(START_DESKTOP_SHARING, {
          mode: getShareCaptureMode(),
          ssid: sharerSsrc,
          canvas: SHARER_SHARING_CANVAS_PLACEHOLDER_DOM_ID,
          video: SHARER_SHARING_VIDEO_PLACEHOLDER_DOM_ID,
          share2ndCamera: true,
          share2ndCameraParams: {
            VideoSelectValue: videoDeviceId,
            AudioSelectValue: audioDeviceId,
            frameRate: 60,
            width: VIDEO_RESOLUTION.WIDTH,
            height: VIDEO_RESOLUTION.HEIGHT,
            noiseSuppression: false,
            autoGainControl: false,
            echoCancellation: false,
            sampleRate: 48000,
          },
        });
        shareLogger.log('Google-Meet started content share successfully', [
          'GOOGLE_MEET_START_CONTENT_SHARE',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.MUTE_CONTENT_SHARE_AUDIO: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { isAudioMuted: true },
        });
        dispatch(pauseOrResumeShareAudio(true));
        shareLogger.log('Google-Meet mute content share audio successfully', [
          'GOOGLE_MEET_MUTE_CONTENT_SHARE_AUDIO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.UNMUTE_CONTENT_SHARE_AUDIO: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { isAudioMuted: false },
        });
        dispatch(pauseOrResumeShareAudio(false));
        shareLogger.log('Google-Meet unmute content share audio successfully', [
          'GOOGLE_MEET_UNMUTE_CONTENT_SHARE_AUDIO',
        ]);
        break;
      }

      case ZOOM_CONNECTOR_EVT_ENUM.STOP_CONTENT_SHARE: {
        adaptor.notifyControllerMeetingUpdate({
          contentShare: { state: CONTENT_SHARE_STATE.INACTIVE },
        });
        dispatch(stopSharer());
        shareLogger.log('Google-Meet stopped content share successfully', [
          'GOOGLE_MEET_STOP_CONTENT_SHARE',
        ]);
        break;
      }

      default:
        break;
    }
  };

  const connectingOrLobby = () => {
    if (!window.parent) {
      return;
    }
    let state = GOOGLE_MEET_TRANSITION_STATE.CONNECTING;
    if (isOnHold) {
      state = GOOGLE_MEET_TRANSITION_STATE.LOBBY;
    }

    const meetingState = {
      state: state,
    };
    adaptor.notifyControllerMeetingUpdate(meetingState);
  };

  // startup sequence begins here

  // 1. Set up Event Listeners
  window.addEventListener('message', handlePostMessage);

  // 2. Let GMH know whether we are CONNECTING to a meeting, or in the Waiting Room (lobby)
  connectingOrLobby();

  // 3. Request the Room's State, to be handled by an event listener
  adaptor.requestRoomSystemState();

  return () => {
    window.removeEventListener('message', handlePostMessage);
  };
};
