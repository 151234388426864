import { createActions } from 'redux-actions';

const TOGGLE_SHARER_PERMISSION_WIDGET = 'TOGGLE_SHARER_PERMISSION_WIDGET';
const TOGGLE_SHARER_POPOVER = 'TOGGLE_SHARER_POPOVER';

const SET_SHARER_STATUS = 'SET_SHARER_STATUS';
const SET_SHARER_PERMISSION_CODE = 'SET_SHARER_PERMISSION_CODE';
const SET_SHARER_SSRC = 'SET_SHARER_SSRC';
const CLOSE_SHARE = 'WS_SHARING_MUTE_SHARE_REQ';
const PAUSE_SHARER = 'WS_SHARING_PAUSE_REQ';
const RESUME_SHARER = 'WS_SHARING_RESUME_REQ';
const SET_SHARER_CLIENT_RECEIVED_RECT_DIMENSION =
  'SET_SHARER_CLIENT_RECEIVED_RECT_DIMENSION';
const SET_SHARER_EXTENSION_INSTALLED = 'SET_SHARER_EXTENSION_INSTALLED';
const SET_SHARER_ENCODE_SDK_INITIALIZED = 'SET_SHARER_ENCODE_SDK_INITIALIZED';
const SHARE_RESET = 'SHARE_RESET';
const SET_SHARING_ACTIVE_NODE = 'SET_SHARING_ACTIVE_NODE';
const SET_CURRENT_SHARING_FPS = 'SET_CURRENT_SHARING_FPS';
const SET_CURRENT_SHARING_RESOLUTION = 'SET_CURRENT_SHARING_RESOLUTION';
const SET_SHARING_CONTENT_IS_ORIGINAL_SIZE =
  'SET_SHARING_CONTENT_IS_ORIGINAL_SIZE';
const SET_REQUESTING_REMOTE_CONTROL = 'SET_REQUESTING_REMOTE_CONTROL';
const SET_REMOTE_CONTROL = 'SET_REMOTE_CONTROL';
const SET_GRAB_REMOTE_CONTROL = 'SET_GRAB_REMOTE_CONTROL';
const SET_SHARING_CONTENT_ORIGINAL_DIMENSION =
  'SET_SHARING_CONTENT_ORIGINAL_DIMENSION';
const ADD_REMOTE_CONTROL_USER = 'ADD_REMOTE_CONTROL_USER';
const REMOVE_REMOTE_CONTROL_USER = 'REMOVE_REMOTE_CONTROL_USER';
const UPDATE_REMOTE_CONTROL_USER = 'UPDATE_REMOTE_CONTROL_USER';
const SET_COPY_BUTTON_VISIBLE = 'SET_COPY_BUTTON_VISIBLE';
const SET_REMOTE_CONTROL_CLIPBOARD = 'SET_REMOTE_CONTROL_CLIPBOARD';
const SET_SDK_IS_GRAB_CONTROL = 'SET_SDK_IS_GRAB_CONTROL';
const SET_NEW_SHARER_TOAST_VISIBLE = 'SET_NEW_SHARER_TOAST_VISIBLE';
const SET_SHAREE_DECODE_SDK_INITIALIZED = 'SET_SHAREE_DECODE_SDK_INITIALIZED';
const TOGGLE_SHARER_INDICATION = 'TOGGLE_SHARER_INDICATION';
const SET_WILL_SHARE_TO_BO = 'SET_WILL_SHARE_TO_BO';
const SET_IS_OPTIMIZE_VIDEO_CLIP = 'SET_IS_OPTIMIZE_VIDEO_CLIP';
const SET_IS_VIDEO_SHARE = 'SET_IS_VIDEO_SHARE';
const SET_IS_SHARING_TO_BO = 'SET_IS_SHARING_TO_BO';
const SET_IS_RECEIVE_SHARING_FROM_MAIN_SESSION =
  'SET_IS_RECEIVE_SHARING_FROM_MAIN_SESSION';
const SET_FORCE_STOP_SHARING_IN_BO_NOTIFICATION_VISIBLE =
  'SET_FORCE_STOP_SHARING_IN_BO_NOTIFICATION_VISIBLE';
const SET_IS_LIMIT_SHARING = 'SET_IS_LIMIT_SHARING';
const SET_HAS_NOT_SAME_ACCOUNT_USER = 'SET_HAS_NOT_SAME_ACCOUNT_USER';
const SET_HAS_LIMIT_SHARING_CONFIRMED = 'SET_HAS_LIMIT_SHARING_CONFIRMED';
const SET_HAS_SHARE_BAR_DRAGGED = 'SET_HAS_SHARE_BAR_DRAGGED';
const SET_CAN_SHARE_AUDIO_DURING_THIS_SHARING =
  'SET_CAN_SHARE_AUDIO_DURING_THIS_SHARING';
const UPDATE_RECEIVED_SHARED_AUDIO_MUTED_STATUS =
  'UPDATE_RECEIVED_SHARED_AUDIO_MUTED_STATUS';
const SET_IS_HIDE_MUTED_WHILE_SHARING_TIPS =
  'SET_IS_HIDE_MUTED_WHILE_SHARING_TIPS';
const SET_IS_SHARING_AUDIO = 'SET_IS_SHARING_AUDIO';
const SET_REMOTE_CONTROL_SEND_ID = 'SET_REMOTE_CONTROL_SEND_ID';
const SET_REMOTE_CONTROLLING_USER_SSRC = 'SET_REMOTE_CONTROLLING_USER_SSRC';
const SET_IS_ENABLE_AUTO_DELETE_RC_APP = 'SET_IS_ENABLE_AUTO_DELETE_RC_APP';
const SET_REMOTE_CONTROLLED_USER_LIST = 'SET_REMOTE_CONTROLLED_USER_LIST';
const ADD_RECEIVE_SHARING_CHANNEL = 'ADD_RECEIVE_SHARING_CHANNEL';
const REMOVE_RECEIVE_SHARING_CHANNEL = 'REMOVE_RECEIVE_SHARING_CHANNEL';
const SET_LEAVE_SHARING_AUDIO = 'SET_LEAVE_SHARING_AUDIO';
const SET_SHARING_PERFORMANT_NOTIFICATION =
  'SET_SHARING_PERFORMANT_NOTIFICATION';
const SET_VIDEO_IS_ON_BEFORE_SHARING = 'SET_VIDEO_IS_ON_BEFORE_SHARING';
const SET_HAS_EVER_CLOSED_OLD_VERSION_CANNOT_RECEIVE_SHARED_SOUND_IN_BO_NOTIFICATION =
  'SET_HAS_EVER_CLOSED_OLD_VERSION_CANNOT_RECEIVE_SHARED_SOUND_IN_BO_NOTIFICATION';
const SET_SHAREE_CONTIANER_RECT = 'SET_SHAREE_CONTIANER_RECT';
const SET_IS_SUPPORT_PRESENTER_ANNOTATE_SELF =
  'SET_IS_SUPPORT_PRESENTER_ANNOTATE_SELF';
export {
  SET_SHARER_STATUS,
  TOGGLE_SHARER_PERMISSION_WIDGET,
  TOGGLE_SHARER_POPOVER,
  SET_SHARER_PERMISSION_CODE,
  SET_SHARER_SSRC,
  CLOSE_SHARE,
  PAUSE_SHARER,
  RESUME_SHARER,
  SET_SHARER_CLIENT_RECEIVED_RECT_DIMENSION,
  SET_SHARER_EXTENSION_INSTALLED,
  SET_SHARER_ENCODE_SDK_INITIALIZED,
  SHARE_RESET,
  SET_SHARING_ACTIVE_NODE,
  SET_CURRENT_SHARING_FPS,
  SET_CURRENT_SHARING_RESOLUTION,
  SET_SHARING_CONTENT_IS_ORIGINAL_SIZE,
  SET_REQUESTING_REMOTE_CONTROL,
  SET_WILL_SHARE_TO_BO,
  SET_IS_LIMIT_SHARING,
  SET_HAS_NOT_SAME_ACCOUNT_USER,
  SET_REMOTE_CONTROL_SEND_ID,
  ADD_RECEIVE_SHARING_CHANNEL,
  REMOVE_RECEIVE_SHARING_CHANNEL,
  SET_LEAVE_SHARING_AUDIO,
  SET_SHARING_PERFORMANT_NOTIFICATION,
  SET_VIDEO_IS_ON_BEFORE_SHARING,
  SET_SHAREE_CONTIANER_RECT,
  SET_IS_SUPPORT_PRESENTER_ANNOTATE_SELF,
};

export const {
  setSharerStatus,
  toggleSharerPermissionWidget,
  toggleSharerPopover,
  setSharerPermissionCode,
  setSharerSsrc,
  closeShare,
  pauseSharer,
  resumeSharer,
  setSharerClientReceivedRectDimension,
  setSharerExtensionInstalled,
  setSharerEncodeSdkInitialized,
  shareReset,
  setSharingActiveNode,
  setCurrentSharingFps,
  setCurrentSharingResolution,
  setSharingContentIsOriginalSize,
  setRequestingRemoteControl,
  setRemoteControl,
  setGrabRemoteControl,
  setSharingContentOriginalDimension,
  addRemoteControlUser,
  removeRemoteControlUser,
  updateRemoteControlUser,
  setCopyButtonVisible,
  setRemoteControlClipboard,
  setSdkIsGrabControl,
  setNewSharerToastVisible,
  setShareeDecodeSdkInitialized,
  toggleSharerIndication,
  setWillShareToBo,
  setIsOptimizeVideoClip,
  setIsVideoShare,
  setIsSharingToBo,
  setIsReceiveSharingFromMainSession,
  setForceStopSharingInBoNotificationVisible,
  setIsLimitSharing,
  setHasNotSameAccountUser,
  setHasLimitSharingConfirmed,
  setHasShareBarDragged,
  setCanShareAudioDuringThisSharing,
  updateReceivedSharedAudioMutedStatus,
  setIsHideSharingAudioFeatureTips,
  setIsHideMutedWhileSharingTips,
  setIsSharingAudio,
  setRemoteControlSendId,
  setRemoteControllingUserSsrc,
  setIsEnableAutoDeleteRcApp,
  setRemoteControlledUserList,
  addReceiveSharingChannel,
  removeReceiveSharingChannel,
  setLeaveSharingAudio,
  setSharingPerformantNotification,
  setVideoIsOnBeforeSharing,
  setHasEverClosedOldVersionCannotReceiveSharedSoundInBoNotification,
  setShareeContianerRect,
  toggleSharerSelfClientPreview,
  setJoinRemoteControlTimer,
  setIsSupportPresenterAnnotateSelf,
} = createActions({
  [SET_SHARER_STATUS]: (payload) => payload,
  [TOGGLE_SHARER_PERMISSION_WIDGET]: () => null,
  [TOGGLE_SHARER_POPOVER]: (payload) => payload,
  [SET_SHARER_PERMISSION_CODE]: (payload) => payload,
  [SET_SHARER_SSRC]: (payload) => payload,
  [CLOSE_SHARE]: (payload) => payload,
  [PAUSE_SHARER]: () => null,
  [RESUME_SHARER]: () => null,
  [SET_SHARER_CLIENT_RECEIVED_RECT_DIMENSION]: (payload) => payload,
  [SET_SHARER_EXTENSION_INSTALLED]: (payload) => payload,
  [SET_SHARER_ENCODE_SDK_INITIALIZED]: (payload) => payload,
  [SHARE_RESET]: () => null,
  [SET_SHARING_ACTIVE_NODE]: (payload) => payload,
  [SET_CURRENT_SHARING_FPS]: (payload) => payload,
  [SET_CURRENT_SHARING_RESOLUTION]: (payload) => payload,
  [SET_SHARING_CONTENT_IS_ORIGINAL_SIZE]: (payload) => payload,
  [SET_REQUESTING_REMOTE_CONTROL]: (payload) => payload,
  [SET_REMOTE_CONTROL]: (payload) => payload,
  [SET_GRAB_REMOTE_CONTROL]: (payload) => payload,
  [SET_SHARING_CONTENT_ORIGINAL_DIMENSION]: (payload) =>
    payload && payload.body,
  [ADD_REMOTE_CONTROL_USER]: (payload) => payload,
  [REMOVE_REMOTE_CONTROL_USER]: (payload) => payload,
  [UPDATE_REMOTE_CONTROL_USER]: (payload) => payload,
  [SET_COPY_BUTTON_VISIBLE]: (payload) => payload,
  [SET_REMOTE_CONTROL_CLIPBOARD]: (payload) => payload,
  [SET_SDK_IS_GRAB_CONTROL]: (payload) => payload,
  [SET_NEW_SHARER_TOAST_VISIBLE]: (payload) => payload,
  [SET_SHAREE_DECODE_SDK_INITIALIZED]: (payload) => payload,
  [TOGGLE_SHARER_INDICATION]: (payload) => payload,
  [SET_WILL_SHARE_TO_BO]: (payload) => payload,
  [SET_IS_OPTIMIZE_VIDEO_CLIP]: (payload) => payload,
  [SET_IS_VIDEO_SHARE]: (payload) => payload,
  [SET_IS_SHARING_TO_BO]: (payload) => payload,
  [SET_IS_RECEIVE_SHARING_FROM_MAIN_SESSION]: (payload) => payload,
  [SET_FORCE_STOP_SHARING_IN_BO_NOTIFICATION_VISIBLE]: (payload) => payload,
  [SET_IS_LIMIT_SHARING]: (payload) => payload,
  [SET_HAS_NOT_SAME_ACCOUNT_USER]: (payload) => payload,
  [SET_HAS_LIMIT_SHARING_CONFIRMED]: (payload) => payload,
  [SET_HAS_SHARE_BAR_DRAGGED]: (payload) => payload,
  [SET_CAN_SHARE_AUDIO_DURING_THIS_SHARING]: (payload) => payload,
  [UPDATE_RECEIVED_SHARED_AUDIO_MUTED_STATUS]: (payload) => payload,
  [SET_IS_HIDE_MUTED_WHILE_SHARING_TIPS]: (payload) => payload,
  [SET_IS_SHARING_AUDIO]: (payload) => payload,
  [SET_REMOTE_CONTROL_SEND_ID]: (payload) => payload,
  [SET_REMOTE_CONTROLLING_USER_SSRC]: (payload) => payload,
  [SET_IS_ENABLE_AUTO_DELETE_RC_APP]: (payload) => payload,
  [SET_REMOTE_CONTROLLED_USER_LIST]: (payload) => payload,
  [ADD_RECEIVE_SHARING_CHANNEL]: (payload) => payload,
  [REMOVE_RECEIVE_SHARING_CHANNEL]: (payload) => payload,
  [SET_LEAVE_SHARING_AUDIO]: (payload) => payload,
  [SET_SHARING_PERFORMANT_NOTIFICATION]: (payload) => payload,
  [SET_VIDEO_IS_ON_BEFORE_SHARING]: (payload) => payload,
  [SET_HAS_EVER_CLOSED_OLD_VERSION_CANNOT_RECEIVE_SHARED_SOUND_IN_BO_NOTIFICATION]:
    (payload) => payload,
  [SET_SHAREE_CONTIANER_RECT]: (payload) => payload,
  TOGGLE_SHARER_SELF_CLIENT_PREVIEW: (payload) => payload,
  SET_JOIN_REMOTE_CONTROL_TIMER: (payload) => payload,
  [SET_IS_SUPPORT_PRESENTER_ANNOTATE_SELF]: (payload) => payload,
});
