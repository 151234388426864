import cookie from 'react-cookie';
import qs from 'qs';
import meetingConfig from 'meetingConfig';
import axios from 'axios';
import _ from 'lodash';
import { createAction } from 'redux-actions';

import { isHost } from '../global/service/user-types';
import {
  hideJoinLoading,
  isSupportWaitingRoomWithoutFailoverFlow,
  transformRecordingStatus,
} from '../global/service';

import * as types from '../constants/MeetingActionTypes';
import * as socketActionTypes from '../constants/SocketActionTypes';
import * as errorTypes from '../constants/ErrorTypes';
import * as leaveReasonType from '../constants/LeaveReasons';
import {
  attendeeListReset,
  avLeaveMeeting,
  closeSocket,
  saveWebSDKUserThunk,
  sendSocketMessage,
} from './SocketActions';
import { globalVariable } from '../global/global-variable';
import {
  decodeBase64,
  encodeBase64,
  iText,
  removeCookie,
} from '../global/util';
import * as socketEventTypes from '../constants/ZoomSocketEventTypes';
import * as userRoleConstants from '../constants/UserRoles';
import {
  errorLog,
  infoLog,
  successLog,
  warningLog,
} from '../global/web-client-logger';
import { forYourGiftTypes } from '../features/dialog/enum';
import { emptyUser } from '../constants/DefaultModules';
import {
  setBoFactorMeetingStartTime,
  setIsSupportHugeBo,
} from '../features/breakout-room/redux/bo-room-action';
import {
  chatReset,
  setWaitingRoomChatFlag,
} from '../features/chat/redux/chat-action';
import { setMmrSaveOrderFlag } from '../features/video/redux/video-action';

import { wcToast } from '../global/components/widget/toast/wc-toast';
import { isBoMainWebsocket } from '../features/breakout-room/utils';
import {
  JOIN_MEETING_POLICY,
  LOCALSTORAGE_KEYS,
  RESET_WEBSOCKET_EVENTS,
  SESSIONSTORAGE_KEYS,
} from '../global/constant';
import { easyStore, storeType } from '../global/easy-store';
import { setReportDomain } from '../features/security/redux/security-action';
import {
  PWAMeetingEvent,
  sendJoinSuccessMsgToPWA,
  sendMsgToPWA,
} from '../global/pwa-integration';
import { sendPollFailoverToken } from '../features/poll/redux/poll-thunk-action';
import {
  forceUpdateMeetingEndHandler,
  freeMeetingEndHandler,
} from '../features/dialog/service';
import {
  setCannotJoinMeetingDialogVisible,
  setConfEndedDialogVisible,
  setConfFullDialogVisible,
  setConfLockedDialogVisible,
  setConnectErrorDialogVisible,
  setExpelInfoDialogVisible,
  setForYourGiftDialogVisible,
  setJoinVoipTimeoutDialogVisible,
  setReconnectDialogVisible,
} from '../features/dialog/redux/dialog-action';
import { setSharePronounNotificationVisible } from '../reducers/MeetingUIReducer';
import { setSharingActiveNode } from '../features/sharing/redux/sharing-action';
import { initBackgroundStatusThunk } from '../features/settings/redux/settings-thunk-action';
import {
  setShowReactionToolBar,
  setShowUserProfileIcon,
} from '../features/settings/redux/settings-action';
import { isCurrentCCEditorSelector } from '../features/new-live-transcription/redux/new-LT-selector';
import {
  setIsLeave,
  setKickedByHost,
  setSurveyUrl,
} from '../features/feedback/redux/feedback-actions';
import { FAILOVER_REASON } from '../global/enum';
import { asignNewCCType } from '../features/dialog/redux/dialog-thunk-action';
import {
  performanceMeasure,
  PERFORMANCE_MARK,
  performanceMark,
  measureDuration,
  sendToRwgPerformanceData,
} from '../performance';
import { changePanelViewState } from '../global/redux/set-panel-action';
import { getJoinMeetingLog } from '../global';
import { makeLogger } from '../global/logger';
import { trackingId } from '../global/logger/laplace';
import {
  SocketType,
  WCSockets,
} from '../tasks/global/websocket/websocket-utils';
import { shouldHideUILoadingWhenJoinMeeting } from '../global/service/hide-ui-loading';
import { createCollectorThunk } from '../global/utils/create-collector-thunk';
import { isWebinar } from '../global/service/meeting-types';
import {
  sendDeviceTypeMonitorLog,
  sendSocketMonitorLog,
} from '../global/redux/thunk-action/send-socket-monitor-log';
import { exitConf } from '../global/redux/thunk-action/end-meeting-request';
import { pipInstance } from '../global/hooks/use-picture-in-picture';
import { sendRejoinRequest } from '../global/service/rwg';
import { sendRWGMessage } from '../tasks/global/websocket/webclient-websocket';
import * as AVNotifyMediaSDKTypes from '../constants/AVNotifyMediaSDKTypes';
import { setNewWaitingRoomFlowLoading, setOnHold } from './MeetingUIActions';
import { closeAllRefDialog } from '../features/dialog/service/command-dialog';
import { EventTimeoutTracker } from '../global/timeout-tracker/event-timeout-tracker';
import eventBus from '../global/event-bus';
import {
  blackBoxEmitEvent,
  BlackBoxEvent,
} from '../global/service/black-box-service';
// import { xmppServer } from '../components/XMPPSocket';

const logger = makeLogger(['Join Meeting Flow']);

function dataHas(data, key) {
  // eslint-disable-next-line no-prototype-builtins
  return data.hasOwnProperty(key);
}

export function setCurrentUserRole(data) {
  return { type: types.SET_CURRENT_USER_ROLE, data };
}
export function hideLoading(data) {
  if (!data) {
    blackBoxEmitEvent(BlackBoxEvent.inMeeting_hideLoading);
  }
  return { type: types.HIDE_LOADING, data };
}

export function startJoinMeeting(data) {
  return { type: types.JOIN_MEETING_START, data };
}

export function joinMeetingFailed(data) {
  sendMsgToPWA(PWAMeetingEvent.FAIL);
  if (data) {
    return { type: types.JOIN_MEETING_FAILED, errorCode: data.body.res };
  }
  return { type: types.JOIN_MEETING_FAILED };
}

export const setIsNowFailoverInProgress = (isNowFailoverInProgress) => ({
  type: types.SET_IS_NOW_FAILOVER_IN_PROGRESS,
  isNowFailoverInProgress,
});

const changeDocumentTitle = _.debounce((meetingTopic, locked) => {
  document.title = `${
    locked ? `(${iText('Locked', 'apac.wc_lock_meeting_status')}) ` : ''
  }${meetingTopic}`;
}, 1000);
export function setMeetingTopicThunk(meetingTopic) {
  return (dispatch, getState) => {
    const {
      meeting: { bLock },
    } = getState();
    changeDocumentTitle(meetingTopic, bLock);
    dispatch({
      type: types.SET_MEETING_TOPIC,
      meetingTopic,
    });
  };
}

export const setDisableAudioQos = (disableAudioQos) => {
  return {
    type: types.SET_DISABLE_AUDIO_QOS,
    payload: disableAudioQos,
  };
};

export const setDisableVideoQos = (disableVideoQos) => {
  return {
    type: types.SET_DISABLE_VIDEO_QOS,
    payload: disableVideoQos,
  };
};

export function setMeetingToken(data) {
  const {
    body: { meetingtoken },
  } = data;
  if (meetingtoken) {
    easyStore.easySet(
      SESSIONSTORAGE_KEYS.webClient_meetingToken,
      `${meetingtoken || ''}`,
      storeType.sessionStorage,
    );
  }
}

export function setAudioBridgeToken(data) {
  return { type: types.SET_AUDIO_BRIDGE_TOKEN, data };
}
export function setAudioBridgeLocalFlag(data) {
  return { type: types.SET_AUDIO_BRIDGE_LOCAL_FLAG, data };
}

export function setConfReady(data) {
  return { type: types.SET_CONF_READY, data };
}

export function setAudioSessionReady(data) {
  return { type: types.SET_AUDIO_SESSION_READY, data };
}

export function setVideoSessionReady(data) {
  return { type: types.SET_VIDEO_SESSION_READY, data };
}

export function setShareSessionReady(data) {
  return { type: types.SET_SHARE_SESSION_READY, data };
}
export function joinMeetingSuccess(dispatch, data) {
  return (dispatch, getState) => {
    dispatch({ type: types.SET_JOIN_MEETING_RES, res: data?.body?.res });
    if (errorTypes.CONF_SUCCESS === data.body.res) {
      const {
        body: {
          elapsed,
          hugeBO,
          reportDomain,
          confID,
          disableAqos,
          disableVqos,
          // meetingtoken,
          mmrFeature = 0,
          mmrFeatureExStr = '0',
          ABtoken,
          supportLocalAB,
          conID,
          userID,
        },
      } = data;
      const {
        socketStatus: { commandSocketUrl },
      } = getState();
      performanceMark(PERFORMANCE_MARK.inMeeting_RWG_success, {
        detail: commandSocketUrl,
      });
      if (ABtoken) {
        dispatch(setAudioBridgeToken(ABtoken));
      }
      dispatch(setAudioBridgeLocalFlag(supportLocalAB));
      dispatch({ type: types.JOIN_MEETING_SUCCESS, data });
      dispatch(hideLoading(false));
      if (shouldHideUILoadingWhenJoinMeeting()) {
        hideJoinLoading();
      }
      performanceMark(PERFORMANCE_MARK.inMeeting_hideLoading);
      sendJoinSuccessMsgToPWA();
      // wait 1s after join meeting success, if still not put on hold, take it as hasBeenInMeeting true
      globalVariable.hasBeenInMeetingTimer = setTimeout(() => {
        easyStore.easySet(
          SESSIONSTORAGE_KEYS.hasBeenInMeeting,
          true,
          storeType.sessionStorage,
        );
      }, 1000);
      dispatch(setIsNowFailoverInProgress(false));
      /**
       * fix throttle timer clean issue on failover
       */
      dispatch(attendeeListReset());
      dispatch(setSharingActiveNode({}));
      dispatch(
        setBoFactorMeetingStartTime({
          elapsed,
          joinBoLocalTime: new Date().getTime() / 1000,
        }),
      );
      dispatch(
        setIsSupportHugeBo(
          hugeBO && meetingConfig.meetingOptions.isBO100Enabled,
        ),
      );
      dispatch(setWaitingRoomChatFlag(mmrFeature));
      dispatch(setMmrSaveOrderFlag(mmrFeatureExStr));
      // if (meetingtoken) {
      //   easyStore.easySet(
      //     SESSIONSTORAGE_KEYS.webClient_meetingToken,
      //     `${meetingtoken || ''}`,
      //     storeType.sessionStorage,
      //   );
      // }
      if (disableAqos !== undefined) {
        dispatch(setDisableAudioQos(disableAqos));
      }
      if (disableVqos !== undefined) {
        dispatch(setDisableVideoQos(disableVqos));
      }
      if (reportDomain) {
        dispatch(setReportDomain(reportDomain));
      }
      dispatch(recordJoinMeetingMonitorLog());
      dispatch(sendDeviceTypeMonitorLog());
      const userId = data.body.userID;
      const participantId = data.body.participantIDStr;
      const zoomId = data.body.zoomID;

      const confCookie = `${userId}#${participantId}#${zoomId}#${
        meetingConfig.userEmail
      }#${getState().meeting.userName}`;
      // eastStore start point
      easyStore.easySet('userId', data.body.userID, storeType.sessionStorage);
      easyStore.easySet(
        'participantId',
        participantId,
        storeType.sessionStorage,
      );
      easyStore.easySet('zoomId', zoomId, storeType.sessionStorage);
      easyStore.easySet(
        'userEmail',
        meetingConfig.userEmail,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'userName',
        getState().meeting.userName,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'isOnHold',
        Number(getState().meetingUI.isOnHold),
        storeType.sessionStorage,
      );
      easyStore.easySet(
        SESSIONSTORAGE_KEYS.inMeeting,
        true,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'JOIN_MEETING_FLOW_JOIN_BO',
        null,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'JOIN_MEETING_FLOW_LEAVE_BO',
        null,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'JOIN_MEETING_FLOW_PROMOTE',
        null,
        storeType.sessionStorage,
      );
      easyStore.easySet(
        'JOIN_MEETING_FLOW_DEPROMOTE',
        null,
        storeType.sessionStorage,
      );
      // here we need set a new localstorage key so that we can get  the storage key change event
      // in globalEventListener.js
      if (!isBoMainWebsocket(commandSocketUrl)) {
        localStorage.setItem(
          encodeBase64(LOCALSTORAGE_KEYS.webClient_meetingUqiueId),
          encodeBase64(`${confID};${window.pageUUID}`),
        );
      }

      dispatch(sendPollFailoverToken());
      if (isSupportWaitingRoomWithoutFailoverFlow()) {
        globalVariable.avSocket.sendSocket(
          AVNotifyMediaSDKTypes.USER_HOLD_STATUS_SWITCH,
          {
            hold: false,
            userid: userID,
          },
        );
      }

      const cookieTime = new Date();
      cookieTime.setTime(cookieTime.getTime() + 2 * 60 * 60 * 1000);
      cookie.save('wc_info', confCookie, { expires: cookieTime, path: '/' });
      /**
       * if user failover, cannot get the avSocket callback,
       * so send the `STOP_PRESENTING` when join meeting
       */
      sendMsgToPWA(PWAMeetingEvent.STOP_PRESENTING);
      if (data.body.meetingTopic) {
        dispatch(setMeetingTopicThunk(decodeBase64(data.body.meetingTopic)));
      }

      dispatch(initBackgroundStatusThunk());
      dispatch(sendSocketMonitorLog(`L_ID ${trackingId}`));
      // if vb disable form web settings, add the log from web client
      // if vb enable by user, add logs from media sdk
      if (!meetingConfig.meetingOptions.isEnableWebclientVB) {
        dispatch(
          sendSocketMonitorLog(
            'isWebEnabled:0, isLocalEnabled:0, isSmartBkgnd:1, bkgType:0',
          ),
        );
      }

      dispatch(
        setShowUserProfileIcon(
          easyStore.easyGet('showUserProfileIcon') ?? true,
        ),
      );
      dispatch(
        setShowReactionToolBar(
          easyStore.easyGet('showReactionToolBar') ?? true,
        ),
      );
      if (data.body.feedbackUrl) {
        dispatch(setSurveyUrl(data.body.feedbackUrl));
      }
      if (globalVariable.performanceHandler !== 'done') {
        globalVariable.performanceHandler = setTimeout(() => {
          globalVariable.performanceHandler = 'done';
          performanceMeasure((indicatorData) => {
            const performanceData = sendToRwgPerformanceData(indicatorData);
            Object.keys(performanceData).forEach((key) => {
              dispatch(
                sendSocketMessage({
                  evt: socketEventTypes.WS_CONF_WRITE_RWG_MONITOR_LOG_REQ,
                  body: {
                    data: `,${key},${conID},${performanceData[key].join(',')}`,
                  },
                }),
              );
            });
          });
        }, 45000);
      }

      // init options that the 'see myself as active'
      const enableSeeSelf = easyStore.easyGet(
        LOCALSTORAGE_KEYS.webClient_enableSeeSelfActiveWhenSpeaking,
      );
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_VIDEO_SEE_MYSELF_AS_ACTIVE,
          body: {
            bSeeMySelf: !!enableSeeSelf,
          },
        }),
      );
      return null;
    }
    if (errorTypes.CONF_FAIL_CONF_USER_FULL === data.body.res) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(
        setConfFullDialogVisible({ visible: true, lmURL: data.body.lmURL }),
      );
    } else if (errorTypes.CONF_FAIL_CONFLOCKED === data.body.res) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(setConfLockedDialogVisible(true));
    } else if (errorTypes.CONF_FAIL_MEETING_OVER === data.body.res) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(setConfEndedDialogVisible(true));
    } else if (errorTypes.CONF_RESULT_MMR_IB_REJECT === data.body.res) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(
        setCannotJoinMeetingDialogVisible({
          visible: true,
          errorCode: data.body.res,
        }),
      );
    } else if (
      errorTypes.CONF_FAIL_RESULT_MMR_CONF_PARTICIPANT_EXISTS === data.body.res
    ) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(
        setCannotJoinMeetingDialogVisible({
          visible: true,
          errorCode: data.body.res,
        }),
      );
    } else if (
      errorTypes.CONF_FAIL_JOIN_WEBINAR_RESTRICTION === data.body.res
    ) {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed());
      dispatch(setReconnectDialogVisible(false));
      dispatch(
        setCannotJoinMeetingDialogVisible({
          visible: true,
          errorCode: data.body.res,
        }),
      );
    } else {
      dispatch(hideLoading(false));
      dispatch(joinMeetingFailed(data));
      dispatch(
        setReconnectDialogVisible({
          visible: true,
          errorCode: data.body.res,
          body: data.body,
        }),
      );
      dispatch(closeSocket(false));
    }
    return null;
  };
}

export function setConfAttributeIndication(data, isBoSecondWSChannel) {
  return (dispatch, getState) => {
    const meeting = getState().meeting;
    const message = {
      bLock: meeting.bLock,
      bRecord: meeting.bRecord,
      bCanUnmute: meeting.bCanUnmute,
      bHoldUponEntry: meeting.bHoldUponEntry,
      bAllowShowCount: meeting.bAllowShowCount,
      viewOnly: meeting.viewOnly,
      listenOnlyPhone: meeting.listenOnlyPhone,
      bMutedUponEntry: meeting.bMutedUponEntry,
      chatPriviledge: meeting.chatPriviledge,
      panelistChatPriviledge: meeting.panelistChatPriviledge,
      bAllowAttendeeChat: meeting.bAllowAttendeeChat,
      bCCEditorAssigned: meeting.closedCaption.bCCEditorAssigned,
      bCanUnmuteVideo: meeting.bCanUnmuteVideo,
      bAllowRaiseHand: meeting.bAllowRaiseHand,
      bBroadcast: meeting.bBroadcast,
      bAllowAttendeeRename: meeting.bAllowAttendeeRename,
      bAllowMessageFeedbackNotify: meeting.bAllowMessageFeedbackNotify,
      bAllowPlayChimeForEnterOrExit: meeting.bAllowPlayChimeForEnterOrExit,
      bHasRMC: meeting.bHasRMC,
      bHasAST: meeting.bHasAST,
      bIbDisableShare: meeting.bIbDisableShare,
      bIbDisableChat: meeting.bIbDisableChat,
      bIbDisableRecording: meeting.bIbDisableRecording,
      bIbDisableFileTransfer: meeting.bIbDisableFileTransfer,
      encryptKey: meeting.encryptKey,
      meetingTopic: meeting.meetingTopic,
      bNoHostTimeOut: meeting.bNoHostTimeOut,
      bAllowedAvatar: meeting.bAllowedAvatar,
      bFollowHostVideo: meeting.bFollowHostVideo,
      bAllowPanelistVote: meeting.bAllowPanelistVote,
      bLsUnencrytped: meeting.bLsUnencrytped,
      confStatus: meeting.confStatus,
      freeNotification: meeting.freeNotification,
      freeDuration: meeting.freeDuration,
      enableAutomicRecordingCloud: meeting.enableAutomicRecordingCloud,
      disAllowClientStopAutoCmr: meeting.disAllowClientStopAutoCmr,
      liveTransEngineType: meeting.liveTransEngineType,
      VideoHd: meeting.VideoHd,
    };
    const { restrictFeatures } = meeting;
    const {
      socketStatus: { commandSocketUrl },
      meeting: { meetingTopic },
    } = getState();
    const shouldSetCurrentAttr =
      !isBoMainWebsocket(commandSocketUrl) || isBoSecondWSChannel;

    if (dataHas(data.body, 'enableAutomicRecordingCloud')) {
      message.enableAutomicRecordingCloud =
        data.body.enableAutomicRecordingCloud;
    }

    if (dataHas(data.body, 'disAllowClientStopAutoCmr')) {
      message.disAllowClientStopAutoCmr = data.body.disAllowClientStopAutoCmr;
    }

    if (dataHas(data.body, 'bHasRMC')) {
      message.bHasRMC = data.body.bHasRMC;
    }

    if (dataHas(data.body, 'bHasAST')) {
      message.bHasAST = data.body.bHasAST;
    }

    if (dataHas(data.body, 'bLock')) {
      message.bLock = data.body.bLock;
      changeDocumentTitle(meetingTopic, message.bLock);
    }

    if (dataHas(data.body, 'cmrServerStatus')) {
      message.bRecord = transformRecordingStatus(data.body.cmrServerStatus);
    }

    if (dataHas(data.body, 'bCanUnmute')) {
      message.bCanUnmute = data.body.bCanUnmute;
    }

    if (dataHas(data.body, 'bHoldUponEntry')) {
      message.bHoldUponEntry = data.body.bHoldUponEntry;
    }

    if (dataHas(data.body, 'bAllowShowCount')) {
      message.bAllowShowCount = data.body.bAllowShowCount;
    }

    if (dataHas(data.body, 'viewOnly')) {
      message.viewOnly = data.body.viewOnly;
    }

    if (dataHas(data.body, 'listenOnlyPhone')) {
      message.listenOnlyPhone = data.body.listenOnlyPhone;
    }

    if (dataHas(data.body, 'bMutedUponEntry')) {
      message.bMutedUponEntry = data.body.bMutedUponEntry;
    }

    if (dataHas(data.body, 'chatPriviledge')) {
      message.chatPriviledge = data.body.chatPriviledge;
    }

    if (dataHas(data.body, 'panelistChatPriviledge')) {
      message.panelistChatPriviledge = data.body.panelistChatPriviledge;
    }

    if (dataHas(data.body, 'bCCEditorAssigned')) {
      message.bCCEditorAssigned = data.body.bCCEditorAssigned;
    }

    if (dataHas(data.body, 'bAllowAttendeeChat')) {
      message.bAllowAttendeeChat = data.body.bAllowAttendeeChat;
    }

    if (dataHas(data.body, 'bCanUnmuteVideo')) {
      message.bCanUnmuteVideo = data.body.bCanUnmuteVideo;
    }

    if (dataHas(data.body, 'bAllowRaiseHand')) {
      message.bAllowRaiseHand = data.body.bAllowRaiseHand;
    }
    // if current websocket url is connectting bo meeting,we need't set this attr
    // and we can set this attr when the message is from the second ws channel in bo
    if (dataHas(data.body, 'bAllowAttendeeRename') && shouldSetCurrentAttr) {
      message.bAllowAttendeeRename = data.body.bAllowAttendeeRename;
    }

    if (dataHas(data.body, 'bBroadcast')) {
      message.bBroadcast = data.body.bBroadcast;
    }

    if (dataHas(data.body, 'bAllowMessageFeedbackNotify')) {
      message.bAllowMessageFeedbackNotify =
        data.body.bAllowMessageFeedbackNotify;
    }

    if (dataHas(data.body, 'bChime')) {
      message.bAllowPlayChimeForEnterOrExit = data.body.bChime;
    }

    if (dataHas(data.body, 'bIbDisableShare')) {
      message.bIbDisableShare = data.body.bIbDisableShare;
    }

    if (dataHas(data.body, 'bIbDisableChat')) {
      message.bIbDisableChat = data.body.bIbDisableChat;
    }

    if (dataHas(data.body, 'bIbDisableRecording')) {
      message.bIbDisableRecording = data.body.bIbDisableRecording;
    }

    if (dataHas(data.body, 'bIbDisableFileTransfer')) {
      message.bIbDisableFileTransfer = data.body.bIbDisableFileTransfer;
    }

    if (dataHas(data.body, 'encryptKey')) {
      message.encryptKey = data.body.encryptKey;
    }

    if (dataHas(data.body, 'topic')) {
      message.meetingTopic = decodeBase64(data.body.topic);
    }

    if (dataHas(data.body, 'bNoHostTimeOut')) {
      message.bNoHostTimeOut = data.body.bNoHostTimeOut;
    }

    if (dataHas(data.body, 'bAllowedAvatar')) {
      message.bAllowedAvatar =
        data.body.bAllowedAvatar &&
        !restrictFeatures[JOIN_MEETING_POLICY.HIDE_AVATAR];
    }

    if (dataHas(data.body, 'bFollowHostVideo')) {
      message.bFollowHostVideo = data.body.bFollowHostVideo;
    }

    if (dataHas(data.body, 'bAllowPanelistVote')) {
      message.bAllowPanelistVote = data.body.bAllowPanelistVote;
    }

    if (dataHas(data.body, 'bLsUnencrytped')) {
      message.bLsUnencrytped = data.body.bLsUnencrytped;
    }

    if (dataHas(data.body, 'confStatus')) {
      message.confStatus = data.body.confStatus;
    }

    if (dataHas(data.body, 'freeNotification')) {
      message.freeNotification = data.body.freeNotification;
    }

    if (dataHas(data.body, 'freeDuration')) {
      message.freeDuration = data.body.freeDuration;
    }

    if (dataHas(data.body, 'LiveTransEngineType')) {
      message.liveTransEngineType = data.body.LiveTransEngineType;
    }

    if (dataHas(data.body, 'VideoHd')) {
      message.VideoHd = data.body.VideoHd;
    }
    return dispatch({ type: types.WS_CONF_ATTRIBUTE_INDICATION, message });
  };
}

export function setCallOutCountry2(data) {
  return { type: types.SELECT_CALLOUTCOUNTRY2, data };
}

export function getHostChangeRes(data) {
  return { type: types.WS_CONF_HOST_CHANGE_INDICATION, data };
}

// if the host or the co-host is in the room,he is a moderator
export function setModerator(isModerator) {
  return { type: types.WS_CONF_MODERATOR_CHANGE_INDICATION, isModerator };
}

export function getLockRes(data) {
  if (data.body.result === 0) {
    const lockStatus = data.body.bLock ? 'success' : '';
    const {
      body: { bLock },
    } = data;
    if (bLock) {
      wcToast({
        text: iText(
          'You’ve locked the meeting. No one else can join.',
          'apac.wc_meeting.lock_notification',
        ),
        type: 'notify',
        aliveTime: 3,
      });
    } else {
      wcToast({
        text: iText(
          'You’ve unlocked the meeting. New participants can join.',
          'apac.wc_meeting.unlock_notification',
        ),
        type: 'notify',
        aliveTime: 3,
      });
    }
    return {
      type: types.LOCK_MEETING_SUCCESS,
      data: { bLock: data.body.bLock, lockStatus },
    };
  }
  return null;
}

export function setInvitePhoneNumber(data) {
  return { type: types.UPDATE_INVITE_PHONENUMBER, data };
}

export function setInviteName(data) {
  return { type: types.UPDATE_INVITE_NAME, data };
}

export function setRenameValue(data) {
  return { type: types.UPDATE_RENAME__VALUE, data };
}

export function openWindow(data) {
  return (dispatch) => {
    const { windowName, isOpen } = data;
    switch (windowName) {
      case 'ClosedCaption':
        dispatch({ type: types.CLOSED_CAPTION_OPENWINDOW, isOpen });
        break;
      case 'AudioMerge':
        dispatch({
          type: types.SET_MERGE_AUDIO_VISIBLE,
          data: { visible: isOpen },
        });
        break;
      // case 'ParticipantsList':
      //   dispatch({ type: types.PARTICIPANTLIST_OPENWINDOW, isOpen });
      //   break;
      // case 'Chat':
      //   dispatch(chatWindowOpen(isOpen));
      //   break;
      // case 'LiveTranscription':
      //   dispatch(transcriptWindowOpen(isOpen));
      //   break;
      default: {
        dispatch(changePanelViewState(windowName, isOpen));
      }
    }
  };
}

export function setErrorCode(data) {
  return { type: types.SET_ERRORCODE, data };
}

export function setIsWaitingRoomLoading(data) {
  return { type: types.SET_IS_WAITING_ROOM_LOADING, data };
}

export function setIsRejoinLoading(data) {
  return { type: types.SET_IS_REJOIN_LOADING, data };
}

export function setArchivingInfo(data) {
  return { type: types.SET_ARCHIVE_INFO, data };
}

export function setNDIInfo(data) {
  return { type: types.SET_DNI_INFO, data };
}

export function setAppSignalInfo(data) {
  return { type: types.SET_APP_SIGNAL_INFO, data };
}

const removeSensitiveCookies = () => {
  const sensitiveCookies = [
    'wc_dn',
    'wc_join',
    'wc_el',
    'wc_info',
    'wc_epk',
    'wc_webinar',
  ];
  sensitiveCookies.forEach((cookie) => {
    removeCookie(cookie);
  });
};

function resetAfterNewWaitingRoomJoin() {
  return (dispatch) => {
    warningLog(
      '>>>>>>>>> Reset Webclient after new waiting room flow join >>>>>>>>>>>>>',
    );
    eventBus.emit('reset-all-redux-store', {
      dispatch,
      type: RESET_WEBSOCKET_EVENTS.NEW_WAITING_ROOM_JOIN,
    });
    closeAllRefDialog();
    easyStore.failOver();
    EventTimeoutTracker.getInstance().failOver();
  };
}

function waitingRoomFailoverThunk() {
  return (dispatch, getState) => {
    const {
      meeting: { isNowFailoverInProgress },
    } = getState();
    if (isNowFailoverInProgress) {
      return;
    }
    easyStore.easySet('isOnHold', false, storeType.sessionStorage);
    dispatch(chatReset(true));
    dispatch(setIsWaitingRoomLoading(true));
    if (isSupportWaitingRoomWithoutFailoverFlow()) {
      dispatch(resetAfterNewWaitingRoomJoin());
      dispatch(setNewWaitingRoomFlowLoading(true));
      dispatch(setOnHold(false));
      globalVariable.whiteboardSdk?.destroy();
      globalVariable.whiteboardSdk = null;
      sendRejoinRequest(sendRWGMessage);
    } else {
      dispatch(closeSocket(true));
    }
    easyStore.easySet(
      'waitingRoomWithFailOver',
      !isSupportWaitingRoomWithoutFailoverFlow(),
      storeType.memory,
    );
  };
}
export function endMeetingJob(data) {
  return (dispatch, getState) => {
    const {
      meeting: { isOriginhost },
      meetingUI: { isOnHold },
    } = getState();
    // close PIP window
    pipInstance?.closePipWin();
    if (
      !(
        isSupportWaitingRoomWithoutFailoverFlow() &&
        leaveReasonType.LEAVESUBREASON_WAITINGROOMFAILOVER ===
          data.body.subReason
      )
    ) {
      dispatch(avLeaveMeeting());
    }

    if (dataHas(data.body, 'reason')) {
      infoLog(`Leave reason: ${data.body.reason}`);
      dispatch(setConnectErrorDialogVisible(false));
      dispatch(setJoinVoipTimeoutDialogVisible(false));
      if (
        data.body.reason !== leaveReasonType.LEAVEREASON_RECONNECT &&
        data.body.reason !== leaveReasonType.LEAVE_REASON_DUPLICATE_SESSION
      ) {
        removeSensitiveCookies();
      }
      switch (data.body.reason) {
        case leaveReasonType.LEAVEREASON_RECONNECT: {
          if (
            leaveReasonType.LEAVESUBREASON_WAITINGROOMFAILOVER ===
              data.body.subReason &&
            isOnHold
          ) {
            const { wrInfo } = meetingConfig;
            const { waitingRoomAdditionDelay } = JSON.parse(wrInfo || '{}');
            const enterWRTime = easyStore.easyGet('enterWRTime');
            let timeOffset = 0;
            let shouldDelayEnterWR = false;

            if (waitingRoomAdditionDelay && enterWRTime) {
              timeOffset =
                waitingRoomAdditionDelay * 1000 - (Date.now() - enterWRTime);
              shouldDelayEnterWR = timeOffset > 0;
            }
            if (!shouldDelayEnterWR) {
              dispatch(waitingRoomFailoverThunk());
            } else {
              //prevent ZoomCommandSocket handleOnclose triggered by socket close event from RWG
              logger.log(
                'Force closing RWG WebSocket because of waiting room failover',
              );
              if (!isSupportWaitingRoomWithoutFailoverFlow()) {
                WCSockets.forceClose(SocketType.RWG);
              }

              setTimeout(() => {
                dispatch(waitingRoomFailoverThunk());
              }, timeOffset);
            }
          } else {
            dispatch(closeSocket(true));
          }
          break;
        }
        case leaveReasonType.LEAVEREASON_KICKEDBYHOST: {
          dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
          dispatch(setExpelInfoDialogVisible(true));
          dispatch(setKickedByHost(true));
          dispatch(closeSocket(false));
          cookie.remove('wc_info', { path: '/' });
          sendMsgToPWA(PWAMeetingEvent.REMOVED_BY_HOST);
          break;
        }
        case leaveReasonType.LEAVEREASON_ENDBYHOST:
        case leaveReasonType.LEAVE_REASON_ENDBYNONE:
        case leaveReasonType.LEAVE_REASON_ENDBYADMIN:
        case leaveReasonType.LEAVEREASON_ENDBYHOSTSTARTANOTHERMEETING:
        case leaveReasonType.LEAVE_REASON_RSGW_END_SIMU_LIVE:
        case leaveReasonType.LEAVE_REASON_RSGW_TIMEOUT: {
          const isLeaveMeetingRightNow = freeMeetingEndHandler(
            dispatch,
            isOriginhost,
          );
          if (isLeaveMeetingRightNow) {
            dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
            dispatch(
              setConfEndedDialogVisible({
                visible: true,
                type: data.body.reason,
              }),
            );
            if (data.body.reason === leaveReasonType.LEAVEREASON_ENDBYHOST) {
              sendMsgToPWA(PWAMeetingEvent.END_BY_HOST);
            }
          }
          break;
        }
        case leaveReasonType.LEAVE_REASON_ARCHIVE_FAIL: {
          dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
          dispatch(
            setConfEndedDialogVisible({
              visible: true,
              type: data.body.reason,
            }),
          );
          break;
        }
        case leaveReasonType.LEAVE_REASON_DUPLICATE_SESSION:
        case leaveReasonType.LEAVEREASON_USERREQUEST: {
          // attendee in webinar and no-host in meeting can participant survey
          // eslint-disable-next-line @babel/new-cap
          const { isLeave, isHungUpRemotely, isFailover } = data.body;
          const isLeaveMeetingRightNow =
            freeMeetingEndHandler(dispatch, isOriginhost) &&
            forceUpdateMeetingEndHandler(dispatch);
          dispatch(
            exitConf({
              isLeave,
              isLeaveMeetingRightNow,
              isHungUpRemotely,
              isFailover,
            }),
          );
          dispatch(setIsLeave(isLeave));
          break;
        }
        case leaveReasonType.LEAVEREASON_FREEMEETINGTIMEOUT: {
          dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
          dispatch(
            setForYourGiftDialogVisible({
              visible: true,
              type: isOriginhost
                ? forYourGiftTypes.THIRD_END_FOR_HOST
                : forYourGiftTypes.THIRD_END_FOR_OTHERS,
              isModal: true,
            }),
          );
          break;
        }
        case leaveReasonType.LEAVE_REASON_MEETINGTRANSFER: {
          dispatch({ type: types.IS_FAILOVER_TO_SOCKET_CLOSE, data: false });
          logger.log('Force closing RWG WebSocket because of meeting transfer');
          WCSockets.forceClose(SocketType.RWG);
          easyStore.removeSessionStorage();
          sendMsgToPWA(PWAMeetingEvent.LEAVE);
          break;
        }
        default:
          break;
      }
    } else {
      removeSensitiveCookies();
    }
  };
}

export function roomSystemPairing(pairingCode) {
  infoLog(`Invite with pairing code: ${pairingCode}`);
  return (dispatch, getState) => {
    const { meeting } = getState();
    const url = `${meeting.baseUrl}/wc/pairing`;
    const data = {
      meetingNumber: meeting.meetingNumber,
      pairing_code: pairingCode,
      password: meeting.password,
      auth: meeting.auth,
      ts: meeting.ts,
    };
    axios
      .post(url, qs.stringify(data))
      .then(({ data: resData }) =>
        resData.status
          ? successLog('Invite with pairing code success')
          : errorLog(resData.errorMessage),
      )
      .catch((error) => errorLog(error));
  };
}

export function userForbidShare(data) {
  return { type: types.USER_FORBID_SHARE, data };
}

export function meRaiseHandAsAttendee(data) {
  return { type: types.ME_RAISE_HAND_AS_ATTENDEE, data };
}

export function changeRestartWebRtcTime(data) {
  return { type: types.CHANGE_RESTART_WEBRTC_TIME, data };
}

export function increaseRWGRetryCount() {
  return (dispatch, getState) => {
    const rwgRetryCount = getState().meeting.rwgRetryCount;
    dispatch({ type: types.INCREASE_RWG_RETRY_COUNT, data: rwgRetryCount + 1 });
  };
}

// let last = 0;
export function updateXmppUserList(data) {
  return (dispatch) => {
    const xmppUserList = {
      host: [],
      panelists: [],
      attendees: [],
    };
    const raiseHandAttendees = [];
    const attendeeHasSdkKey = [];

    // let current = Date.now();
    // eslint-disable-next-line no-console
    // console.log('run update', current - last, data.size);
    // last = current;
    if (!data || !data.size) return;
    for (const [, originUser] of data) {
      const node = Number(originUser.node);
      const user = { ...originUser };
      user.node = user.userId = node;
      if (user.role.toString() === userRoleConstants.WEBINAR_HOST) {
        xmppUserList.host.push(user);
      } else if (user.role.toString() === userRoleConstants.WEBINAR_PANELISTS) {
        xmppUserList.panelists.push(user);
      } else if (user.role.toString() === userRoleConstants.WEBINAR_ATTENDEES) {
        if (user.bRaiseHand) {
          raiseHandAttendees.push(user);
        } else {
          xmppUserList.attendees.push(user);
        }
      }
      if (user.sdkKey) {
        attendeeHasSdkKey.push(user);
      }
    }

    Array.prototype.unshift.apply(xmppUserList.attendees, raiseHandAttendees);
    dispatch({ type: types.UPDATE_XMPP_USER_LIST, xmppUserList });
    dispatch(
      saveWebSDKUserThunk({
        xmppAttendees: attendeeHasSdkKey,
        currentAttendeeCount: data.size,
      }),
    );
  };
}

export function updateMessageLatest(data) {
  return { type: types.UPDATE_MESSAGE_LATEST, data };
}
export function listenClosedCaption(data) {
  return { type: types.LISTEN_CLOSED_CAPTION, data };
}
export function enableClosedCaption(data) {
  return { type: types.ENABLE_CLOSED_CAPTION, data };
}

export function showClosedCaptionWindow(data) {
  return { type: types.CLOSED_CAPTION_OPENWINDOW, data };
}
export function pushClosedCaptionMessage(data) {
  return { type: types.PUSH_CLOSED_CAPTION_MESSAGE, data };
}
export const updatebCCEditor = createCollectorThunk(
  (data) => {
    return (dispatch, getState) => {
      const tmp = {};
      data
        .filter((v) => typeof v.bCCEditor !== 'undefined')
        .forEach((item) => {
          tmp[item.id] = item.bCCEditor;
        });
      const id = Object.entries(tmp)
        .filter(([, bCCEditor]) => bCCEditor)
        .map(([id]) => id)
        .reverse()[0];
      if (!id) {
        return;
      }
      const {
        newLiveTranscription: { isNewLTManualCCOn, newLTFeatureEnabled },
        breakoutRoom,
      } = getState();
      dispatch({
        type: types.UPDATE_CC_EDITOR,
        data: {
          id: Number(id),
          isNewLTManualCCOn,
          newLTFeatureEnabled,
          breakoutRoom,
        },
      });
    };
  },
  300,
  { trailing: true, leading: false },
);

export function showClosedCaptionAssignTip(data) {
  return { type: types.SHOW_CC_ASSIGNTIP, data };
}

export function setHost(data) {
  return (dispatch, getState) => {
    const hasCCList =
      getState().attendeesList &&
      getState().attendeesList.attendeesList.filter((item) => item.bCCEditor);

    if (
      data &&
      getState().meeting.closedCaption.bCCEditorAssigned &&
      !hasCCList.length
    ) {
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_ASSIGN_CC_REQ,
          body: {
            id: getState().meeting.currentUser.userId,
            bCCEditor: true,
          },
        }),
      );
    }
    return dispatch({ type: types.SET_MEETING_HOST, data });
  };
}

export function updateMessageTemp(data) {
  return { type: types.UPDATE_MESSGAE_TEMP, data };
}

export function updateTargetToAssign(data) {
  return { type: types.UPDATE_TARGET_TO_ASSIGN, data };
}
export function showAssignClosedCaptionConfirm(data) {
  return { type: types.SHOW_ASSIGN_CC_CONFIRM, data };
}
export function updateTempRealTimeCC(data) {
  return { type: types.UPDATE_TEMP_REALTIME_CC, data };
}
export function updateWebinarTabStatus(data) {
  return { type: types.UPDATE_WEBINAR_TAB_STATUS, data };
}

export function updateparticipantsRemoveMode(data) {
  return { type: types.UPDATE_PARTICIPANTS_REMOVE_MODE, data };
}

export function setCurrentCurrentUserIsMuteVideo(data) {
  return { type: types.SET_CURRENT_USER_IS_MUTE_VIDEO, data };
}

export function toggleCCPermission(target) {
  // only host can get here
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser: { userId },
        closedCaption: { ccEditor },
      },
    } = getState();

    const isWithdraw = target.bCCEditor;

    const body = { bCCEditor: true };

    if (isWithdraw) {
      body.id = userId;
    } else {
      body.id = target.userId;
    }

    if (
      ccEditor &&
      !isCurrentCCEditorSelector(getState()) &&
      ccEditor !== target.userId
    ) {
      dispatch(asignNewCCType(target));
    } else {
      dispatch(
        sendSocketMessage({
          evt: socketEventTypes.WS_CONF_ASSIGN_CC_REQ,
          body,
        }),
      );
    }
  };
}

export function handlePromoteOrDepromote(data) {
  return (dispatch, getState) => {
    const { bPromote, jid, ret } = data;
    const {
      meeting: { xmppUserList },
    } = getState();
    // ret === 0/1: success/decline
    // bPromote === true/false: promote/depromote
    if (ret === 0) {
      if (bPromote) {
        const xmppUser = xmppUserList.attendees.find((i) => i.jid === jid);
        if (xmppUser) {
          const text = iText(
            `${xmppUser.name} will be rejoining the webinar as a panelist.`,
            'apac.wc_webinar_promote',
            [xmppUser.name],
          );
          wcToast({
            text,
            type: 'success',
          });
        }
      } else {
        const xmppUser = [...xmppUserList.host, ...xmppUserList.panelists].find(
          (i) => i.jid === jid,
        );
        if (xmppUser) {
          const text = iText(
            `${xmppUser.name} will be rejoining the webinar as a attendee.`,
            'apac.wc_webinar_depromote',
            [xmppUser.name],
          );
          wcToast({
            text,
            type: 'success',
          });
        }
      }
    } else if (bPromote && ret === 1) {
      const xmppUser = xmppUserList.attendees.find((i) => i.jid === jid);
      if (xmppUser) {
        const text = iText(
          `${xmppUser.name} declined to be promoted to a panelist`,
          'apac.wc_webinar_promote_declined',
          [xmppUser.name],
        );
        wcToast({
          text,
          type: 'warning',
        });
      }
    } else {
      const text = bPromote
        ? iText(
            'Promote to panelist is failed',
            'apac.wc_webinar_promote_error',
          )
        : iText(
            'Changing Role to Attendee is failed',
            'apac.wc_webinar_depromote_error',
          );
      wcToast({
        text,
        type: 'error',
      });
    }
  };
}

export function setXMPPPromote(data) {
  return {
    type: types.IS_XMPP_PROMOTE,
    data,
  };
}
export function meetingReset(
  allReset /* isBOReconnection */,
  notFailover = false /* isNewWaitingRoomFlow */,
) {
  return (dispatch, getState) => {
    const {
      meeting: {
        currentUser,
        zoomId,
        userGUID,
        isNowFailoverInProgress,
        webinarToken,
        isWaitingRoomLoading,
        resetTime: currentResetTime,
        audioVideoStatusBeforeOnHold,
        ts,
        zak,
        fileReadZak,
        zmk,
        auth,
        trackAuth,
        encryptedRWC,
        tid,
        networkType,
        failoverData,
        userPronoun,
        chatModerationPolicy,
        restrictFeatures,
        isEnablePWAChat,
        giphyRatingInMeetingChat,
        giphy,
        attendeeChatDisableHyperLinks,
        shareFileConfig,
        rwcResponse,
      },
    } = getState();
    const resetTime = notFailover ? currentResetTime : currentResetTime + 1;
    const isWebClientReseting = true;
    const newRwcResponse = notFailover ? rwcResponse : [];
    const { audio, muted, bVideoOn, uid } = currentUser;
    const newCurrentUser = { ...emptyUser, uid };
    let currentUserAudioBeforeReset = audio;
    let currentUserAudioMuteBeforeReset = muted;
    let currentUserVideoBeforeReset = bVideoOn;
    if (audioVideoStatusBeforeOnHold !== undefined) {
      currentUserAudioBeforeReset = audioVideoStatusBeforeOnHold.audio;
      currentUserAudioMuteBeforeReset = audioVideoStatusBeforeOnHold.muted;
      currentUserVideoBeforeReset = audioVideoStatusBeforeOnHold.bVideoOn;
    }
    const bothSaving = {
      isWebClientReseting,
      resetTime,
      zoomId,
      rwcResponse: newRwcResponse,
      isWaitingRoomLoading,
      currentUser: newCurrentUser,
      currentUserAudioBeforeReset,
      currentUserAudioMuteBeforeReset,
      currentUserVideoBeforeReset,
      isNowFailoverInProgress,
      webinarToken,
      ts,
      zak,
      fileReadZak,
      zmk,
      auth,
      trackAuth,
      encryptedRWC,
      tid,
      networkType,
      failoverData,
      userPronoun,
      chatModerationPolicy,
      restrictFeatures,
      isEnablePWAChat,
      giphyRatingInMeetingChat,
      giphy,
      attendeeChatDisableHyperLinks,
      shareFileConfig,
    };
    if (allReset) {
      return dispatch({
        type: types.MEETING_RESET,
        data: {
          userGUID,
          ...bothSaving,
        },
      });
    }

    return dispatch({
      type: types.MEETING_RESET,
      data: {
        ...bothSaving,
      },
    });
  };
}

export const coHostChange = (bCoHost) => ({
  type: types.WS_CONF_COHOST_CHANGE_INDICATION,
  bCoHost,
});

export const setAdmitAllSilentUsers = (bStarted) => ({
  type: types.WS_CONF_ADMIT_ALL_SILENT_USERS_INDICATION,
  bStarted,
});

function separateAudioToast(isHost, name) {
  if (isHost) {
    return iText(
      'Your audio has been separated by the host',
      'apac.wc_separate_audio_toast1',
    );
  }
  return iText(
    `Your audio has been separated by ${name}(Co-host)`,
    'apac.wc_separate_audio_toast2',
    [name],
  );
}
function mergeAudioToast(isHost, name) {
  if (isHost) {
    return iText('Host merged the audio for you', 'apac.wc_merge_audio_toast1');
  }
  return iText(
    `${name}(Co-host) merged the audio for you`,
    'apac.wc_merge_audio_toast2',
  );
}

export function audioMergeNotification(body) {
  return (dispatch, getState) => {
    const {
      attendeesList: { attendeesList },
    } = getState();
    const { BindStatus, nOperatorUserId } = body;
    let toastText = null;
    const operator = attendeesList.find(
      (attendee) => attendee.userId === nOperatorUserId,
    );
    if (BindStatus) {
      if (isHost(operator.userRole)) {
        toastText = mergeAudioToast(true);
      } else if (operator.bCoHost) {
        toastText = mergeAudioToast(false, operator.displayName);
      }
    } else if (isHost(operator.userRole)) {
      toastText = separateAudioToast(true);
    } else if (operator.bCoHost) {
      toastText = separateAudioToast(false, operator.displayName);
    }
    wcToast({ text: toastText, type: 'success' });
  };
}

/**
 * when attendees leave the  webinar or are removed from the webinar,
 * It is possible that xmpp socket will be closed before webclient receives end webinar (WS_CONF_END_INDICATION) event,
 * So in order to ensure that webclient handles leave webinar action correctly, there is 2 seconds delay in processing the disconnected event.
 */
export function handleXmppDisconnected(oldConID, closedByNewWaitingRoom) {
  return (dispatch, getState) => {
    const {
      meeting: { isNowFailoverInProgress },
    } = getState();
    if (isNowFailoverInProgress || closedByNewWaitingRoom) {
      return;
    }
    setTimeout(() => {
      const {
        meeting: { isNowFailoverInProgress, isFailoverToSocketClose, conID },
        socketStatus: { xmppSocketStatus },
      } = getState();
      if (isNowFailoverInProgress) return;
      if (oldConID !== conID) return;
      if (closedByNewWaitingRoom) return; // new waitinhg roon join flow do not need to close socket again
      if (xmppSocketStatus !== 'close' && xmppSocketStatus !== 'conflict') {
        dispatch({ type: socketActionTypes.CLOSE_XMPP_SOCKET, data: null });
        if (isFailoverToSocketClose && isWebinar()) {
          warningLog('xmpp restart');
          dispatch(closeSocket(true, FAILOVER_REASON.DISCONNECT_XMPP));
        }
      }
    }, 2000);
  };
}

export const updateGatewayInfoList = (gateInfo) => ({
  type: types.UPDATE_GATEWAY_INFO_LIST,
  gateInfo,
});

export function updateCurrentUserCanAdmit(bCanAdmit) {
  return {
    type: types.WS_CONF_CAN_ADMIT_WHEN_NOHOST_PRESENT_INDICATION,
    bCanAdmit,
  };
}

export function setCurrentAVSEncryptKey(keys) {
  return {
    type: types.WS_AVS_ENCRYPT_KEY_INDICATION,
    keys,
  };
}

export function setSharePronounsType(data) {
  return { type: types.SET_SHARE_PRONOUNS_TYPE, payload: data };
}

export function changeUserPronoun(open) {
  return (dispatch, getState) => {
    const {
      meeting: { currentUser },
    } = getState();
    const { strPronoun } = currentUser;
    dispatch(setSharePronounNotificationVisible(false));
    dispatch(
      sendSocketMessage({
        evt: socketEventTypes.WS_CONF_USER_PRONOUN_CHANGE_REQ,
        body: {
          bPronoun: open,
          strPronoun,
        },
      }),
    );
  };
}

export function saveGettyConfig(data) {
  return { type: types.SET_GETTY_CONFIG, data };
}

export function resetRecordingStatus() {
  return { type: types.RECORD_MEETING_RESET };
}

const setRestrictFeatures = createAction(types.SET_RESTRICT_FEATURES);

const DISABLE_ALL_MEETING = '1';
const DISABLE_EXTERNAL_MEETING = '2';
export function setRestrictFeaturesThunk(policy) {
  return (dispatch) => {
    const meetingPolicy = {};
    Object.keys(policy).forEach((key) => {
      const obj = policy[key];
      const policyVal = obj.policyValue;
      meetingPolicy[key] =
        policyVal === DISABLE_ALL_MEETING ||
        (!meetingConfig.isSameAccount &&
          policyVal === DISABLE_EXTERNAL_MEETING);
    });
    dispatch(setRestrictFeatures(meetingPolicy));
    if (meetingPolicy[JOIN_MEETING_POLICY.HIDE_AVATAR]) {
      // manual set bAllowedAvatar false if restrict participantAvatar
      dispatch(setConfAttributeIndication({ body: { bAllowedAvatar: false } }));
    }
  };
}

export function saveKeepPhoneConnected(data) {
  return {
    type: types.SET_CURRENT_USER_KEEP_PHONE_CONNECTED,
    payload: data,
  };
}

export function recordFailoverData(reason) {
  return (dispatch) => {
    const prevFailoverCount = easyStore.easyGet('failoverCount') || 0;
    let failoverCount = prevFailoverCount;
    if (reason !== FAILOVER_REASON.NORMAL_CASE) {
      failoverCount = prevFailoverCount + 1;
      easyStore.easySet(
        'failoverCount',
        failoverCount,
        storeType.sessionStorage,
      );
    }

    easyStore.easySet(
      'failOverReason',
      { reason, failOverCount: failoverCount },
      storeType.sessionStorage,
    );

    dispatch({
      type: types.RECORD_FAILOVER_REASON,
      payload: {
        reason,
        count: failoverCount,
        interval: measureDuration(
          PERFORMANCE_MARK.inMeeting_RWG_success,
          PERFORMANCE_MARK.inMeeting_failed,
        )?.duration,
      },
    });

    logger.log(
      getJoinMeetingLog(`failover count: ${failoverCount}, reason: ${reason}`),
      ['FAILOVER'],
    );
  };
}

export function recordJoinMeetingMonitorLog() {
  return (dispatch, getState) => {
    const isInMeetingWithoutRefreshPage = easyStore.easyGet(
      'isInMeetingWithoutRefreshPage',
    );
    easyStore.easySet('isInMeetingWithoutRefreshPage', true, storeType.memory);
    const hasSetZoomId = easyStore.easyGet('zoomId');
    const isRejoinMeetingByRefreshPage =
      !isInMeetingWithoutRefreshPage &&
      !!hasSetZoomId &&
      !globalVariable.wcCTX().fromPreview;
    if (isRejoinMeetingByRefreshPage) {
      dispatch(recordFailoverData(FAILOVER_REASON.REFRESH_PAGE));
    }

    // after dispatch(recordFailoverData), we get latest failoverData state
    const {
      meeting: {
        failoverData: { count: failoverCount, reason, interval },
      },
    } = getState();

    const duration =
      measureDuration(
        [
          PERFORMANCE_MARK.inMeeting_failed,
          PERFORMANCE_MARK.start_InMeetingJS_fromPreview,
          PERFORMANCE_MARK.start_InMeetingJS,
        ],
        PERFORMANCE_MARK.inMeeting_RWG_success,
      )?.duration ?? 0;
    /* send RWG CONNECTION TIME TO RECORD PROFERMANCE PURPOSE */

    let monitorLogContent = `JMCT(${duration})`;
    if (failoverCount > 0 && reason !== FAILOVER_REASON.NORMAL_CASE) {
      monitorLogContent = `${monitorLogContent},FAILOVER(${failoverCount},${reason},${(
        interval / 1000
      ).toFixed(1)})`;
    }
    dispatch(sendSocketMonitorLog(monitorLogContent));
    dispatch({ type: types.CLEAR_FAILOVER_DATA });
  };
}

export function setIsChatDisabledByDlp(data) {
  return {
    type: types.SET_IS_CHAT_DISABLED_BY_DLP,
    payload: data,
  };
}

export const setCurrentUserUid = (uid) => {
  return {
    type: types.SET_CURRENT_USER_UID,
    payload: uid,
  };
};
export const setIsEnablePWAChat = (bool) => {
  return {
    type: types.SET_IS_ENABLE_PWA_CHAT,
    payload: bool,
  };
};

export const setCurrentUserAid = (aid) => {
  return {
    type: types.SET_CURRENT_USER_AID,
    payload: aid,
  };
};

export const setMeetingLcpAction = (lcp) => {
  return {
    type: types.SET_MEETING_LCP,
    payload: lcp,
  };
};

export const setOriginalMeetingStatus = (meetingStatus) => {
  return {
    type: types.SET_ORIGINAL_MEETING_STATUS,
    payload: meetingStatus,
  };
};

export const setMeetingTransfer = (isTransfer) => {
  return {
    type: types.SET_IS_TRANSFER_MEETING,
    payload: isTransfer,
  };
};

// export const setEnableNewChatUI = (bool) => {
//   return {
//     type: types.SET_ENABLE_NEW_CHAT_UI,
//     payload: bool,
//   };
// };

export const handleMeetingConfigIndication = (message) => ({
  type: types.HANDLE_MEETING_CONFIG_INDICATION,
  payload: message,
});

export const setAttendeeChatDisableHyperLinks = (disabled) => ({
  type: types.SET_ATTENDEE_CHAT_DISABLE_HYPER_LINKS,
  payload: disabled,
});

export const setShareFileConfig = (shareFileConfig) => ({
  type: types.SET_SHARE_FILE_CONFIG,
  payload: shareFileConfig,
});
